export default class TreeNode {
  constructor(data, tree) {
    this.id = data.id;
    this.level = 0;
    this.data = null;
    this.parent = null;
    this.children = [];
    this.tree = tree;
  }

  get isLeaf() {
    return (this.children || []).length === 0;
  }

  append(node) {
    this.children = this.children || [];
    this.children.push(node);
    // eslint-disable-next-line no-param-reassign
    node.level = this.level + 1;
    // eslint-disable-next-line no-param-reassign
    node.parent = this;
    this.tree.registerNode(node);
  }

  appendTo(node) {
    if (node instanceof TreeNode && this.parent instanceof TreeNode) {
      node.append(this);
    }
  }

  get nextSibling() {
    const { parent } = this;
    if (parent) {
      const index = parent.childNodes.indexOf(this);
      if (index > -1) {
        return parent.childNodes[index + 1];
      }
    }
    return null;
  }

  get previousSibling() {
    const { parent } = this;
    if (parent) {
      const index = parent.childNodes.indexOf(this);
      if (index > -1) {
        return index > 0 ? parent.childNodes[index - 1] : null;
      }
    }
    return null;
  }

  contains(target, deep = true) {
    const walk = (parent) => {
      const children = parent.children || [];
      let result = false;
      for (let i = 0, j = children.length; i < j; i += 1) {
        const child = children[i];
        if (child === target || (deep && walk(child))) {
          result = true;
          break;
        }
      }
      return result;
    };

    return walk(this);
  }

  insertChild(child, index) {
    if (!child) throw new Error('insertChild error: child is required.');

    if (!(child instanceof TreeNode)) throw new Error('insertChild error: child expect TreeNode.');

    if (index === undefined || index < 0) {
      this.children.push(child);
    } else {
      this.children.splice(index, 0, child);
    }

    // eslint-disable-next-line no-param-reassign
    child.level = this.level + 1;
    // eslint-disable-next-line no-param-reassign
    child.parent = this;
    this.tree.registerNode(child);
  }

  insertBefore(child, ref) {
    let index;
    if (ref) {
      index = this.children.indexOf(ref);
    }
    this.insertChild(child, index);
  }

  insertAfter(child, ref) {
    let index;
    if (ref) {
      index = this.children.indexOf(ref);
      if (index !== -1) index += 1;
    }
    this.insertChild(child, index);
  }

  remove() {
    const { parent } = this;
    if (parent) {
      parent.removeChild(this);
    }
  }

  removeChild(child) {
    const index = this.children.indexOf(child);

    if (index > -1) {
      this.tree.deregisterNode(child);
      // eslint-disable-next-line no-param-reassign
      child.parent = null;
      this.children.splice(index, 1);
    }
  }
}
