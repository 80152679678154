/**
 * 上传对象接口
 * https://developer.qiniu.com/kodo/sdk/1283/javascript
 */

const EventSymbol = Symbol('Event');
export const Private = Symbol('Private');

class Uploader {
  constructor(file, key = '') {
    if (!(file instanceof File)) {
      throw new Error('Uploader Error: 构造上传对象时, 需传入要上传的文件');
    }
    this.key = key;
    this.status = '';
    this.localUrl = window.URL.createObjectURL(file);
    this[EventSymbol] = Object.create(null);
    this[Private] = Object.create(null);
    this[Private].file = file;
  }

  get file() {
    return this[Private].file;
  }

  setStatus(val) {
    if (this.status === val) {
      return;
    }
    this.status = val;
    this.trigger('change:status', val);
  }

  setKey(key) {
    if (key) {
      this.key = key;
      return;
    }
    throw new Error('setKey 方法尚未实现');
  }

  // eslint-disable-next-line class-methods-use-this
  async checkToken() {
    throw new Error('checkToken 方法尚未实现');
  }

  // eslint-disable-next-line class-methods-use-this
  async upload() {
    throw new Error('upload 方法尚未实现');
  }

  // eslint-disable-next-line class-methods-use-this
  abort() {
    throw new Error('abort 方法尚未实现');
  }

  on(evtName, fuc, once = false) {
    this[EventSymbol][evtName] = this[EventSymbol][evtName] || new Set();
    this[EventSymbol][evtName].add({
      handle: fuc,
      once,
    });
  }

  off(evtName, fuc) {
    if (!this[EventSymbol][evtName]) {
      return;
    }
    this[EventSymbol][evtName].forEach((item) => {
      if (item.handle === fuc) {
        this[EventSymbol][evtName].delete(item);
      }
    });
  }

  trigger(evtName, val) {
    if (!evtName) return;

    // 绑定的事件监听函数
    const actions = this[EventSymbol][evtName];
    if (!actions) return;

    actions.forEach((action) => {
      const { handle, once } = action;
      handle.call(this, val);
      if (once) {
        actions.delete(action);
      }
    });
  }
}

export default Uploader;
