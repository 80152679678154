<template>
  <div class="kk-image">
    <slot v-if="loading" name="placeholder">
      <div class="kk-image__placeholder">加载中</div>
    </slot>
    <slot v-else-if="error" name="error">
      <div class="kk-image__error">加载失败</div>
    </slot>
    <img v-else class="kk-image__inner" v-bind="$attrs" v-on="$listeners" :src="src" :style="imageStyle" />
  </div>
</template>

<script>
const isSupportObjectFit = () => document.documentElement.style.objectFit !== undefined;

const ObjectFit = {
  NONE: 'none',
  CONTAIN: 'contain',
  COVER: 'cover',
  FILL: 'fill',
  SCALE_DOWN: 'scale-down',
};

export default {
  name: 'KkImage',
  components: {},
  props: {
    src: String,
    fit: String,
    lazy: Boolean,
  },
  data() {
    return {
      loading: true,
      error: false,
      show: !this.lazy,
      imageWidth: 0,
      imageHeight: 0,
    };
  },
  computed: {
    imgSrc() {
      if (this.src) {
        try {
          return this.src;
          // return reduceImageSrc(this.src);
        } catch (err) {
          return '';
        }
      }
      return '';
    },
    imageStyle() {
      const { fit } = this;
      if (fit) {
        return isSupportObjectFit()
          ? { 'object-fit': fit }
          : this.getImageStyle(fit);
      }
      return {};
    },
    alignCenter() {
      return !isSupportObjectFit() && this.fit !== ObjectFit.FILL;
    },
    preview() {
      const { previewSrcList } = this;
      return Array.isArray(previewSrcList) && previewSrcList.length > 0;
    },
  },
  watch: {
    src(val) {
      if (val) {
        this.loadImage();
      }
    },
    show(val) {
      if (val) {
        this.loadImage();
      }
    },
  },
  mounted() {
    if (this.lazy) {
      this.addLazyLoadListener();
    } else {
      this.loadImage();
    }
  },
  // beforeDestroy() {
  //   this.lazy && this.removeLazyLoadListener();
  // },
  methods: {
    loadImage() {
      if (!this.imgSrc) {
        return;
      }
      this.loading = true;
      this.error = false;
      const img = new Image();
      img.onload = e => this.handleLoad(e, img);
      img.onerror = this.handleError.bind(this);
      // bind html attrs
      // so it can behave consistently
      Object.keys(this.$attrs)
        .forEach((key) => {
          const value = this.$attrs[key];
          img.setAttribute(key, value);
        });
      img.src = this.imgSrc;
    },
    handleLoad(e, img) {
      this.imageWidth = img.width;
      this.imageHeight = img.height;
      this.loading = false;
      this.error = false;
    },
    handleError(e) {
      this.loading = false;
      this.error = true;
      this.$emit('error', e);
    },
    // handleLazyLoad() {
    //   if (isInContainer(this.$el, this._scrollContainer)) {
    //     this.show = true;
    //     this.removeLazyLoadListener();
    //   }
    // },
    // addLazyLoadListener() {
    //   if (this.$isServer) return;
    //   const { scrollContainer } = this;
    //   let _scrollContainer = null;
    //   if (isHtmlElement(scrollContainer)) {
    //     _scrollContainer = scrollContainer;
    //   } else if (isString(scrollContainer)) {
    //     _scrollContainer = document.querySelector(scrollContainer);
    //   } else {
    //     _scrollContainer = getScrollContainer(this.$el);
    //   }
    //   if (_scrollContainer) {
    //     this._scrollContainer = _scrollContainer;
    //     this._lazyLoadHandler = throttle(200, this.handleLazyLoad);
    //     on(_scrollContainer, 'scroll', this._lazyLoadHandler);
    //     this.handleLazyLoad();
    //   }
    // },
    // removeLazyLoadListener() {
    //   const { _scrollContainer, _lazyLoadHandler } = this;
    //   if (this.$isServer || !_scrollContainer || !_lazyLoadHandler) return;
    //   off(_scrollContainer, 'scroll', _lazyLoadHandler);
    //   this._scrollContainer = null;
    //   this._lazyLoadHandler = null;
    // },
    /**
     * simulate object-fit behavior to compatible with IE11 and other browsers which not support object-fit
     */
    getImageStyle(fit) {
      const { imageWidth, imageHeight } = this;
      const {
        clientWidth: containerWidth,
        clientHeight: containerHeight,
      } = this.$el;
      if (!imageWidth || !imageHeight || !containerWidth || !containerHeight) return {};
      const vertical = imageWidth / imageHeight < 1;
      let targetFit = fit;
      if (targetFit === ObjectFit.SCALE_DOWN) {
        const isSmaller = imageWidth < containerWidth && imageHeight < containerHeight;
        targetFit = isSmaller ? ObjectFit.NONE : ObjectFit.CONTAIN;
      }
      switch (targetFit) {
        case ObjectFit.NONE:
          return { width: 'auto', height: 'auto' };
        case ObjectFit.CONTAIN:
          return vertical ? { width: 'auto' } : { height: 'auto' };
        case ObjectFit.COVER:
          return vertical ? { height: 'auto' } : { width: 'auto' };
        default:
          return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kk-image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.kk-image__inner {
  vertical-align: top;
}

.kk-image__error,
.kk-image__inner,
.kk-image__placeholder {
  width: 100%;
  height: 100%;
}

.kk-image__error, .kk-image__placeholder {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #C0C4CC;
  vertical-align: middle;
  background: #F5F7FA;
}
</style>
