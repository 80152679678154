/**
 * 访问地址: https://sentry.kkmh.com/organizations/kuaikan/projects/kk-ads-effect_kuaikan_front_edit/?project=32
 */
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
// eslint-disable-next-line import/no-cycle
import user from '@/models/User.js';
import { getRunEnvironment } from '@/assets/js/utils';

/** @enum { 'local' | 'development' | 'stag' | 'preview' | 'production' } 运行环境 */
const env = getRunEnvironment();

/** 各运行环境的采样率 */
const tracesSampleRateMap = {
  local: 0.5,
  development: 0.2,
  stag: 0.5,
  preview: 1,
  production: 1,
};

/** 各运行环境的错误上报采样率 */
const sampleRateMap = {
  local: 0.5,
  development: 0.2,
  stag: 0.5,
  preview: 1,
  production: 1,
};

if (env !== 'local') {
  Sentry.init({
    Vue,
    integrations: [new TracingIntegrations.BrowserTracing({
      startTransactionOnLocationChange: false,
    })],
    dsn: 'https://cda8d95edfc54b4f8cc1f12839595a80@sentry.kkmh.com/32',
    environment: env || 'unknown',
    tracesSampleRate: tracesSampleRateMap[env] || 1,
    tracingOptions: {
      trackComponents: true,
    },
    sampleRate: sampleRateMap[env] || 1,
    ignoreErrors: [
      'NavigationDuplicated',
      'Request failed with status code 502',
      'ResizeObserver',
      'Network Error',
      'Request aborted',
      'Navigation aborted',
      'Navigation cancelled',
      'Non-Error promise rejection captured with value: cancel',
      'Cannot read property \'level\' of null', // 级联选择器回显时, 第一个选项非一级会报错, element-ui 的问题
      'Non-Error promise rejection captured with keys: code, message',
      'Cannot read property \'scrollToActiveTab\' of undefined',
    ],
    beforeSend(event, hint) {
      if (hint.originalException) {
        if (hint.originalException.code && [400, 403, 401, 502, 500, 2005, 302].includes(hint.originalException.code)) {
          return;
        }
      }
      return event;
    },
  });

  Sentry.configureScope((scope) => {
    scope.setTag('project', 'kk-effect');
  });

  user.on('userInfo', () => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.username,
        username: user.name,
        department: user.department,
      });
    });
  });
}

const sentry = {};

sentry.getInstance = () => Sentry;

sentry.report = function reportSentry(err, { extra = {}, level = 'debug' } = {}) {
  if (!err) {
    return;
  }

  if (env === 'local') {
    console.log('本地环境不上报', err);
    return;
  }

  Sentry.withScope((scope) => {
    scope.setTag('report.mode', 'fe-developer');
    scope.setLevel(level);

    // 设置额外参数
    if (typeof extra === 'object') {
      Object.keys(extra).forEach((key) => {
        scope.setExtra(key, JSON.stringify(extra[key]));
      });
    }

    if (err instanceof Error) {
      Sentry.captureException(err, level);
    } else if (typeof err === 'string') {
      Sentry.captureMessage(err, level);
    }
  });
};
// ------------------------------------------------------------------------------------------------------
const sentryPlugin = {};
// eslint-disable-next-line
sentryPlugin.install = (Vue) => {
  // eslint-disable-next-line
  Vue.prototype.$reportSentry = sentry.report;
};

Vue.use(sentryPlugin);

export default sentry;
