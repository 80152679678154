<template>
  <div v-if="globalNotice" class="app-tips" style="margin-bottom: 10px;">
    <el-alert :key="globalNotice.id" show-icon type="warning" @close="close(globalNotice.id)">
      <div class="tips" slot="title" v-html="globalNotice.content" />
      <div class="tips" slot="title"><slot></slot></div>
    </el-alert>
  </div>
</template>

<script>
export default {
  name: 'AppTips',
  data() {
    return {};
  },
  computed: {
    globalNotice() {
      return (this.$store.state.app.globalNotices || [])[0] || null;
    },
  },
  methods: {
    close(id) {
      this.$store.dispatch('app/closeNotice', id);
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  display: inline-block;
  margin-right: 20px;
}
</style>
