import { DateTime } from 'luxon';

const defaultOptions = {
  /** 模式 time, range */
  mode: 'range',
  /** 维度, time, day, month */
  dimension: 'day',
};

class PickShortcutsCreator {
  constructor(opt = {}) {
    this.options = Object.create(null);
    this.options.mode = opt.mode || defaultOptions.mode;
    this.options.dimension = opt.dimension || defaultOptions.dimension;
  }

  getShortcuts() {
    const { dimension } = this.options;
    if (dimension === 'time') {
      return this.getTimeRangeShortcuts();
    }
    if (dimension === 'day') {
      return this.getDayRangeShortcuts();
    }
    if (dimension === 'month') {
      return this.getMonthRangeShortcuts();
    }
  }

  getTimeRangeShortcuts() {
    const shortcutsConfigList = [{
      text: '近一小时',
      onClick: (vm) => {
        const start = DateTime.now().plus({ hour: -1 });
        const end = DateTime.now();
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '近三小时',
      onClick: (vm) => {
        const start = DateTime.now().plus({ hour: -3 });
        const end = DateTime.now();
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '近六小时',
      onClick: (vm) => {
        const start = DateTime.now().plus({ hour: -6 });
        const end = DateTime.now();
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '近十二小时',
      onClick: (vm) => {
        const start = DateTime.now().plus({ hour: -12 });
        const end = DateTime.now();
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '今天',
      onClick: (vm) => {
        const start = DateTime.now().startOf('day');
        const end = DateTime.now().endOf('day');
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '近三天',
      onClick: (vm) => {
        const start = DateTime.now().plus({ day: -3 }).startOf('day');
        const end = DateTime.now().endOf('day');
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '近七天',
      onClick: (vm) => {
        const start = DateTime.now().plus({ day: -7 }).startOf('day');
        const end = DateTime.now().endOf('day');
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '本月',
      onClick: (vm) => {
        const start = DateTime.now().startOf('month');
        const end = DateTime.now().endOf('month');
        this.emitTime(vm, start, end);
      },
    },
    {
      text: '近三月',
      onClick: (vm) => {
        const start = DateTime.now().plus({ month: -2 }).startOf('month');
        const end = DateTime.now().endOf('month');
        this.emitTime(vm, start, end);
      },
    },
    ];

    return shortcutsConfigList;
  }

  getDayRangeShortcuts() {
    const shortcutsConfigList = [{
      text: '今天',
      onClick: (vm) => {
        const start = DateTime.now().startOf('day');
        const end = DateTime.now().endOf('day');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '近三天',
      onClick: (vm) => {
        const start = DateTime.now().plus({ day: -3 }).startOf('day');
        const end = DateTime.now().endOf('day');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '近七天',
      onClick: (vm) => {
        const start = DateTime.now().plus({ day: -7 }).startOf('day');
        const end = DateTime.now().endOf('day');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '本月',
      onClick: (vm) => {
        const start = DateTime.now().startOf('month');
        const end = DateTime.now().endOf('month');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '上个月',
      onClick: (vm) => {
        const start = DateTime.now().plus({ month: -1 }).startOf('month');
        const end = DateTime.now().plus({ month: -1 }).endOf('month');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '近三月',
      onClick: (vm) => {
        const start = DateTime.now().plus({ month: -2 }).startOf('month');
        const end = DateTime.now().endOf('month');
        this.emitTime(vm, start, end);
      },
    }];

    return shortcutsConfigList;
  }

  getMonthRangeShortcuts() {
    const shortcutsConfigList = [{
      text: '本月',
      onClick: (vm) => {
        const start = DateTime.now().startOf('month');
        const end = DateTime.now().endOf('month');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '上个月',
      onClick: (vm) => {
        const start = DateTime.now().plus({ month: -1 }).startOf('month');
        const end = DateTime.now().plus({ month: -1 }).endOf('month');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '近三月',
      onClick: (vm) => {
        const start = DateTime.now().plus({ month: -2 }).startOf('month');
        const end = DateTime.now().endOf('month');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '本季度',
      onClick: (vm) => {
        const currentMonth = DateTime.now().get('month');
        const season = Math.floor((currentMonth - 1) / 3) + 1;
        const start = DateTime.now().set({ month: (season * 3) - 2 }).startOf('month');
        const end = DateTime.now().set({ month: (season * 3) }).endOf('month');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '上季度',
      onClick: (vm) => {
        const currentMonth = DateTime.now().get('month');
        const season = Math.floor((currentMonth - 1) / 3) + 1;
        const start = DateTime.now().set({ month: (season * 3) - 2 }).plus({ month: -3 }).startOf('month');
        const end = DateTime.now().set({ month: (season * 3) }).plus({ month: -3 }).endOf('month');
        this.emitTime(vm, start, end);
      },
    }, {
      text: '近半年',
      onClick: (vm) => {
        const start = DateTime.now().plus({ month: -5 }).startOf('month');
        const end = DateTime.now().endOf('month');
        this.emitTime(vm, start, end);
      },
    }];

    return shortcutsConfigList;
  }

  emitTime(vm, start, end) {
    const { mode } = this.options;
    if (mode === 'range') {
      vm.$emit('pick', [start.toJSDate(), end.toJSDate()]);
      return;
    }

    vm.$emit('pick', start.toJSDate());
  }
}

function getDatePickerShortcuts(opt) {
  const pickOptionsCreator = new PickShortcutsCreator(opt);

  return pickOptionsCreator.getShortcuts();
}

export default getDatePickerShortcuts;
