import Vue from 'vue';
import BaseCard from '@/components/BaseCard';
import BaseIcon from '@/components/BaseIcon';
import BaseEmpty from '@/components/BaseEmpty';
import BasePageView from '@/components/BasePageView';
import BaseImg from '@/components/BaseImg';
import BaseStatusText from '@/components/BaseStatusText';
import BaseGallery from '@/components/BaseGallery';
import BaseImgUpload from '@/components/BaseImgUpload';
import BaseFileUpload from '@/components/BaseFileUpload';
import BaseLongText from '@/components/BaseLongText';
import BaseTagsInput from '@/components/BaseTagsInput';
import BaseVideoUpload from '@/components/BaseVideoUpload';
import BaseForm from '@/components/BaseForm';
import BaseFormItem from '@/components/BaseFormItem';
import BaseCardRadio from '@/components/BaseCardRadio';
import BaseButtonSelect from '@/components/BaseButtonSelect';

Vue.component(BaseCard.name, BaseCard);
Vue.component(BasePageView.name, BasePageView);
Vue.component(BaseImg.name, BaseImg);
Vue.component(BaseStatusText.name, BaseStatusText);
Vue.component(BaseGallery.name, BaseGallery);
Vue.component(BaseImgUpload.name, BaseImgUpload);
Vue.component(BaseFileUpload.name, BaseFileUpload);
Vue.component(BaseLongText.name, BaseLongText);
Vue.component(BaseTagsInput.name, BaseTagsInput);
Vue.component(BaseVideoUpload.name, BaseVideoUpload);
Vue.component(BaseIcon.name, BaseIcon);
Vue.component(BaseEmpty.name, BaseEmpty);
Vue.component(BaseForm.name, BaseForm);
Vue.component(BaseFormItem.name, BaseFormItem);
Vue.component(BaseCardRadio.name, BaseCardRadio);
Vue.component(BaseButtonSelect.name, BaseButtonSelect);

// Vue.prototype.$loading = Loading.service;
