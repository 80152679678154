<template>
  <el-form-item
    ref="elFormItem"
    v-if="innerFormItemInfo.active"
    v-show="!innerFormItemInfo.hidden"
    :required="innerFormItemInfo.required"
    :error="innerFormItemInfo.error"
    v-bind="$props"
    class="kk-form-item">
    <span v-if="explan && label" slot="label">
      {{label}}
      <el-popover placement="top" width="300" trigger="hover">
        <div v-html="xss(explan)" />
        <kk-icon slot="reference" type="question-circle" />
      </el-popover>
    </span>
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </el-form-item>
</template>

<script>
import KkIcon from '@/components/BaseIcon';
import { xss } from '@/assets/js/utils';

export default {
  name: 'KkFormItem',
  components: { KkIcon },
  inject: {
    kkFormInfo: {
      default: '',
    },
  },
  props: {
    label: String,
    labelWidth: String,
    prop: String,
    required: {
      type: Boolean,
      default: undefined,
    },
    rules: [Object, Array],
    error: String,
    validateStatus: String,
    for: String,
    inlineMessage: {
      type: [String, Boolean],
      default: '',
    },
    showMessage: {
      type: Boolean,
      default: true,
    },
    size: String,

    // 新添加的属性
    /** 表单项配置, 来自 formModel */
    formItemInfo: {
      type: [Object, String],
      default: null,
    },
    /** 数据项说明 */
    explan: {
      type: String,
      default: '',
    },
  },
  computed: {
    formInfo() {
      if (!this.formItemInfo) {
        return {};
      }
      if (typeof this.formItemInfo === 'object') {
        return this.formItemInfo;
      }
      if (typeof this.formItemInfo === 'string' && this.kkFormInfo) {
        if (this.kkFormInfo[this.formItemInfo]) {
          return this.kkFormInfo[this.formItemInfo];
        }
        console.error(`formModel 中找不到属性 ${this.formItemInfo} 的配置`);
        return {};
      }
      return {};
    },
    innerFormItemInfo() {
      const info = this.formInfo;
      return {
        active: info.active !== undefined ? info.active : true,
        hidden: info.hidden || false,
        required: this.required || info.required || false,
        error: this.error || info.error || '',
      };
    },
  },
  methods: {
    xss(content) {
      return xss(content);
    },
    /** 传递事件触发 */
    spreadEvent(name, evt) {
      this.$emit(name, evt);
    },

    /** 同 el-form-item resetField */
    resetField() {
      // eslint-disable-next-line
      return this.$refs.elFormItem.resetField.apply(this.$refs.elFormItem, arguments);
    },
    /** 同 el-form-item clearValidate */
    clearValidate() {
      // eslint-disable-next-line
      return this.$refs.elFormItem.clearValidate.apply(this.$refs.elFormItem, arguments);
    },
  },
};
</script>

<style lang="scss" scoped>
.kk-form-bottomtip {
  color: gray;
  font-size: 12px;
  line-height: 1.5;
}
</style>
