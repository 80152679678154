/**
 * 全局 mixin
 * 注: 全局提供的默认方法, 使用 kk_ 作为前缀用以区分
 */
import Vue from 'vue';

const mixinObj = Object.create(null);

mixinObj.methods = {
  /** 返回上一页 */
  kk_goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.push('/');
    }
  },
};

// 用于非 production 环境的 mixin
if (process.env.NODE_ENV !== 'production') {
  const exceptEles = ['<Bar>', '<Root>', 'Bar', 'keep-alive', 'label-wrap', 'total', 'sizes', 'prev', 'pager', 'next', 'jumper', 'transition', 'TabNav'];
  // eslint-disable-next-line
  const logLife = (node, lifeStr) => {
    // eslint-disable-next-line no-underscore-dangle
    const componentTag = node.$options._componentTag;

    // eslint-disable-next-line no-underscore-dangle
    if (!componentTag && node._name && !exceptEles.includes(node._name)) {
      // eslint-disable-next-line no-underscore-dangle
      // console.log(`%c ${lifeStr} %c ${node._name}  %c`,
      //   'background: #35495e; padding: 1px; border-radius: 3px 0 0 3px; color: #fff; display: inline-block;',
      //   'background: #4987ec; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff;',
      //   'background: transparent;');
    }
  };

  mixinObj.created = function logCreatedLife() {
    const { name } = (this.$options || {});
    if (name
      && !name.startsWith('El')
      && !exceptEles.includes(name)
      && !name.startsWith('App')
      && !name.startsWith('Kk')
      && !name.startsWith('transition')) {
      // console.log(`%c created %c ${name}  %c`,
      //   'background: #35495e; padding: 1px; border-radius: 3px 0 0 3px; color: #fff; display: inline-block;',
      //   'background: #4987ec; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff;',
      //   'background: transparent;');
    }
  };
  mixinObj.activated = function logActivatedLife() {
    logLife(this, 'activated');
  };
  mixinObj.deactivated = function logDeactivatedLife() {
    logLife(this, 'deactivated');
  };
  mixinObj.mounted = function logMountedLife() {
    logLife(this, 'mounted');
  };
  mixinObj.destroyed = function logDestroyedLife() {
    logLife(this, 'destroyed');
  };
}

Vue.mixin(mixinObj);
