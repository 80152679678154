/**
 * 图像相关公共方法整理
 */

// 检测官方文档: https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
/** 类型检测掩码集合 */
const patternMask = [{
  name: 'image/x-icon',
  mask: [0xFF, 0xFF, 0xFF, 0xFF],
  byte: [0x00, 0x00, 0x01, 0x00],
}, {
  name: 'image/x-icon',
  mask: [0xFF, 0xFF, 0xFF, 0xFF],
  byte: [0x00, 0x00, 0x02, 0x00],
}, {
  name: 'image/bmp',
  mask: [0xFF, 0xFF],
  byte: [0x42, 0x4D],
}, {
  name: 'image/gif',
  mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
  byte: [0x47, 0x49, 0x46, 0x38, 0x37, 0x61],
}, {
  name: 'image/gif',
  mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
  byte: [0x47, 0x49, 0x46, 0x38, 0x39, 0x61],
}, {
  name: 'image/webp',
  mask: [0xFF, 0xFF, 0xFF, 0xFF, 0x00, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
  byte: [0x52, 0x49, 0x46, 0x46, 0x00, 0x00, 0x00, 0x00, 0x57, 0x45, 0x42, 0x50, 0x56, 0x50],
}, {
  name: 'image/png',
  mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
  byte: [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A],
}, {
  name: 'image/jpeg',
  mask: [0xFF, 0xFF, 0xFF],
  byte: [0xFF, 0xD8, 0xFF],
}];

function remoteNameFromUrl(urlStr) {
  const prefix = '__remote__';
  const result = urlStr.split(/(\\|\/)/g).pop();
  return prefix + result.split('?')[0];
}

/**
 * 检测文件类型
 * 使用文件编码进行检测
 * 当前仅支持检测: image/x-icon, image/bmp, image/gif, image/webp, image/png, image/jpeg
 * 支持模式参看: patternMask 定义
 * @param {File} file 需要检测的文件对象
 * @returns {Promise} resolve 后返回文件类型
 */
export async function getFileType(file) {
  if (!(file instanceof File)) {
    return '';
  }
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const header = (new Uint8Array(e.target.result)).slice(0, 20);
      let type = '';

      // eslint-disable-next-line arrow-body-style
      const index = patternMask.findIndex((item) => {
        // eslint-disable-next-line arrow-body-style
        return item.mask.every((subItem, subI) => {
          // subItem 掩码标志
          // item.byte[subI] 规范值
          // header[subI] 文件实际值
          // eslint-disable-next-line
          return ((subItem & (header[subI] ^ item.byte[subI])) === 0);
        });
      });

      if (index >= 0) {
        type = patternMask[index].name;
      }

      resolve(type);
    };
    fileReader.readAsArrayBuffer(file);
  });
}

/**
 * 检测是否是动图
 * 主要针对 Gif 和 Webp 两种格式
 * @param {File|String} image 要检测的图片文件或地址
 * @param {String} fileType 文件类型(MIME 格式)
 * 手动传文件 MIME, 可优化执行速度, 如不传, 会调用 getFileType 获取文件类型
 */
export async function checkIsAnimated(image, fileType = '') {
  // 参数验证
  if (!image || (!(image instanceof File) && typeof image !== 'string')) {
    throw new Error('checkIsAnimated error: 需要传入需要检查的图像文件或地址');
  }

  let imgFile;
  if (typeof image === 'string') {
    const imgBlob = await fetch(image).then(res => res.blob());
    imgFile = new File([imgBlob], remoteNameFromUrl(image));
  } else {
    imgFile = image;
  }

  if (!fileType) {
    // eslint-disable-next-line no-param-reassign
    fileType = await getFileType(imgFile);
  }

  // 如果不是 gif 和 webp, 默认作为非动图
  if (fileType !== 'image/webp' && fileType !== 'image/gif') {
    return false;
  }

  if (fileType === 'image/webp') {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve((event.target.result.indexOf('ANMF') !== -1));
      };
      reader.readAsText(imgFile);
      // const request = new XMLHttpRequest();
      // request.open('GET', imgUrl, true);
      // request.addEventListener('load', () => {
      //   resolve((request.response.indexOf('ANMF') !== -1));
      // });
      // request.send();
    });
  }
  if (fileType === 'image/gif') {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const arr = new Uint8Array(event.target.result);
        // make sure it's a gif (GIF8)
        if (arr[0] !== 0x47 || arr[1] !== 0x49 || arr[2] !== 0x46 || arr[3] !== 0x38) {
          resolve(false);
          return;
        }

        // ported from php http://www.php.net/manual/en/function.imagecreatefromgif.php#104473
        // an animated gif contains multiple "frames", with each frame having a
        // header made up of:
        // * a static 4-byte sequence (\x00\x21\xF9\x04)
        // * 4 variable bytes
        // * a static 2-byte sequence (\x00\x2C) (some variants may use \x00\x21 ?)
        // We read through the file til we reach the end of the file, or we've found
        // at least 2 frame headers
        let frames = 0;
        for (let i = 0, len = arr.length - 9; i < len && frames < 2; i += 1) {
          if (arr[i] === 0x00 && arr[i + 1] === 0x21 && arr[i + 2] === 0xF9 && arr[i + 3] === 0x04 && arr[i + 8] === 0x00 && (arr[i + 9] === 0x2C || arr[i + 9] === 0x21)) {
            frames += 1;
          }
        }

        // if frame count > 1, it's animated
        resolve(frames > 1);
      };
      reader.readAsArrayBuffer(imgFile);

      // const request = new XMLHttpRequest();
      // request.open('GET', imgUrl, true);
      // request.responseType = 'arraybuffer';
      // request.addEventListener('load', () => {
      //   const arr = new Uint8Array(request.response);
      //   // make sure it's a gif (GIF8)
      //   if (arr[0] !== 0x47 || arr[1] !== 0x49 || arr[2] !== 0x46 || arr[3] !== 0x38) {
      //     resolve(false);
      //     return;
      //   }

      //   // ported from php http://www.php.net/manual/en/function.imagecreatefromgif.php#104473
      //   // an animated gif contains multiple "frames", with each frame having a
      //   // header made up of:
      //   // * a static 4-byte sequence (\x00\x21\xF9\x04)
      //   // * 4 variable bytes
      //   // * a static 2-byte sequence (\x00\x2C) (some variants may use \x00\x21 ?)
      //   // We read through the file til we reach the end of the file, or we've found
      //   // at least 2 frame headers
      //   let frames = 0;
      //   for (let i = 0, len = arr.length - 9; i < len && frames < 2; i += 1) {
      //     if (arr[i] === 0x00 && arr[i + 1] === 0x21 && arr[i + 2] === 0xF9 && arr[i + 3] === 0x04 && arr[i + 8] === 0x00 && (arr[i + 9] === 0x2C || arr[i + 9] === 0x21)) {
      //       frames += 1;
      //     }
      //   }

      //   // if frame count > 1, it's animated
      //   resolve(frames > 1);
      // });
      // request.send();
    });
  }
}

/**
 * 获取图片信息
 * @param {File|String} image 需要检测的文件对象/或者资源地址
 */
export async function getImageInfo(image) {
  if (!image) {
    throw new Error('getImageInfo error: 传入参数错误, 需传入文件对象或图片资源地址');
  }

  let imgUrl; let
    imgFile;
  if (image instanceof File) {
    imgFile = image;
    imgUrl = window.URL.createObjectURL(image);
  } else if (typeof image === 'string') {
    imgUrl = image;
    const imgBlob = await fetch(image).then(res => res.blob());
    imgFile = new File([imgBlob], remoteNameFromUrl(image));
  } else {
    throw new Error('getImageInfo error: 传入参数错误, 需传入文件对象或图片资源地址');
  }

  let width;
  let height;
  await new Promise((resolve, reject) => {
    const imageEle = new Image();
    imageEle.src = imgUrl;
    imageEle.onload = () => {
      width = imageEle.width;
      height = imageEle.height;
      resolve();
    };
    imageEle.onerror = (err) => {
      reject(err);
    };
  });

  let type = await getFileType(imgFile);
  if (type === 'unknown') {
    type = imgFile.type;
  }
  const { name } = imgFile;
  const { size } = imgFile;
  const isAnimation = await checkIsAnimated(imgFile, type);
  // eslint-disable-next-line no-nested-ternary
  const kkImgType = (type === 'image/gif') ? 1 : (isAnimation ? 3 : 2); // 1 gif 2 静态图 3 动态webp
  const info = {
    name,
    size,
    type,
    width,
    height,
    url: imgUrl,
    isAnimation,
    kkImgType,
  };
  return info;
}
