/**
 * @author 圈圈
 * @description 上传图像资源
 */
import axios from '@/plugins/axios.js';
import { getImageInfo } from '@/assets/js/imgUtils.js';
import * as utils from './utils';
import Uploader from './Uploader';

const defaultConfig = { useCatch: true };

class InnerImgUploader extends Uploader {
  // 直接走服务端接口上传不需要设置 key
  constructor(file, config = { useCatch: false }) {
    super(file);
    /** 用于提交给服务器的地址 */
    this.uploadUrl = '';
    /** 原图地址 */
    this.sourceUrl = '';
    this.key = '';
    this.config = { ...defaultConfig, ...config };
    this.hash = '';

    this.info = {
      name: file.name,
      size: file.size,
      type: file.type,
      width: '',
      height: '',
      url: '',
      isAnimation: false,
      kkImgType: 0,
      /** 动图第一帧 */
      firstImage: '',
      firstImageDisplay: '',
    };
  }

  async init() {
    try {
      this.hash = await utils.getQiniuHash(this.file);
    } catch (err) {
      // 计算 hash 出错, 使用随机字符串代替
      this.hash = utils.getRandomStr(32);
    }
    this.info = await getImageInfo(this.file);

    this.setStatus('inited');
  }

  async upload() {
    // 状态检测
    const enableStatus = ['inited', 'complete', 'error', 'abort'];
    if (!enableStatus.includes(this.status)) {
      throw new Error(`上传出错, 当前状态 ${this.status} 不允许上传`);
    }

    this.setStatus('uploading');

    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('type', this.info.type);
    formData.append('is_dynamic', this.info.isAnimation.toString());

    try {
      const { data } = await axios({
        method: 'post',
        url: '/kyle/common_storage/image/upload',
        data: formData,
        onUploadProgress: (evt) => {
          const target = {
            total: {
              loaded: evt.loaded,
              size: evt.total,
              percent: (evt.loaded / evt.total) * 100,
            },
          };
          this.trigger('upload:progress', target);
        },
      });

      this.key = data.image;
      this.uploadUrl = data.image;
      this.sourceUrl = data.display_image;

      if (data.first_image) {
        this.info.firstImage = data.first_image;
        this.info.firstImageDisplay = data.display_first_image;
      }

      this.setStatus('complete');
      this.trigger('upload:success', this);
    } catch (err) {
      this.setStatus('error');
      this.trigger('upload:error', err);
      this.trigger('error', err);
    }
  }

  abort() {
    // 状态检测
    const enableStatus = ['uploading'];
    if (!enableStatus.includes(this.status)) {
      console.error(`当前状态 ${this.status} 中止无效`);
      return false;
    }

    this.setStatus('abort');
  }
}

export default InnerImgUploader;
