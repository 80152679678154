import xss from '@kkmh/xss';
import { DateTime, Settings as luxonSettings } from 'luxon';
import {
  isPlainObject,
  forEach,
  cloneDeep,
  startsWith,
  isFunction,
  debounce,
} from 'lodash';
import { isPromise } from './types';
import mimeType from '../mimeType';

// 设置默认语言为中文
luxonSettings.defaultLocale = 'zh-cn';
export { debounce, DateTime, xss };

/** @type {function} 深度克隆 */
export const deepClone = cloneDeep;

export const ifStartsWith = startsWith;

/**
 * 尝试当做函数运行, 返回 promise
 * @param {any} fuc 执行的对象, 兼容 async 函数或者普通函数
 * @param {...any} arg 执行函数的参数
 */
export async function tryRun(fuc, ...arg) {
  if (!isFunction(fuc)) return false;
  const result = fuc(...arg);
  if (!isPromise(result)) {
    return result;
  }
  return result.then(data => data);
}

/**
 * 格式化月
 * @param {timeStamp} timeStamp 时间戳
 * @returns 2021-01
 */
export function formatMonth(timeStamp) {
  if (!timeStamp) {
    return '';
  }
  const dt = DateTime.fromJSDate(new Date(timeStamp));
  if (!dt.isValid) {
    return '';
  }
  return dt.toFormat('y-LL');
}

/**
 * 格式化日期
 * @param {timeStamp} timeStamp 时间戳
 * @returns 2021-01-23
 */
export function formatDate(timeStamp) {
  if (!timeStamp) {
    return '';
  }
  const dt = DateTime.fromJSDate(new Date(timeStamp));
  if (!dt.isValid) {
    return '';
  }
  return dt.toFormat('y-LL-dd');
}

/**
 * 格式化时间
 * @param {timeStamp} timeStamp 时间戳
 * @returns 2021-01-23 23:15:12
 */
export function formatTime(timeStamp) {
  if (!timeStamp) {
    return '';
  }

  const dt = DateTime.fromJSDate(new Date(timeStamp));
  if (!dt.isValid) {
    return '';
  }
  return dt.toFormat('y-LL-dd HH:mm:ss');
}
/**
 * 获取当前运行环境
 * @returns {'local'|'development'|'stag'|'preview'|'production'}
 */
export function getRunEnvironment() {
  if (window.location.port) {
    return 'local';
  }
  if (window.location.hostname === 'houyi.quickcan.cn') {
    return 'stag';
  }
  if (window.location.hostname === 'dev-houyi.quickcan.cn') {
    return 'development';
  }
  if (window.location.hostname === 'preview-houyi.quickcan.cn') {
    return 'preview';
  }
  return 'production';
}

// 序列化对象
export function serialize(obj) {
  if (!isPlainObject(obj)) {
    return '';
  }
  const queryString = [];

  forEach(obj, (value, key) => {
    if (value === null || value === undefined) {
      // eslint-disable-next-line
      console.log(`can't serialize ${key}:`, value);
      return;
    }
    queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`);
  });

  return queryString.join('&');
}

function insertItem(item, arr) {
  const { order } = item;
  if (typeof arr[order] !== 'number') {
    // eslint-disable-next-line
    arr[order] = item;
    return;
  }
  let moveBegin;
  let moveEnd;
  let pos;
  let i = order + 1;

  while (arr[i]) {
    if (arr[i].order > order) {
      if (!moveBegin) {
        moveBegin = i;
        pos = i;
      }
    }
    i += 1;
  }

  if (moveBegin) {
    moveEnd = i;
  } else {
    pos = i;
  }

  if (!moveEnd) {
    // eslint-disable-next-line
    arr[pos] = item;
    return;
  }

  // 需要移动
  for (let j = moveEnd; j >= moveBegin; j -= 1) {
    // eslint-disable-next-line
    arr[j + 1] = arr[j];
  }
  // eslint-disable-next-line
  arr[pos] = item;
}

/**
 * 根据数组的 order 字段排序
 * @param {Array} source
 */
export const sortByOrder = (source = []) => {
  if (!Array.isArray(source)) {
    // eslint-disable-next-line
    console.error('sortByOrder 传入参数不符合要求, 应为数组', source);
    return source;
  }
  const tmp = [];
  let target = [];

  // 将带排序的子项添加进临时数组 tmp
  for (let i = 0; i < source.length; i += 1) {
    if (typeof source[i].order !== 'number') {
      // eslint-disable-next-line
      continue;
    }
    let { order } = source[i];
    // 支持设置倒数顺序
    if (order < 0) {
      order = source.length + order;
      if (order < 0) {
        order = 0;
      }
    }

    // 确保整数
    // eslint-disable-next-line
    source[i].order = Math.floor(order);

    // 插入临时数组
    insertItem(source[i], tmp);
  }

  // 合并临时数组和原数组
  for (let i = 0, j = 0; i < source.length; i += 1) {
    if (typeof source[i].order === 'number') {
      // eslint-disable-next-line
      continue;
    }
    // 找需要填的坑
    while (tmp[j]) {
      j += 1;
    }
    tmp[j] = source[i];
  }
  // 筛除空隙
  target = tmp.filter(item => !!item);
  return target;
};

export function transTimeInTable(time, lines) {
  if (time) {
    const timeStr = typeof time === 'string' ? time : formatTime(time);
    return lines ? timeStr.replace(' ', '<br/>') : timeStr;
  }
  return '-';
}

/**
 * 限制长文本展示
 * @param {string} text 原字符串
 * @param {number} limit 限制展示字符数
 * @returns {string} 限制字数后的新字符串
 */
export function limitText(text, limit = 25) {
  if (!text) {
    return '';
  }
  if (limit < 3) { // 忽略小于3的情况, 没必要
    return text;
  }

  const t = text.toString();
  if (t.length <= limit) {
    return t;
  }
  const regex = /[,.'"!:，。；“”？！：\s+]/; // 检测标点符号空格等
  let result = t.slice(0, limit);
  let i = result.length;

  while (i > 0 && regex.test(result.charAt(i - 1))) {
    i -= 1;
  }

  if (i > 0) {
    result = `${result.slice(0, i)}...`;
  }
  return result;
}

export function sleep(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

/* eslint-disable func-names */
const { hasOwnProperty } = Object.prototype;

export function noop() {}

export function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
}

export const generateId = function () {
  return Math.floor(Math.random() * 10000);
};

// 返回 n 位的随机字符串
export const getRandomStr = (n = 6) => {
  let str = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
  for (let i = 0; i < n; i += 1) {
    str += chars.charAt(Math.floor(Math.random() * 62));
  }
  return str;
};

// coerce truthy value to array
export const coerceTruthyValueToArray = function (val) {
  if (Array.isArray(val)) {
    return val;
  }
  if (val) {
    return [val];
  }
  return [];
};

export const kebabCase = function (str) {
  const hyphenateRE = /([^-])([A-Z])/g;
  return str
    .replace(hyphenateRE, '$1-$2')
    .replace(hyphenateRE, '$1-$2')
    .toLowerCase();
};

export const capitalize = function (str) {
  if (typeof str !== 'string') return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isEmpty = function (val) {
  // null or undefined
  if (val == null) return true;

  if (typeof val === 'boolean') return false;

  if (typeof val === 'number') return !val;

  if (val instanceof Error) return val.message === '';

  // eslint-disable-next-line default-case
  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;

    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size;
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }

  return false;
};

export function rafThrottle(fn) {
  let locked = false;
  // eslint-disable-next-line func-names
  return function (...args) {
    if (locked) return;
    locked = true;
    window.requestAnimationFrame(() => {
      fn.apply(this, args);
      locked = false;
    });
  };
}

export function objToArray(obj) {
  if (Array.isArray(obj)) {
    return obj;
  }
  return isEmpty(obj) ? [] : [obj];
}

/** 下载 */
export function download(url, filename = '') {
  const link = document.createElement('a');
  if (filename) {
    link.download = filename;
  }
  link.href = url;
  link.click();
}

/** 异步加载远程 js */
export function loadScript(src, async = true) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.async = async;
    s.src = src;
    s.onerror = reject;
    s.onload = resolve;

    // 兼容 IE 11 下
    let loaded = false;
    s.onreadystatechange = () => {
      if (!loaded && (!s.readyState || s.readyState === 'loaded' || s.readyState === 'complete')) {
        loaded = true;
        resolve();
      }
    };
    document.head.appendChild(s);
  });
}

/** 手动检测文件 MIME 是否符合 accept 配置要求 */
export function validateAcceptAttr(accept, file) {
  if (!accept) {
    return true;
  }
  const targetMimeType = file.type;
  const acceptRules = accept.split(',').map(item => item.trim());
  // 满足一条规则即通过
  const isPass = acceptRules.some((item) => {
    if (!item) {
      return false;
    }
    // 后缀模式
    if (item.indexOf('.') === 0) {
      const allowMimeType = mimeType.allSuffixes[item];
      if (!allowMimeType) {
        console.error(`出现未知后缀限制 ${item}, 如需扩展该后缀支持请检查 mimeType 文件, 并放开对应注释`);
        // 检查文件后缀是否完全一致
        const fileSuffixes = `.${file.name.split('.').pop()}`;
        return fileSuffixes === item;
      }
      return allowMimeType.includes(targetMimeType);
    }
    // mime 通配符模式, image/*
    if (item.indexOf('/*') > 0) {
      const type = item.split('/')[0];
      const targetType = targetMimeType.split('/')[0];
      return type === targetType;
    }
    // 精确 mime 模式
    if (item.indexOf('/') > 0) {
      return (item === targetMimeType);
    }
    return true;
  });
  return isPass;
}
