<template>
  <span>
    <span class="status-dot" :class="classNameText" />
    <span class="status-text" style="margin-left: 8px;"><slot /></span>
  </span>
</template>

<script>
export default {
  name: 'KkStatusText',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    classNameText() {
      if (this.type === 'primary') {
        return 'status-primary';
      }
      if (this.type === 'success') {
        return 'status-success';
      }
      if (this.type === 'warning') {
        return 'status-warning';
      }
      if (this.type === 'danger') {
        return 'status-danger';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
// $--color-primary:#5284D5;
// $--color-success:#53BA55;
// $--color-warning:#DFB237;
// $--color-danger:#E04545;

.status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-left: 2px;

}

.status-primary,
.status-success,
.status-warning,
.status-danger {
  position: relative;

  &:after {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: antStatusProcessing 1.2s ease-in-out infinite;
    content: "";
  }
}

.status-primary {
  background-color: $--color-primary;

  &:after {
    border: 1px solid $--color-primary;
  }
}

.status-success {
  background-color: $--color-success;

  &:after {
    border: 1px solid $--color-success;
  }
}

.status-warning {
  background-color: $--color-warning;

  &:after {
    border: 1px solid $--color-warning;
  }
}

.status-danger {
  background-color: $--color-danger;

  &:after {
    border: 1px solid $--color-danger;
  }
}
</style>
<style lang="scss">
@keyframes antStatusProcessing {
  0% {
    transform: scale(.8);
    opacity: .5
  }

  to {
    transform: scale(2.4);
    opacity: 0
  }
}
</style>
