const allSuffixes = {
  '.apk': ['application/vnd.android.package-archive'], // 新增
  '.asp': ['text/asp'],
  '.avi': ['application/x-troff-msvideo', 'video/avi', 'video/msvideo', 'video/x-msvideo'],
  '.bin': ['application/mac-binary', 'application/macbinary', 'application/octet-stream', 'application/x-binary', 'application/x-macbinary'],
  '.bm': ['image/bmp'],
  '.bmp': ['image/bmp', 'image/x-windows-bmp'],
  '.cpp': ['text/x-c'],
  '.cpt': ['application/mac-compactpro', 'application/x-compactpro', 'application/x-cpt'],
  '.crl': ['application/pkcs-crl', 'application/pkix-crl'],
  '.crt': ['application/pkix-cert', 'application/x-x509-ca-cert', 'application/x-x509-user-cert'],
  '.csh': ['application/x-csh', 'text/x-script.csh'],
  '.css': ['application/x-pointplus', 'text/css'],
  '.csv': ['text/csv'], // 新增
  '.cxx': ['text/plain'],
  '.dcr': ['application/x-director'],
  '.def': ['text/plain'],
  '.dif': ['video/x-dv'],
  '.dir': ['application/x-director'],
  '.dl': ['video/dl', 'video/x-dl'],
  '.doc': ['application/msword'],
  '.dot': ['application/msword'],
  '.dp': ['application/commonground'],
  '.exe': ['application/octet-stream'],
  '.fli': ['video/fli', 'video/x-fli'],
  '.flo': ['image/florian'],
  '.flx': ['text/vnd.fmi.flexstor'],
  '.fmf': ['video/x-atomic3d-feature'],
  '.for': ['text/plain', 'text/x-fortran'],
  '.fpx': ['image/vnd.fpx', 'image/vnd.net-fpx'],
  '.g': ['text/plain'],
  '.g3': ['image/g3fax'],
  '.gif': ['image/gif'],
  '.gl': ['video/gl', 'video/x-gl'],
  '.gsd': ['audio/x-gsm'],
  '.gsm': ['audio/x-gsm'],
  '.gtar': ['application/x-gtar'],
  '.gz': ['application/x-compressed', 'application/x-gzip'],
  '.gzip': ['application/x-gzip', 'multipart/x-gzip'],
  '.h': ['text/plain', 'text/x-h'],
  '.hdf': ['application/x-hdf'],
  '.help': ['application/x-helpfile'],
  '.hgl': ['application/vnd.hp-hpgl'],
  '.hh': ['text/plain', 'text/x-h'],
  '.htm': ['text/html'],
  '.html': ['text/html'],
  '.htmls': ['text/html'],
  '.isu': ['video/x-isvideo'],
  '.it': ['audio/it'],
  '.jam': ['audio/x-jam'],
  '.jav': ['text/plain', 'text/x-java-source'],
  '.java': ['text/plain', 'text/x-java-source'],
  '.jfif': ['image/jpeg', 'image/pjpeg'],
  '.jfif-tbnl': ['image/jpeg'],
  '.jpe': ['image/jpeg', 'image/pjpeg'],
  '.jpeg': ['image/jpeg', 'image/pjpeg'],
  '.jpg': ['image/jpeg', 'image/pjpeg'],
  '.js': ['application/x-javascript', 'application/javascript', 'application/ecmascript', 'text/javascript', 'text/ecmascript'],
  '.log': ['text/plain'],
  '.lsp': ['application/x-lisp', 'text/x-script.lisp'],
  '.lst': ['text/plain'],
  '.lsx': ['text/x-la-asf'],
  '.ltx': ['application/x-latex'],
  '.m': ['text/plain', 'text/x-m'],
  '.m1v': ['video/mpeg'],
  '.m2a': ['audio/mpeg'],
  '.m2v': ['video/mpeg'],
  '.m3u': ['audio/x-mpequrl'],
  '.mjpg': ['video/x-motion-jpeg'],
  '.mov': ['video/quicktime'],
  '.movie': ['video/x-sgi-movie'],
  '.mp2': ['audio/mpeg', 'audio/x-mpeg', 'video/mpeg', 'video/x-mpeg', 'video/x-mpeq2a'],
  '.mp3': ['audio/mpeg3', 'audio/x-mpeg-3', 'video/mpeg', 'video/x-mpeg'],
  '.mpa': ['audio/mpeg', 'video/mpeg'],
  '.mpe': ['video/mpeg'],
  '.mpeg': ['video/mpeg'],
  '.mpg': ['audio/mpeg', 'video/mpeg'],
  '.mpga': ['audio/mpeg'],
  '.mv': ['video/x-sgi-movie'],
  '.pbm': ['image/x-portable-bitmap'],
  '.pct': ['image/x-pict'],
  '.pcx': ['image/x-pcx'],
  '.pdb': ['chemical/x-pdb'],
  '.pdf': ['application/pdf'],
  '.pic': ['image/pict'],
  '.pict': ['image/pict'],
  '.pkg': ['application/x-newton-compatible-pkg'],
  '.png': ['image/png'],
  '.pot': ['application/mspowerpoint', 'application/vnd.ms-powerpoint'],
  '.ppa': ['application/vnd.ms-powerpoint'],
  '.pps': ['application/mspowerpoint', 'application/vnd.ms-powerpoint'],
  '.ppsm': ['application/vnd.ms-powerpoint'], // 新增
  '.ppt': ['application/mspowerpoint', 'application/powerpoint', 'application/vnd.ms-powerpoint', 'application/x-mspowerpoint'],
  '.pptm': ['application/vnd.ms-powerpoint'], // 新增
  '.pwz': ['application/vnd.ms-powerpoint'],
  '.py': ['text/x-script.phyton'],
  '.pyc': ['application/x-bytecode.python'],
  '.rf': ['image/vnd.rn-realflash'],
  '.rgb': ['image/x-rgb'],
  '.rt': ['text/richtext', 'text/vnd.rn-realtext'],
  '.rtf': ['application/rtf', 'application/x-rtf', 'text/richtext'],
  '.rtx': ['application/rtf', 'text/richtext'],
  '.sgm': ['text/sgml', 'text/x-sgml'],
  '.sgml': ['text/sgml', 'text/x-sgml'],
  '.swf': ['application/x-shockwave-flash'],
  '.text': ['application/plain', 'text/plain'],
  '.tif': ['image/tiff', 'image/x-tiff'],
  '.tiff': ['image/tiff', 'image/x-tiff'],
  '.txt': ['text/plain'],
  '.vcd': ['application/x-cdlink'],
  '.vcs': ['text/x-vcalendar'],
  '.viv': ['video/vivo', 'video/vnd.vivo'],
  '.vivo': ['video/vivo', 'video/vnd.vivo'],
  '.w60': ['application/wordperfect6.0'],
  '.w61': ['application/wordperfect6.1'],
  '.w6w': ['application/msword'],
  '.wav': ['audio/wav', 'audio/x-wav'],
  '.wb1': ['application/x-qpro'],
  '.wbmp': ['image/vnd.wap.wbmp'],
  '.web': ['application/vnd.xara'],
  '.webp': ['image/webp'], // 添加
  '.wiz': ['application/msword'],
  '.wmf': ['windows/metafile'],
  '.wml': ['text/vnd.wap.wml'],
  '.wmlc': ['application/vnd.wap.wmlc'],
  '.wmls': ['text/vnd.wap.wmlscript'],
  '.wmlsc': ['application/vnd.wap.wmlscriptc'],
  '.word': ['application/msword'],
  '.wp': ['application/wordperfect'],
  '.wp5': ['application/wordperfect', 'application/wordperfect6.0'],
  '.wp6': ['application/wordperfect'],
  '.wpd': ['application/wordperfect', 'application/x-wpwin'],
  '.xbm': ['image/x-xbitmap', 'image/x-xbm', 'image/xbm'],
  '.xl': ['application/excel'],
  '.xla': ['application/excel', 'application/x-excel', 'application/x-msexcel'],
  '.xlb': ['application/excel', 'application/vnd.ms-excel', 'application/x-excel'],
  '.xlc': ['application/excel', 'application/vnd.ms-excel', 'application/x-excel'],
  '.xld': ['application/excel', 'application/x-excel'],
  '.xlk': ['application/excel', 'application/x-excel'],
  '.xll': ['application/excel', 'application/vnd.ms-excel', 'application/x-excel'],
  '.xlm': ['application/excel', 'application/vnd.ms-excel', 'application/x-excel'],
  '.xls': ['application/excel', 'application/vnd.ms-excel', 'application/x-excel', 'application/x-msexcel'],
  '.xlsb': ['application/vnd.ms-excel'], // 新增
  '.xlsm': ['application/vnd.ms-excel'], // 新增
  '.xlt': ['application/excel', 'application/x-excel'],
  '.xlv': ['application/excel', 'application/x-excel'],
  '.xlw': ['application/excel', 'application/vnd.ms-excel', 'application/x-excel', 'application/x-msexcel'],
  '.xm': ['audio/xm'],
  '.xml': ['application/xml', 'text/xml'],
  '.xpm': ['image/x-xpixmap', 'image/xpm'],
  '.x-png': ['image/png'],
  '.xsr': ['video/x-amt-showrun'],
  '.xyz': ['chemical/x-pdb'],
  '.z': ['application/x-compress', 'application/x-compressed'],
  '.zip': ['application/x-compressed', 'application/x-zip-compressed', 'application/zip', 'multipart/x-zip'],
  '.zoo': ['application/octet-stream'],
  '.zsh': ['text/x-script.zsh'],
};

const allMimeType = {};

Object.keys(allSuffixes).forEach((suffixes) => {
  allSuffixes[suffixes].forEach((mimeType) => {
    allMimeType[mimeType] = allMimeType[mimeType] || [];
    allMimeType[mimeType].push(suffixes);
  });
});

export default {
  allSuffixes,
  allMimeType,
};
