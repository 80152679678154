<template>
  <div id="app-container" style="display: flex; flex-direction: column; height: 100vh; overflow: auto; min-width: 1200px; background-color: #f0f2f5;">
    <app-header :title="title" />
    <app-body>
      <AppTips>
        <slot name="globalTips"></slot>
      </AppTips>
      <slot />
    </app-body>
    <app-footer />
    <el-backtop target="#app-container" />
  </div>
</template>

<script>
import AppHeader from './header.vue';
import AppFooter from './footer.vue';
import AppBody from './body.vue';
import AppTips from './AppTips';

export default {
  name: 'AppContainer',
  components: {
    AppHeader,
    AppFooter,
    AppBody,
    AppTips,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
