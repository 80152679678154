/**
 * @author 圈圈
 * @description 文件上传
 */
import axios from '@/plugins/axios.js';
import Uploader from './Uploader';
import * as utils from './utils';

const defaultConfig = { useCatch: false };

class FileUploader extends Uploader {
  constructor(file, config = defaultConfig) {
    super(file);
    /** 用于提交给服务器的地址 */
    this.uploadUrl = '';
    this.sourceUrl = '';
    this.downloadUrl = '';
    this.key = '';
    this.config = { ...defaultConfig, ...config };
    this.hash = '';

    this.info = null;
  }

  async init() {
    try {
      this.hash = await utils.getQiniuHash(this.file);
    } catch (err) {
      // 计算 hash 出错, 使用随机字符串代替
      this.hash = utils.getRandomStr(32);
    }
    this.info = {
      name: this.file.name,
      size: this.file.size,
      type: this.file.type,
    };

    this.setStatus('inited');
  }

  async upload() {
    // 状态检测
    const enableStatus = ['inited', 'complete', 'error', 'abort'];
    if (!enableStatus.includes(this.status)) {
      throw new Error(`上传出错, 当前状态 ${this.status} 不允许上传`);
    }

    this.setStatus('uploading');

    const formData = new FormData();
    formData.append('file', this.file);

    try {
      const { data } = await axios({
        method: 'post',
        url: '/kyle/common_storage/file/upload',
        data: formData,
        onUploadProgress: (evt) => {
          const target = {
            total: {
              loaded: evt.loaded,
              size: evt.total,
              percent: (evt.loaded / evt.total) * 100,
            },
          };
          this.trigger('upload:progress', target);
        },
      });

      this.key = data.key;
      this.uploadUrl = data.key;
      this.sourceUrl = data.file_download_url;
      this.downloadUrl = `/kyle/common_storage/file/download?file_name=${data.key}`;

      this.setStatus('complete');
      this.trigger('upload:success', this);
    } catch (err) {
      this.setStatus('error');
      this.trigger('upload:error', err);
      this.trigger('error', err);
    }
  }

  abort() {
  // 状态检测
    const enableStatus = ['uploading'];
    if (!enableStatus.includes(this.status)) {
      console.error(`当前状态 ${this.status} 中止无效`);
      return false;
    }

    this.setStatus('abort');
  }
}

export default FileUploader;
