<template>
  <el-menu
    :router="true"
    mode="horizontal"
    style="width: 100%; border-bottom: none;"
    :default-active="active"
    @select="menuSelect">
    <el-menu-item v-show="false" key="home" index="home" route="/">
      <template slot="title">
        <span slot="title">首页</span>
      </template>
    </el-menu-item>
    <!-- 一级目录 -->
    <template v-for="item in navConfig">
      <el-submenu v-if="item.type === 'folder'" :key="item.id" :index="'folder-' + item.id">
        <template slot="title">
          <span slot="title">{{item.title}}</span>
        </template>
        <!-- 二级目录 -->
        <template v-for="subitem in item.children">
          <el-submenu v-if="subitem.type === 'folder'" :key="subitem.id" :index="'folder-' + subitem.id">
            <template slot="title">
              <span slot="title">{{subitem.title}}</span>
            </template>
            <!-- 三级目录 -->
            <template v-for="thirdItem in subitem.children">
              <el-menu-item v-if="thirdItem.type === 'page'" :key="thirdItem.name" :index="thirdItem.name" :route="thirdItem.route">
                <template slot="title">
                  <span slot="title">{{thirdItem.title}}</span>
                </template>
              </el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item v-else :key="subitem.name" :index="'page-' + subitem.id" :route="subitem.route">
            <template slot="title">
              <span slot="title">{{subitem.title}}</span>
            </template>
          </el-menu-item>
        </template>
      </el-submenu>
      <el-menu-item v-else-if="item.type === 'page'" :key="item.id" :index="item.name" :route="item.route">
        <template slot="title">
          <span slot="title">{{item.title}}</span>
        </template>
      </el-menu-item>
      <el-menu-item v-else-if="item.type === 'link'" :key="item.id" :index="'link-' + item.id">
        <template slot="title">
          <span slot="title">{{item.title}}
            <i class="el-icon-link" style="vertical-align: text-bottom;" />
          </span>
        </template>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
export default {
  name: 'AppNav',
  components: {},
  data() {
    return {
      active: this.$route.name,
    };
  },
  watch: {
    $route(to) {
      this.active = to.name;
    },
  },
  computed: {
    isCollapse() {
      return this.$store.state.app.sideNavIsCollapse;
    },
    navConfig() {
      return this.$store.state.app.sideNavConfig;
    },
  },
  mounted() {},
  methods: {
    menuSelect(evt) {
      const [type, id] = (evt.split('-') || []);
      if (type === 'link') {
        const target = this.findConfigById(parseInt(id, 10));
        if ((target || {}).link) {
          window.open(target.link);
        }
      }
    },
    findConfigById(id) {
      // todo: 改为递归搜索
      for (let i1 = 0; i1 < this.navConfig.length; i1 += 1) {
        if (this.navConfig[i1].id === id) {
          return this.navConfig[i1];
        }
        if ((this.navConfig[i1].children || []).length > 0) {
          for (let i2 = 0; i2 < this.navConfig[i1].children.length; i2 += 1) {
            if (this.navConfig[i1].children[i2].id === id) {
              return this.navConfig[i1].children[i2];
            }
          }
        }
      }
    },
  },
};
</script>
