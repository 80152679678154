/**
 * 7牛官方提供的算法 https://github.com/qiniu/qetag
 * 转换成浏览器可执行版本 https://www.jianshu.com/p/3785fc314fc5
 */
let jsSha1;

/** sha1算法 */
// eslint-disable-next-line space-before-function-paren
const shA1 = async (buffer) => {
  // 能力检测, 如无浏览器原生 sha1 算法, 则使用
  if (!((crypto || {}).subtle || {}).digest) {
    if (!jsSha1) {
      jsSha1 = await import('js-sha1');
    }
    // @ts-ignore
    return jsSha1.digest(buffer);
  }
  const res = await crypto.subtle.digest('SHA-1', buffer);
  return Array.from(new Uint8Array(res));
};

/** Array 2 Uint8Array */
const concatArr2Uint8 = (s) => {
  let tmp = [];
  // eslint-disable-next-line
  for (const i of s) {
    tmp = tmp.concat(i);
  }
  return new Uint8Array(tmp);
};

/** Uint8Array 2 Base64 */
function Uint8ToBase64(u8Arr, uriSafe) {
  const CHUNK_SIZE = 0x8000; // arbitrary number
  let index = 0;
  const { length } = u8Arr;
  let result = '';
  let slice;
  while (index < length) {
    slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
    result += String.fromCharCode.apply(null, slice);
    index += CHUNK_SIZE;
  }
  return uriSafe ? btoa(result).replace(/\//g, '_').replace(/\+/g, '-') : btoa(result);
}

async function getEtag(buffer) {
  // 以4M为单位分割
  const blockSize = 4 * 1024 * 1024;
  let sha1String = [];
  let prefix = 0x16;
  let blockCount = 0;

  const bufferSize = buffer.size || buffer.length || buffer.byteLength;
  blockCount = Math.ceil(bufferSize / blockSize);

  const promiseTasks = [];
  for (let i = 0; i < blockCount; i += 1) {
    promiseTasks.push(shA1(buffer.slice(i * blockSize, (i + 1) * blockSize)));
  }
  sha1String = await Promise.all(promiseTasks);
  // sha1String.push(shA1(buffer.slice(i * blockSize, (i + 1) * blockSize)));

  if (!sha1String.length) {
    return 'Fto5o-5ea0sNMlW_75VgGJCv2AcJ';
  }
  let sha1Buffer = concatArr2Uint8(sha1String);
  // 如果大于4M，则对各个块的sha1结果再次sha1
  if (blockCount > 1) {
    prefix = 0x96;
    sha1Buffer = await shA1(sha1Buffer.buffer);
  } else {
    sha1Buffer = Array.apply([], sha1Buffer);
  }
  sha1Buffer = concatArr2Uint8([[prefix], sha1Buffer]);
  return Uint8ToBase64(sha1Buffer, true);
}

export default getEtag;
