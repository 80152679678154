/**
 * @author 圈圈
 * @description 腾讯云上传
 * https://cloud.tencent.com/document/product/436/11459
 */
import * as utils from './utils';
import Uploader from './Uploader';
import TencentCos from './utils/TencentCos';

/** 已上传的文件缓存 3 天 */
const catchEffectTime = 3 * 24 * 60 * 60 * 1000;
const defaultConfig = { useCatch: false, prefix: 'file' };

/** 腾讯云上传实例缓存 */
let tencentCosInstance;

/** 更新缓存 */
function refreshCatch(catchList) {
  localStorage.setItem('eff_cos_catch', JSON.stringify(catchList));
}

/** 获取缓存 */
function getCatch() {
  const catchList = JSON.parse(localStorage.getItem('eff_cos_catch') || '[]');
  const result = [];
  const now = Date.now();
  let restore = false;
  catchList.forEach((item) => {
    if (item.expires < now) {
      restore = true;
      return;
    }
    if (item.status === 'error') {
      restore = true;
      return;
    }
    result.push(item);
  });

  if (restore) {
    refreshCatch(result);
  }
  return result;
}

class TencentUploader extends Uploader {
  constructor(file, key = '', config = {}) {
    super(file, key);

    this.key = key;
    this.uploadUrl = '';
    this.sourceUrl = '';
    this.downloadUrl = '';
    this.config = { ...defaultConfig, ...config };
    this.cos = tencentCosInstance;
    this.hash = '';
    this.uploadId = null;
    this.info = {
      name: this.file.name,
      type: this.file.type,
      size: this.file.size,
    };
    // console.log(this);
  }

  async init() {
    if (!this.cos) {
      tencentCosInstance = new TencentCos();
      await tencentCosInstance.init();
      this.cos = tencentCosInstance;
    }

    try {
      this.hash = await utils.getQiniuHash(this.file);
    } catch (err) {
      // 计算 hash 出错, 使用随机字符串代替
      this.hash = utils.getRandomStr(32);
    }

    if (!this.key) {
      this.setKey();
    }
    this.setStatus('inited');
  }

  setKey(key = '') {
    // 根据文件标识 hash 检查缓存
    const catchItem = (this.global.catch.find(item => (item.hash === this.hash)));

    if (!catchItem) { // 未命中缓存, 创建 key 并增加缓存
      this.key = key || `${this.config.prefix}/${utils.dateKey()}/${utils.getRandomStr(9)}.${utils.getFileSuffix(this.file)}`;
      // 缓存 key 与 hash 的关系
      this.global.catch.push({
        key: this.key,
        hash: this.hash,
        expires: Date.now() + catchEffectTime,
        status: '',
      });
      refreshCatch(this.global.catch);
      return;
    }

    // 检查 key 是否命中
    if (key && catchItem.key !== key) { // 强制传入 key, 且 key 与命中缓存不相等, 更新缓存 key 和过期时间
      this.key = key;
      catchItem.key = key;
      catchItem.expires = Date.now() + catchEffectTime;
      refreshCatch(this.global.catch);
      return;
    }

    // 设为缓存 key 值
    this.key = catchItem.key;
    catchItem.expires = Date.now() + catchEffectTime;
    refreshCatch(this.global.catch);
  }

  async upload() {
    // 状态检测
    const enableStatus = ['inited', 'complete', 'error', 'abort'];
    if (!enableStatus.includes(this.status)) {
      throw new Error(`上传出错, 当前状态 ${this.status} 不允许上传`);
    }

    this.setStatus('uploading');
    const catchItem = this.global.catch.find(item => (item.hash === this.hash));

    // 检查是否使用缓存
    if (this.config.useCatch) {
      if (catchItem && catchItem.status === 'uploaded') {
        this.uploadUrl = this.key;
        this.sourceUrl = this.key;
        this.downloadUrl = '';
        this.setStatus('complete');
        this.trigger('upload:success', {
          hash: this.hash,
          key: this.key,
        });
        return;
      }
    }

    try {
      // 上传文件
      const data = await this.cos.sliceUploadFile(this.file, this.key, (taskId) => {
        console.log('上传任务id', taskId);
        this.uploadId = taskId;
      }, (progress) => {
        const target = {
          total: {
            loaded: progress.loaded,
            size: progress.total,
            percent: progress.percent * 100,
          },
        };
        this.trigger('upload:progress', target);
      });
      this.uploadUrl = this.key;
      this.sourceUrl = this.key;
      this.downloadUrl = '';

      // 更新缓存状态
      if (catchItem) {
        catchItem.status = 'uploaded';
        refreshCatch(this.global.catch);
      }
      this.setStatus('complete');
      this.trigger('upload:success', data);
    } catch (err) {
      let errInfo = {};
      if (!err) {
        errInfo.message = '上传错误';
      } else if (typeof err === 'string') {
        errInfo.message = err;
      }
      if (typeof err === 'object') {
        errInfo = { ...err };
        errInfo.message = err.message || err.error || '上传错误';
      }
      this.setStatus('error');
      this.trigger('upload:error', errInfo);
      this.trigger('error', errInfo);

      // 更新缓存状态
      if (catchItem) {
        catchItem.status = 'error';
        refreshCatch(this.global.catch);
      }
    }
  }

  abort() {
    // 状态检测
    const enableStatus = ['uploading'];
    if (!enableStatus.includes(this.status)) {
      console.error(`当前状态 ${this.status} 中止无效`);
      return false;
    }
    if (this.uploadId) {
      this.cos.abort(this.uploadId);
    }
    this.setStatus('abort');
  }

  pause() {
    if (this.uploadId) {
      this.cos.pause(this.uploadId);
    }
    this.setStatus('pause');
  }

  restart() {
    if (this.uploadId) {
      this.cos.restart(this.uploadId);
      this.setStatus('uploading');
    }
  }
}

TencentUploader.prototype.global = {
  catch: getCatch(),
};
export default TencentUploader;
