import { DateTime, getRandomStr } from '@/assets/js/utils';
import getEtag from './getEtag';

export { getRandomStr };

export async function getQiniuHash(file) {
  if (!file || !(file instanceof File)) {
    throw new Error('getQiniuHash error: 需传入 File 对象');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (data) => {
      try {
        const result = await getEtag(data.target.result);
        resolve(result);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = () => {
      reject(new Error('FileReader onerror'));
    };
    reader.readAsArrayBuffer(file);
  });
}

/**
 * 获取文件后缀
 * @param {File} file
 */
export function getFileSuffix(file) {
  return file.name.split('.').pop();
}

/** 获取时机相关 key 字符串, 用户key构建, 格式 yyMMdd: 2020-07-22 => 200722 */
export function dateKey() {
  const dt = DateTime.fromJSDate(new Date());
  if (!dt.isValid) {
    return '';
  }
  return dt.toFormat('yyLLdd');
}

/** 延迟函数 */
export function sleep(time = 3000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}
