/**
 * 创建全局过滤器
 * 过滤器使用: https://cn.vuejs.org/v2/guide/filters.html
 */
import Vue from 'vue';
import {
  formatTime,
  formatDate,
  formatMonth,
  limitText,
  xss,
  formatMoney,
  formatInt,
  formatPercent,
} from './assets/js/utils';

// 时间显示过滤器, 显示示例: 2018-11-03 17:27:39
Vue.filter('formatTime', value => formatTime(value));

// 日期显示过滤器, 显示示例: 2018-11-03
Vue.filter('formatDate', value => formatDate(value));

// 月份显示过滤器, 显示示例: 2018-11
Vue.filter('formatMonth', value => formatMonth(value));

// 限制字符串长度, 过长部分使用 "..." 显示
Vue.filter('limitText', (text, limit = 30) => limitText(text, limit));

// 格式化金额, 金额统一向下取 1.9999 => $1.99
Vue.filter('formatMoney', formatMoney);

// 格式化整数
Vue.filter('formatInt', formatInt);

// 格式化百分比
Vue.filter('formatPercent', formatPercent);

/**
 * XSS 攻击处理, 过滤 html 代码字符串
 * wiki: https://wiki.quickcan.com/pages/viewpage.action?pageId=318505051
 * 过滤配置: https: //git.quickcan.com/kk-front/modules-repo/-/blob/master/xss/src/index.js
 */
Vue.filter('xss', htmlStr => xss(htmlStr));
