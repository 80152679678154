const EventSymbol = Symbol('Event');

class EventObj {
  constructor() {
    this[EventSymbol] = Object.create(null);
  }

  /** 添加事件监听, 仅监听一次 */
  once(evtName, fuc) {
    this[EventSymbol][evtName] = this[EventSymbol][evtName] || new Set();
    this[EventSymbol][evtName].add({
      handle: fuc,
      once: true,
    });
  }

  /** 添加事件监听 */
  on(evtName, fuc, once = false) {
    this[EventSymbol][evtName] = this[EventSymbol][evtName] || new Set();
    this[EventSymbol][evtName].add({
      handle: fuc,
      once,
    });
  }

  /** 取消事件监听 */
  off(evtName, fuc) {
    if (!this[EventSymbol][evtName]) {
      return;
    }
    this[EventSymbol][evtName].forEach((item) => {
      if (item.handle === fuc) {
        this[EventSymbol][evtName].delete(item);
      }
    });
  }

  /** 抛出事件 */
  trigger(evtName, val) {
    if (!evtName) return;

    // 绑定的事件监听函数
    const actions = this[EventSymbol][evtName];
    if (!actions) return;

    actions.forEach((action) => {
      const { handle, once } = action;
      handle.call(this, val);
      if (once) {
        actions.delete(action);
      }
    });
  }
}

export default EventObj;
