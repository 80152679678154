/**
 * @author 圈圈
 * @description 创建腾讯云上传实例
 * https://cloud.tencent.com/document/product/436/11459
 */
import COS from 'cos-js-sdk-v5';
import axios from '@/plugins/axios.js';
import { isFunction } from 'lodash';

/** 支持的模式配置, 如果需要支持多个腾讯云 bucket 则扩展此配置 */
const modeConfig = {
  common: {
    getTokenUrl: '/kyle/common_storage/cos/credential/get',
    bucketCatchKey: 'houyi_common_bucket',
    regionCatchKey: 'houyi_common_region',
  },
};

async function getCredential(mode) {
  if (!Object.keys(modeConfig).includes(mode)) {
    throw new Error('getCredential error: 不支持的腾讯云配置, 请检查代码');
  }

  const url = modeConfig[mode].getTokenUrl;

  const { data } = await axios({
    url,
    method: 'get',
  });

  window.localStorage.setItem(modeConfig[mode].bucketCatchKey, data.bucket);
  window.localStorage.setItem(modeConfig[mode].regionCatchKey, data.region);

  return data || {};
}

class TencentCos {
  constructor(mode = 'common') {
    if (!Object.keys(modeConfig).includes(mode)) {
      throw new Error(`Create TencentCos error: 不支持的 mode ${mode}`);
    }

    this.mode = mode;
    this.cos = null;
    this.bucket = window.localStorage.getItem(modeConfig[mode].bucketCatchKey) || '';
    this.region = window.localStorage.getItem(modeConfig[mode].regionCatchKey) || '';
    this.taskPool = new Set();
  }

  async init() {
    if (this.cos) {
      return;
    }

    if (!this.bucket || !this.region) {
      const { bucket, region } = await getCredential(this.mode);
      this.bucket = bucket;
      this.region = region;
    }

    this.cos = new COS({
      getAuthorization: async (options, callback) => {
        const data = await getCredential(this.mode);

        callback({
          TmpSecretId: data.credentials.tmpSecretId,
          TmpSecretKey: data.credentials.tmpSecretKey,
          XCosSecurityToken: data.credentials.sessionToken,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: data.startTime, // 单位是秒
          ExpiredTime: data.expiredTime,
        });
      },
      ServiceDomain: 'https://cos.default.example.com',
      // Domain: 'https://cos.ap-beijing.myqcloud.com',
      Domain: `https://cos.${this.region}.myqcloud.com`,

      ForcePathStyle: true,
      // /** 分块上传时, 每片字节数 4M */
      // ChunkSize: 1024 * 1024 * 4,
      // /** 上传文件时校验 Content-MD5 */
      // UploadCheckContentMd5: true,
      /** 控制文件上传并发数 */
      FileParallelLimit: 3,
      /** 控制单个文件下分片上传并发数 */
      ChunkParallelLimit: 3,
      /** 控制上传的 onProgress 回调的间隔 */
      ProgressInterval: 500,
      /** 超时时间, 2min */
      Timeout: 2 * 60 * 1000,
    });
  }

  /** 分块上传, 适用于大文件 */
  async sliceUploadFile(file, key, onTaskReady, onProgress) {
    if (!this.cos) {
      throw new Error('TencentCos need init');
    }
    if (!file || !key) {
      throw new Error('TencentCos sliceUploadFile require argument "file" and "key"');
    }

    const startTime = Date.now();
    let currentTask;

    return new Promise((resolve, reject) => {
      this.cos.sliceUploadFile({
        Bucket: this.bucket,
        Region: this.region,
        Key: key,
        Body: file,
        onTaskReady: (taskId) => {
          currentTask = taskId;
          this.taskPool.add(taskId);
          if (isFunction(onTaskReady)) {
            onTaskReady(taskId);
          }
        },
        onProgress: (progressData) => {
          // progressData: {"loaded":10849834,"total":10948138,"speed":73868.35,"percent":0.99}
          if (isFunction(onProgress)) {
            const targetData = {
              startTime,
              duration: Date.now() - startTime,
              ...progressData,
            };
            onProgress(targetData);
          }
        },
      }, (err, data) => {
        this.taskPool.delete(currentTask);

        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  /** 简单上传, 适用于小文件 */
  async putObject(file, key, onTaskReady, onProgress) {
    if (!this.cos) {
      throw new Error('TencentCos need init');
    }
    if (!file || !key) {
      throw new Error('TencentCos sliceUploadFile require argument "file" and "key"');
    }
    const startTime = Date.now();
    let currentTask;

    return new Promise((resolve, reject) => {
      this.cos.putObject({
        Bucket: this.bucket,
        Region: this.region,
        Key: key,
        Body: file,
        onTaskReady: (taskId) => {
          currentTask = taskId;
          this.taskPool.add(taskId);
          if (isFunction(onTaskReady)) {
            onTaskReady(taskId);
          }
        },
        onProgress: (progressData) => {
          // progressData: {"loaded":10849834,"total":10948138,"speed":73868.35,"percent":0.99}
          if (isFunction(onProgress)) {
            const targetData = {
              startTime,
              duration: Date.now() - startTime,
              ...progressData,
            };
            onProgress(targetData);
          }
        },
      }, (err, data) => {
        this.taskPool.delete(currentTask);

        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  /** 取消上传 */
  abort(taskId) {
    if (taskId) {
      this.cos.cancelTask(taskId);
      this.taskPool.delete(taskId);
      return;
    }
    this.taskPool.forEach((item) => {
      this.cos.cancelTask(item);
      this.taskPool.delete(item);
    });
  }

  /** 暂停上传 */
  pause(taskId) {
    if (taskId) {
      this.cos.pauseTask(taskId);
      return;
    }
    this.taskPool.forEach((item) => {
      this.cos.pauseTask(item);
      this.taskPool.delete(item);
    });
  }

  /** 重启上传任务 */
  restart(taskId) {
    if (taskId) {
      this.cos.restartTask(taskId);
      return;
    }
    this.taskPool.forEach((item) => {
      this.cos.restartTask(item);
      this.taskPool.delete(item);
    });
  }
}

export default TencentCos;
