<template>
  <div class="info-card">
    <div v-if="!!title || $slots.header" class="info-card-header">
      <h1 v-if="!!title" class="info-card-title">{{title}}</h1>
      <div style="flex: 1;" />
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'KkCard',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.info-card {
  padding: 20px;
  border: 1px solid #EBEEF5;
  background-color: #FFF;
  color: #303133;
  border-radius: 4px;
  overflow: hidden;
  transition: .3s;

  .info-card-header {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .info-card-title {
      position: relative;
      padding-left: 10px;
      margin: 0;
      color: #292E3F;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;

      &::after {
        content: ' ';
        display: block;
        background-color: #4987ec;
        height: 50%;
        width: 3px;
        position: absolute;
        top: 25%;
        left: 0;
        border-radius: 2px;
      }
    }
  }
}
</style>
