export default async function getVideoFileInfo(file) {
  if (!(file instanceof File)) {
    throw new Error('getVideoFileInfo error: 需传入 File 对象');
  }

  return new Promise((resolve, reject) => {
    const videoUrl = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = videoUrl;

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve({
        name: file.name,
        type: file.type,
        duration: video.duration,
        width: video.videoWidth,
        height: video.videoHeight,
        size: file.size,
      });
    };

    video.onerror = () => {
      window.URL.revokeObjectURL(video.src);
      reject();
    };
  });
}
