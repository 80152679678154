<!-- 图片上传单项 -->
<template>
  <div class="img-upload-container"
    :class="{disabled: inputDisabled, 'is-dragover': isDragover}"
    :style="boxStyle"
    @click="openChoose"
    @drag.prevent.stop="doNothing"
    @dragstart.prevent.stop="doNothing"
    @dragend.prevent.stop="isDragover = false"
    @dragover.prevent.stop="isDragover = true"
    @dragenter.prevent.stop="isDragover = true"
    @dragleave.prevent.stop="isDragover = false"
    @drop.prevent.stop="isDragover = false, inputChange($event)">
    <!-- 空项 -->
    <div v-if="status === 'empty'">
      <i class="el-icon-upload2" style="font-size: 3em;" />
      <div style="padding: 0 1em; line-height: 1.5; color: rgba(128, 128, 128, .88)">{{ rulesTips }}</div>
    </div>
    <!-- 有内容项 -->
    <div v-else class="thumb">
      <el-image class="thumb-img" :src="displayUrl" :fit="fit" />

      <!-- 上传失败 -->
      <!-- <div class="thumb__wrapper status" :class="{ disabled: inputDisabled }" @click.stop>
        <div class="status__error" title="上传失败">
          <i class="el-icon-upload" />
        </div>
        <div>
          重试/更换
        </div>
      </div> -->

      <!-- 上传中 -->
      <div v-if="status === 'uploading'" class="thumb__wrapper uploading" @click.stop>
        <el-progress type="circle" :percentage="progress" :show-text="false" :width="progressWidth"></el-progress>
        <div style="color: white; position: absolute;">
          <div style="font-size: 16px;">{{progress}}%</div>
        </div>
      </div>

      <!-- 上传成功/初始化/等待上传 -->
      <div v-else class="thumb__wrapper control" :class="{ disabled: inputDisabled }" @click.stop>
        <template v-if="!inputDisabled">
          <i v-if="clearable" class="el-icon-close del" @click.prevent.stop="clear" title="删除" />
          <div class="preview" title="更换图片" @click.prevent.stop="openChoose">
            <el-tooltip
              class="item"
              effect="dark"
              :content="rulesTips"
              :open-delay="1000"
              placement="top">
              <i class="el-icon-upload2" />
            </el-tooltip>
          </div>
        </template>
        <div class="control-bottom" @click.stop>
          <el-popover placement="bottom" title="图像信息" width="200" trigger="hover">
            <div v-html="xss(infoHtml)" />
            <i slot="reference" class="control-bottom-btn el-icon-info" title="图片信息" style="cursor: pointer;" />
          </el-popover>
          <gallery selector=".control-bottom-btn">
            <i class="control-bottom-btn el-icon-search" :data-src="displayUrl" title="预览图片" style="cursor: pointer;" />
          </gallery>
        </div>
      </div>
    </div>
    <input ref="inputEle" type="file" :accept="accept" style="display: none;" @change="inputChange" />
  </div>
</template>

<script>
import Gallery from '@/components/BaseGallery';
import QiniuUploader from '@/models/uploader/QiniuUploader.js';
import InnerImgUploader from '@/models/uploader/InnerImgUploader.js';
import { getImageInfo } from '@/assets/js/imgUtils.js';
import { xss } from '@/assets/js/utils';
import { rulesTip, validateImg } from './utils/validate';

/**
 * 状态: 空, 待上传, 上传中, 上传成功, 上传失败
 * 上传中展示: 大文件 => 速度, 进度
 * 支持配置: 禁用
 * 支持的操作: 清空, 选择, 上传, 预览
 */

export default {
  name: 'KkImgUpload',
  components: {
    Gallery,
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      /** 远程加载状态 */
      remoteLoading: false,
      /** 上传状态 */
      uploadLoading: false,
      status: 'empty',
      boxStyle: {},
      infoHtml: '',
      progress: 0,
      displayUrl: '',
      rulesTips: '',
      isDragover: false,
    };
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    initDisplay: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    fit: {
      validator(value) {
        return ['fill', 'contain', 'cover', 'none', 'scale-down'].indexOf(value) !== -1;
      },
      default: 'scale-down',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      validator(value) {
        return value >= 100;
      },
      default: 160,
    },
    height: {
      type: Number,
      validator(value) {
        return value >= 100;
      },
      default: 120,
    },
    mode: {
      type: String,
      validator(value) {
        return ['qiniu', 'common'].indexOf(value) !== -1;
      },
      default: 'common',
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    /** 返回值类型，upload为key值(与服务端交互),source为取完整地址 */
    valueMode: {
      type: String,
      default: 'upload',
    },
  },
  computed: {
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    progressWidth() {
      let { width } = this;
      if (width > this.height) {
        width = this.height;
      }
      return width - 20;
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.clear();
        return;
      }
      this.setStatus();
      this.setDisplayUrl();
      this.setInfoHtml();
    },
    rules: {
      deep: true,
      handler() {
        this.setRuleTip();
      },
    },
  },
  created() {
    this.setBoxStyle();
    this.setStatus();
    this.setInfoHtml();
    this.setDisplayUrl();
    this.setRuleTip();
  },
  methods: {
    xss(content) {
      return xss(content);
    },
    setRuleTip() {
      this.rulesTips = rulesTip(this.rules).join(', ');
    },
    setDisplayUrl() {
      this.displayUrl = ((this.uploader || {}).info || {}).url || this.initDisplay;
    },
    async setInfoHtml() {
      if (!this.value) {
        this.infoHtml = '';
        return;
      }
      if ((!this.uploader && !this.initDisplay) || (this.uploader && !this.uploader.status)) {
        this.infoHtml = '';
        return;
      }

      try {
        const info = (this.uploader || {}).info || await getImageInfo(this.initDisplay);
        let infoHtml = '';
        if (!this.uploader) {
          infoHtml += '(远程图片信息, 仅供参考)</br>';
        }
        infoHtml += `文件格式: ${info.type}</br>`;
        infoHtml += `文件大小: ${(info.size / 1024).toFixed(2)}KB</br>`;
        infoHtml += `宽: ${info.width}px</br>`;
        infoHtml += `高: ${info.height}px</br>`;
        infoHtml += `是否动图: ${info.isAnimation ? '是' : '否'}`;
        this.infoHtml = infoHtml;
      } catch (err) {
        this.infoHtml = '获取图像信息失败';
      }
    },
    setStatus() {
      if (!this.uploader && !this.value) {
        this.status = 'empty';
        return;
      }
      if (!this.uploader && this.value) {
        this.status = 'uploadSuccess';
        return;
      }
      this.status = this.uploader.status;
    },
    openChoose() {
      if (this.inputDisabled) {
        return;
      }
      this.$refs.inputEle.value = '';
      this.$refs.inputEle.click();
    },
    inputChange(evt) {
      // if (!evt.target.files[0]) {
      //   return;
      // }
      // this.choose(evt.target.files[0]);
      // 禁用状态下不允许拖拽上传
      if (this.inputDisabled) {
        return;
      }
      let files;
      if (evt instanceof DragEvent) {
        files = evt.dataTransfer.files;
      } else {
        files = evt.target.files;
      }
      if (!files[0]) {
        return;
      }
      if (!this.$utils.validateAcceptAttr(this.accept, files[0])) {
        this.$message.error('请上传符合格式要求的文件');
        return;
      }
      this.choose(files[0]);
    },
    clear() {
      this.uploader = null;
      this.displayUrl = '';
      this.setStatus();
      this.$emit('change', '');
      this.$emit('changeVal', {});
    },
    async choose(file) {
      if (this.inputDisabled) {
        return;
      }
      let uploader;
      if (this.mode === 'qiniu') {
        uploader = new QiniuUploader(file);
      } else {
        uploader = new InnerImgUploader(file);
      }

      await uploader.init();
      if (!this.validate(uploader)) {
        return;
      }

      this.uploader = uploader;
      this.setInfoHtml();
      this.setDisplayUrl();

      uploader.on('upload:progress', (info) => {
        console.log(info);
        this.progress = Math.round(info.total.percent);
      });
      uploader.on('change:status', (val) => {
        this.status = val;
      });

      if (!this.autoUpload) {
        return;
      }
      this.upload();
    },
    validate(uploader) {
      if (!uploader.status) {
        return false;
      }
      try {
        validateImg(uploader.info, this.rules);
        return true;
      } catch (err) {
        this.$message.error(err.message || '图片校验不通过');
        return false;
      }
    },
    async upload() {
      if (this.inputDisabled) {
        return;
      }
      await this.uploader.upload();
      if (this.valueMode === 'source') {
        this.$emit('change', this.uploader.sourceUrl);
      } else {
        this.$emit('change', this.uploader.uploadUrl);
      }
      this.$emit('changeUploader', this.uploader);
    },

    /** 设置容器样式 */
    setBoxStyle() {
      const { width, height } = this;
      const style = {};
      if (typeof width === 'number') {
        style.width = `${width}px`;
      } else if (typeof width === 'string') {
        style.width = width;
      }
      if (typeof height === 'number') {
        style.height = `${height}px`;
      } else if (typeof height === 'string') {
        style.height = height;
      }

      /** 提示字体最大尺寸 */
      let fontSize = 12;
      /** 每行提示预设 */
      const maxText = 8;
      if (typeof width === 'number' && (width / maxText) < fontSize) {
        fontSize = (width / maxText).toFixed(2);
      }
      style.fontSize = `${fontSize}px`;

      this.boxStyle = style;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-upload-container {
  position: relative;
  overflow: hidden;
  border: 1.5px dashed #d9d9d9;
  border-radius: 4px;
  color: #666666;
  text-align: center;
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, .5);
  transition: all .2s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: #3963bc;
    color: #3963bc;
  }

  &.disabled {
    cursor: not-allowed;
    border-color: #ababab;
    color: #ababab;
  }
}

.thumb {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;

  .thumb__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all .3s ease;
    transition-delay: .1s;
  }

  .thumb-img {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .status {
    .wait-upload {
      background: #ffcb71;
      color: white;
      position: absolute;
      display: inline-block;
      width: 1.7em;
      height: 1.5em;
      top: 0;
      right: 0;
      border-radius: 0 0 0 1.4em;
      transition: all .1s ease;

      &::before {
        font-size: 1.4em;
        position: absolute;
        right: -1px;
        transform: scale(0.7);
      }
    }

  }

  .uploading {
    background-color: rgba(0, 0, 0, .3);
    transition: all .3s ease;
  }

  .control {
    opacity: 0;
    background-color: rgba(0, 0, 0, .3);
    transition: all .3s ease;
    transition-delay: .1s;

    .del {
      background: #f4516c;
      color: white;
      position: absolute;
      display: inline-block;
      width: 1.7em;
      height: 1.5em;
      top: 0;
      right: 0;
      opacity: 0;
      border-radius: 0 0 0 1.4em;
      transition: all .1s ease;

      &::before {
        font-size: 1.4em;
        position: absolute;
        right: -1px;
        transform: scale(0.7);
      }

      &:hover {
        transform: translate(-0.5em, .4em) scale(1.5);
      }

    }

    .preview {
      color: white;
      font-size: 2em;
      transition: all .2s ease;

      &:hover {
        transform: scale(1.2);
      }
    }

    .control-bottom {
      line-height: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: white;
      background-color: rgba(73, 87, 139, .64);
      font-size: 1.2em;
      display: flex;
      justify-content: space-around;
      transform: translate(0, 100%);
      transition: all .2s ease;
      transition-delay: .1s;
      padding: 5px 0;

      .control-bottom-btn {
        transform: all .2s;

        &.disabled {
          color: #ababab;
          cursor: not-allowed;
        }

        &:not(.disabled):hover {
          transform: scale(1.2);
        }
      }
    }
  }

  &:hover {
    .control {
      opacity: 1;
    }

    .del {
      opacity: 1;
    }

    .control-bottom {
      transform: translate(0, 0);
    }
  }
}
</style>
