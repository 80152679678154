<template>
  <div>
    <div>
      <el-button
        type="primary"
        size="small"
        :disabled="inputDisabled || (limit && files.length >= limit)"
        :loading="loading"
        @click="chooseFile">{{ tipText }}</el-button>
      <el-button
        v-if="multiple && clearable"
        type="warning"
        size="small"
        :disabled="inputDisabled || files.length === 0"
        :loading="loading"
        @click="clearAllFiles">清空全部</el-button>
    </div>

    <div v-if="tip" class="upload-tip">{{tip}}</div>

    <div class="upload-content">
      <div v-for="(file, index) in files" :key="file.key || index" class="upload-item" :class="file.status">
        <div class="details">
          <i v-if="clearable || file.status === 'error'" class="delete el-icon-delete" @click="delFile(index)" />
          <i class="el-icon-document" />
          {{ file.name || file.key }}
        </div>
        <el-progress class="progress" :percentage="file.progress" />
      </div>
    </div>
    <input ref="inputEle" type="file" :multiple="multiple" :accept="accept" style="display: none;" @change="inputChange" />
  </div>
</template>

<script>
import Uploader from '@/models/uploader/Uploader.js';
import QiniuUploader from '@/models/uploader/QiniuUploader.js';
import FileUploader from '@/models/uploader/FileUploader.js';
import TencentUploader from '@/models/uploader/TencentUploader.js';

function validateData(info, rules) {
  if (rules.maxSize && info.size > rules.maxSize) {
    throw new Error(`文件大小 ${(info.size / 1024 / 1024).toFixed(2)}M 超过限制 ${(rules.maxSize / 1024 / 1024).toFixed(2)}M`);
  }
  return true;
}

export default {
  name: 'KkFileUpload',
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  model: {
    prop: 'value',
    event: 'changeVal',
  },
  data() {
    return {
      loading: false,
      files: [],
    };
  },
  props: {
    accept: {
      type: String,
      default: '',
    },
    /** 使用哪类上传模型 默认走文件上传(广告接口), 配置 qiniu 可走 7 牛上传, tencent 腾讯cos上传, 支持传入自定义 loader */
    loaderMode: {
      type: [String, Function],
      default: 'common', // qiniu, common, tencent
    },
    rules: {
      type: Object,
      default: () => ({ size: 1024 * 1024 * 1024 }),
    },
    value: {
      type: [String, Array, Number],
      default: '',
    },
    display: {
      type: [String, Array],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    size: String,
    // autoUpload: {
    //   type: Boolean,
    //   default: true,
    // },
    /** 提示文案 */
    tip: {
      type: String,
      default: '',
    },
    limit: Number,
    multiple: Boolean,
    beforeUpload: Function,
  },
  computed: {
    elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize() {
      return this.size || this.elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    tipText() {
      if (!this.multiple && this.files.length > 0) {
        return '更换文件';
      }
      return '选择文件';
    },
    displayMap() {
      if (!Array.isArray(this.display)) {
        return {};
      }
      const display = {};
      this.display.forEach((item) => {
        display[item.value] = item;
      });
      return display;
    },
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    getDisplayName(key) {
      const target = this.displayMap[key];

      if (!target) {
        return key;
      }

      return target.label || key;
    },
    getDownloadUrl(key) {
      const target = this.files.find(item => item.key === key);
      if (!target) {
        return '';
      }
      return target.downloadUrl || '';
    },
    /** 往外部同步值 */
    emitValueChange() {
      if (!this.multiple) { // 单文件上传
        this.$emit('changeVal', (this.files[0] || {}).key || '');
        this.$emit('change', (this.files[0] || null));
      } else {
        const valList = this.files.filter(item => item.status === 'complete');
        this.$emit('changeVal', valList.map(item => item.key));
        this.$emit('change', this.$utils.deepClone(valList));
      }
    },
    /** 清空所有上传 */
    clearAllFiles() {
      this.files = [];
      this.emitValueChange();
    },
    /** 删除特定文件 */
    delFile(index) {
      this.files.splice(index, 1);
      this.emitValueChange();
    },
    setValue() {
      // 多文件上传
      if (this.multiple === true) {
        if (!this.value || !Array.isArray(this.value)) {
          this.files = [];
          return;
        }

        const hasFile = {};
        this.files.forEach((item) => {
          if (this.value.includes(item.key)) {
            hasFile[item.key] = true;
            return;
          }
          if (item.status === 'complete') {
            // eslint-disable-next-line no-param-reassign
            item.needDel = true;
          }
        });

        this.files = this.files.filter(item => !item.needDel);
        this.value.forEach((key) => {
          if (hasFile[key]) {
            return;
          }
          this.files.push({
            key,
            name: this.getDisplayName(key),
            status: 'complete',
            progress: 0,
            downloadUrl: this.getDownloadUrl(key),
          });
        });

        return;
      }

      // 单文件模式
      let key = '';
      if (this.value) {
        key = this.value;
      }

      if (!key) {
        this.files = [];
        return;
      }
      let hasFile = false;
      this.files.forEach((item) => {
        if (item.key === key) {
          hasFile = true;
          return;
        }
        // eslint-disable-next-line no-param-reassign
        item.needDel = true;
      });

      this.files = this.files.filter(item => !item.needDel);
      if (!hasFile) {
        const display = {};
        if (typeof this.display === 'string') {
          display[key] = {
            value: key,
            label: this.display,
            downloadUrl: this.loaderMode === 'common' ? `/kyle/common_storage/file/download?file_name=${key}` : '',
          };
        } else if (Array.isArray(this.display)) {
          this.display.forEach((item) => {
            display[item.value] = item;
          });
        }
        this.files.push({
          key,
          name: display[key].label || key,
          progress: 0,
          status: 'complete',
          downloadUrl: display[key].downloadUrl || '',
        });
      }
    },
    chooseFile() {
      this.$refs.inputEle.value = '';
      this.$refs.inputEle.click();
    },
    async inputChange(evt) {
      if (!evt.target.files[0]) {
        return;
      }

      let uploaderList = Array.from(evt.target.files).map(file => this.createUploaderItem(file));

      uploaderList = await Promise.all(uploaderList);
      uploaderList = uploaderList.filter(item => item instanceof Uploader);

      if (uploaderList.length === 0) {
        return;
      }

      if (!this.multiple) {
        this.files = [];
      } else if (this.limit) { // 文件数量限制
        const currentNum = this.files.reduce((acc, file) => {
          if (file.key && file.status === 'complete') {
            // eslint-disable-next-line no-param-reassign
            acc += 1;
          }
          return acc;
        }, 0);
        const restNum = this.limit - currentNum;
        if (restNum > 0) {
          uploaderList = uploaderList.slice(0, restNum);
        } else {
          uploaderList = [];
        }
      }

      // 最终需要上传的文件
      uploaderList.map(uploader => this.createFileItem(uploader));
    },
    async createUploaderItem(file) {
      let uploader;
      if (this.loaderMode === 'qiniu') {
        uploader = new QiniuUploader(file);
      } else if (this.loaderMode === 'common') {
        uploader = new FileUploader(file);
      } else if (this.loaderMode === 'tencent') {
        uploader = new TencentUploader(file);
      } else if (this.$utils.isFunction(this.loaderMode)) {
        uploader = this.loaderMode(file);
        if (!(uploader instanceof Uploader)) {
          throw new Error('createUploaderItem error, check your loaderMode');
        }
      } else {
        throw new Error('createUploaderItem error, check your loaderMode');
      }

      await uploader.init();

      try {
        validateData(uploader.info, this.rules);
        if (this.beforeUpload) {
          this.beforeUpload(uploader);
        }
        return uploader;
      } catch (err) {
        this.$message.error(err.message || '文件不符合要求');
        return false;
      }
    },
    async createFileItem(uploader) {
      if (this.inputDisabled) {
        return;
      }

      const newItem = {
        key: '',
        name: uploader.info.name,
        hash: uploader.hash,
        status: uploader.status,
        progress: 0,
        downloadUrl: '',
        info: uploader.info,
      };
      this.files.push(newItem);

      uploader.on('upload:progress', (info) => {
        newItem.progress = Math.round(info.total.percent);
      });
      uploader.on('change:status', (val) => {
        newItem.status = val;
      });
      uploader.on('error', (info) => {
        this.$message.error(info.message || '上传出错');
      });

      await uploader.upload();

      newItem.key = uploader.uploadUrl;
      newItem.downloadUrl = uploader.downloadUrl;
      this.emitValueChange();
    },
    downloadFile(downloadUrl) {
      if (!downloadUrl) {
        return;
      }
      this.$axios({
        mehtod: 'get',
        url: downloadUrl,
      });
      // this.$utils.download(this.downloadUrl);
    },
    getValue() {
      const valueList = this.files.filter(item => (item.status === 'complete'));
      return this.$utils.deepClone(valueList);
    },
  },
};
</script>

<style lang="scss" scoped>
// $--color-primary:#5284D5;
// $--color-success:#53BA55;
// $--color-warning:#DFB237;
// $--color-danger:#E04545;

.upload-tip {
  font-size: 12px;
  color: #606266;
  line-height: 1.6;
  margin: 4px 0;
}

.upload-content {
  color: #606266;
  font-size: 14px;
  line-height: 1.4;
  max-width: 400px;
  margin-top: 8px;

  .upload-item {
    cursor: pointer;
    transition: all .3s ease;
    line-height: 2;
    padding-left: 7px;

    .progress {
      visibility: hidden;
      height: 0;
      overflow: hidden;
      transition: all .3s ease;
    }

    .delete {
      cursor: pointer;
      color: #E04545;
      float: right;
      padding: 7px;
      display: none;
    }

    &:hover {
      background-color: #F5F7FA;
      color: $--color-primary;

      .delete {
        display: block;
      }
    }

    &.uploading {
      .progress {
        visibility: visible;
        height: 18px;
      }

      .delete {
        display: none;
      }
    }

    &.error {
      color: $--color-danger;

      .delete {
        display: block;
      }
    }
  }
}

.details {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
