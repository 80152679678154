<template>
  <transition name="dialog-fade" @after-enter="afterEnter" @after-leave="afterLeave">
    <div
      v-if="visible"
      class="lg-outer lg-start-zoom lg-use-css3 lg-css3  lg-visible "
      :class="{
        'lg-slide': (igList.length > 2),
        'lg-fade': (igList.length <= 2),
        'lg-dragging': isDraging,
        'lg-grabbing': isDraging,
        'lg-grab': !isDraging,
        'lg-hide-items': !isActing,
        'lg-empty-html': true,
        'lg-thumb-open': thumbleOpen,
      }"
      @contextmenu.capture="preventDefaultFuc"
      @mousemove="handleUserAction"
      @click="handleUserAction"
      @touchstart="handleUserAction">
      <div class="lg">
        <div class="lg-inner" :style="{
          'transition-timing-function': 'cubic-bezier(0.25, 0, 0.25, 1)',
          'transition-duration': (speed + 'ms'),
        }">
          <div
            v-for="(item, index) in igList"
            :key="'ig-item-' + index"
            class="lg-item "
            :class="{
              'lg-current': (index === current),
              'lg-next-slide': (index === nextIndex),
              'lg-prev-slide': (index === prevIndex),
              'lg-complete': (item.status > 1),
              'lg-loaded': (item.status > 0),
            }"
            v-loading="item.status === 1"
            :style="item.style">
            <div v-if="item.status > 0" class="lg-img-wrap" @click.self="closeModal">
              <img
                v-if="item.type === 'img'"
                class="lg-object lg-image"
                :src="item.src"
                :draggable="isMultiple"
                @load="imgLoadSuccess(index)"
                @error="imgLoadError(index)"
                @touchstart="handleMoveStart($event, 'touch', index)"
                @touchmove="handleMove($event, 'touch', index)"
                @touchend="handleMoveEnd($event, 'touch', index)"
                @mousedown="handleMoveStart($event, 'mouse', index)" />
              <video
                v-if="item.type === 'video'"
                controls
                class="lg-object lg-image"
                preload="metadata"
                :draggable="isMultiple"
                @loadedmetadata="imgLoadSuccess(index)"
                @error="imgLoadError(index)"
                @touchstart="handleMoveStart($event, 'touch', index)"
                @touchmove="handleMove($event, 'touch', index)"
                @touchend="handleMoveEnd($event, 'touch', index)"
                @mousedown="handleMoveStart($event, 'mouse', index)">
                <source :src="item.src" type="video/mp4">
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          </div>
        </div>
        <div class="lg-toolbar group">
          <span class="lg-close lg-icon" @click="closeModal">
            <i class="el-icon-close"></i>
          </span>
          <a v-if="enableDownload && igList[current] && igList[current].status > 0" id="lg-download" target="_blank" download class="lg-download lg-icon" :href="igList[current].src">
            <i class="el-icon-download"></i>
          </a>
          <div id="lg-counter">
            <span id="lg-counter-current">{{current | currentPage}}</span> / <span id="lg-counter-all">{{igList.length}}</span>
          </div>
        </div>
        <div class="lg-actions">
          <div v-if="igList.length > 1" class="lg-prev lg-icon" @click="goToPrevSlide('Button')" :disabled="!loop && current > 0">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div v-if="igList.length > 1" class="lg-next lg-icon" @click="goToNextSlide('Button')" :disabled="!loop && current < (igList.length - 1)">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <!-- <div class="lg-sub-html"></div> -->
      </div>
    </div>
  </transition>
</template>

<script>
import utils from './utils';

export default {
  name: 'ImgGallery',
  data() {
    return {
      igList: [],
      isBusy: false,
      hasZoom: false,
      isActing: false,
      current: 0,
      thumbleOpen: false,
      startCoords: null,
      endCoords: null,
      isMoved: false,
      readyMove: false,
      isTouch: ('ontouchstart' in document.documentElement),
      clientWidth: document.documentElement.clientWidth,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    speed: {
      type: Number,
      default: 300,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    keypress: {
      type: Boolean,
      default: true,
    },
    preload: {
      type: Number,
      default: 0,
    },
    thumbail: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    dynamic: {
      type: Boolean,
      default: true,
    },
    selector: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    /** 是否允许下载 */
    enableDownload: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  filters: {
    currentPage(val) {
      return (parseInt(val, 10) + 1);
    },
  },
  computed: {
    isMultiple() {
      return this.igList.length > 1;
    },
    prevIndex() {
      let prev = this.current - 1;
      if (prev < 0) {
        prev += this.igList.length;
      }
      return prev;
    },
    nextIndex() {
      let next = this.current + 1;
      if (next >= this.igList.length) {
        next -= this.igList.length;
      }
      return next;
    },
    isDraging() {
      if (!this.isMoved) {
        return false;
      }
      if (Math.abs(this.endCoords - this.startCoords) < 15) {
        return false;
      }
      return true;
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.igList = this.content.map((item) => {
          const data = { ...item };
          data.style = false;
          data.src = '';
          data.type = 'img';
          return data;
        });
        this.current = this.index;

        this.openModal();

        this.loadContent(this.current);

        this.keyPress();
        this.$emit('open');

        utils.on(window, 'mousemove.ig', (e) => {
          this.handleMove(e, 'mouse');
        });

        utils.on(window, 'mouseup.ig', (e) => {
          this.handleMoveEnd(e, 'mouse');
        });

        utils.on(window, 'resize.ig', () => {
          this.clientWidth = document.documentElement.clientWidth;
        });
      } else {
        this.$emit('close');
        utils.off(window, '.ig');
      }
    },
    current(val) {
      this.loadContent(val);
    },
    isMoved(val) {
      if (val) {
        return;
      }
      this.$nextTick(() => {
        this.igList.forEach((item) => {
          if (item.style) {
            // eslint-disable-next-line
            item.style.Transform = '';
          }
        });
      });
    },
    // endCoords() {
    //   if (!this.isMoved) {
    //     return;
    //   }
    // },
  },

  created() {},

  methods: {
    preventDefaultFuc(e) {
      if (!this.enableDownload) {
        e.preventDefault();
      }
    },
    handleUserAction() {
      this.isActing = true;
      clearTimeout(this.hideBartimeout);
      // Timeout will be cleared on each slide movement also
      this.hideBartimeout = setTimeout(() => {
        this.isActing = false;
      }, 6000);
    },
    imgLoadSuccess(index) {
      this.igList[index].status = 2;
    },
    imgLoadError(index) {
      this.igList[index].status = 3;
    },
    loadContent(index) {
      const item = this.igList[index];
      // 0: 未初始化
      // 1: 初始化了, 未加载
      // 2: 已加载
      // 3: 出错
      if (item.status === 2) {
        return;
      }

      item.status = 1;
      item.src = item.$el.getAttribute('data-src') || item.$el.getAttribute('href');
      item.type = item.$el.getAttribute('data-type') || 'img';
    },
    goToPrevSlide(from) {
      const pre = this.current;
      const to = this.prevIndex;

      this.slide(pre, to, from);
    },
    goToNextSlide(from) {
      const pre = this.current;
      const to = this.nextIndex;

      this.slide(pre, to, from);
    },
    slide(pre, to, from) {
      this.current = to;
      this.isBusy = true;
      this.$emit('onSlide', {
        prevIndex: pre,
        index: to,
        from, // Button, Thumb, Touch
      });
      setTimeout(() => {
        this.isBusy = false;
        this.$emit('onAfterSlide', {
          prevIndex: pre,
          index: to,
          from, // Button, Thumb, Touch
        });
      }, this.speed);
    },
    afterEnter() {
      this.$emit('opened');
    },
    afterLeave() {
      this.$emit('closed');
      this.closeModal();
    },
    openModal() {
      if (!utils.hasClass(document.body, 'lg-on')) {
        utils.addClass(document.body, 'lg-on');
      }
      if (document.querySelector('ig-mask')) {
        this.maskEle = document.querySelector('ig-mask');
        return;
      }
      const modalDom = document.createElement('div');
      this.maskEle = modalDom;
      utils.on(modalDom, 'touchmove.ig', (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
      // modalDom.addEventListener('touchmove.ig', (event) => {
      //   event.preventDefault();
      //   event.stopPropagation();
      // });

      utils.on(modalDom, 'click.ig', () => {
        // 关闭弹出内容
        this.closeModal();
      });
      // modalDom.addEventListener('click.ig', () => {
      //   // 关闭弹出内容
      //   this.closeModal();
      // });

      utils.addClass(modalDom, 'ig-mask');
      utils.addClass(modalDom, 'ig-mask-enter');

      setTimeout(() => {
        utils.removeClass(modalDom, 'ig-mask-enter');
      }, 200);

      document.body.appendChild(modalDom);

      modalDom.style.zIndex = 10000;
      modalDom.style.display = '';

      this.$emit('update:visible', true);
    },
    closeModal() {
      utils.removeClass(document.body, 'lg-on');
      const modalDom = this.maskEle;
      if (!modalDom) return;

      utils.off(modalDom, '.ig');
      utils.addClass(modalDom, 'ig-mask-leave');
      this.$emit('update:visible', false);
      setTimeout(() => {
        if (modalDom.parentNode) modalDom.parentNode.removeChild(modalDom);
        modalDom.style.display = 'none';

        utils.removeClass(modalDom, 'ig-mask-leave');
        this.maskEle = null;
      }, 200);
    },
    keyPress() {
      if (this.igList.length > 1) {
        utils.on(window, 'keyup.ig', (e) => {
          if (this.igList.length > 1) {
            if (e.keyCode === 37) {
              e.preventDefault();
              this.goToPrevSlide();
            }

            if (e.keyCode === 39) {
              e.preventDefault();
              this.goToNextSlide();
            }
          }
        });
      }

      utils.on(window, 'keydown.ig', (e) => {
        if (e.keyCode === 27) {
          e.preventDefault();
          if (!this.thumbleOpen) {
            this.$emit('update:visible', false);
          } else {
            this.thumbleOpen = false;
          }
        }
      });
    },
    handleMoveStart(e, source, index) {
      if (!this.isMultiple) {
        return;
      }
      if (this.isBusy || this.hasZoom) {
        return;
      }
      if (this.current !== index) {
        return;
      }
      e.preventDefault();

      if (source === 'mouse') {
        this.startCoords = e.pageX;
      } else {
        this.startCoords = e.targetTouches[0].pageX;
      }
      this.endCoords = this.startCoords;
      this.isMoved = false;
      this.readyMove = true;
    },
    handleMove(e, source) {
      if (this.isBusy || this.hasZoom || !this.readyMove) {
        return;
      }

      e.preventDefault();

      if (source === 'mouse') {
        this.endCoords = e.pageX;
      } else {
        this.endCoords = e.targetTouches[0].pageX;
      }

      const distance = this.endCoords - this.startCoords;

      if (Math.abs(distance) > 15) {
        this.igList[this.current].style = {
          Transform: `translate3d(${distance}px, 0px, 0px)`,
        };

        this.igList[this.prevIndex].style = {
          Transform: `translate3d(${distance - this.clientWidth}px, 0px, 0px)`,
        };
        this.igList[this.nextIndex].style = {
          Transform: `translate3d(${distance + this.clientWidth}px, 0px, 0px)`,
        };
        this.isMoved = true;
      }
    },
    handleMoveEnd(e) {
      if (this.isBusy || this.hasZoom || !this.readyMove) {
        return;
      }
      this.readyMove = false;
      this.isMoved = false;
      e.preventDefault();
      const distance = this.endCoords - this.startCoords;

      if (Math.abs(distance) < 50) {
        return;
      }

      if (distance > 0) {
        this.goToPrevSlide('Swipe');
      } else {
        this.goToNextSlide('Swipe');
      }
    },
  },
};
</script>

<style lang="scss">
@import "./sass/lightgallery.scss";

.ig-mask-enter {
  animation: ig-mask-in .2s ease;
}

.ig-mask-leave {
  animation: ig-mask-out .2s ease forwards;
}

@keyframes ig-mask-in {
  0% {
    opacity: 0;
  }

}

@keyframes ig-mask-out {

  100% {
    opacity: 0;
  }
}

.ig-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: #000;
}

.dialog-fade-enter-active {
  animation: dialog-fade-in .3s;
}

.dialog-fade-leave-active {
  animation: dialog-fade-out .3s;
}

@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style>
