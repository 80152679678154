<template>
  <el-radio-group class="card-radio" :class="{'card-disabled': inputDisabled}" v-model="innerVal" @change="changeVal">
    <template v-for="item in options">
      <el-radio v-if="!inputDisabled || innerVal === item.value" :key="item.value" class="card-radio-item" :label="item.value">
        <div class="card-option" :class="{active: innerVal === item.value}">
          <div class="icon-holder">
            <i :class="item.icon" />
          </div>
          <div class="heading">{{item.title}}</div>
          <div class="description">{{item.desc}}</div>
        </div>
      </el-radio>
    </template>
  </el-radio-group>
</template>

<script>
export default {
  name: 'kkCardRadio',
  model: {
    prop: 'value',
    event: 'change',
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  data() {
    return {
      innerVal: '',
    };
  },
  props: {
    value: {},
    options: {
      type: Array,
      default: () => ([]),
    },
    size: String,
    disabled: Boolean,
  },
  computed: {
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
  watch: {
    value(val) {
      if (this.innerVal !== val) {
        this.innerVal = val;
      }
    },
  },
  created() {
    this.innerVal = this.value;
  },
  methods: {
    changeVal(evt) {
      this.$emit('change', evt);
    },
  },
};
</script>

<style lang="scss" scoped>
// $--color-primary:#5284D5;
// $--color-success:#53BA55;
// $--color-warning:#DFB237;
// $--color-danger:#E04545;

$-card-height: 160px;
$-card-width: 240px;
$-title-font-size: 18px;
$-desc-font-size: 14px;
$-icon-font-size: 30px;

.card-radio {
  display: flex;
}

.card-radio-item {
  vertical-align: bottom;
  width: $-card-width;
}

.card-option {
  font-weight: normal;
  box-sizing: border-box;
  width: 100%;
  height: $-card-height;
  max-width: $-card-width;
  margin: 20px 0;
  text-align: center;
  border: 1px solid #ddd;
  transition: all 0.3s ease;

  .icon-holder {
    font-size: $-icon-font-size;
    position: relative;
    top: math.div($-card-height, 2) - $-icon-font-size - 20px;
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px;
    background: white;
    transition: all 0.3s ease;
  }

  .heading {
    position: relative;
    top: $-title-font-size;
    font-size: $-title-font-size;
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #333333;
  }

  .description {
    font-size: $-desc-font-size;
    line-height: 1.4;
    color: #8a8e91;
    white-space: normal;
    width: 85%;
    margin: 0 auto;
    opacity: 0;
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: scale(0);
  }

  &:hover,
  &.active {
    border-color: $--color-primary;

    .icon-holder {
      top: (math.div($-icon-font-size, 2) + 10) * -1;
    }

    .heading {
      top: (math.div($-card-height, 2) - math.div($-icon-font-size, 2) - ($-title-font-size) - 30px) * (-1);
    }

    .description {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.active {
    .heading {
      color: $--color-primary;
    }
  }
}

.card-disabled .card-radio-item {
  cursor: default;
}
</style>

<style lang="scss">
.card-radio {
  .el-radio__input {
    display: none;
  }
}
</style>
