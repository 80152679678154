import numeral from 'numeral';

// 设置默认语言
numeral.register('locale', 'chs', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: '千',
    million: '百万',
    billion: '十亿',
    trillion: '兆',
  },
  ordinal() {
    return '.';
  },
  currency: {
    symbol: '¥',
  },
});
numeral.locale('chs');

/**
 * 格式百分比
 * @param {number} num
 * @returns {string}
 * @example
 * formatPercent(1) // '100.00%'
 * formatPercent(1.9999) // '200.00%'
 * formatPercent(0.567) // '56.70%'
 */
export function formatPercent(num) {
  return numeral(num).format('0.00%');
}

/**
 * 格式化整数
 * @param {number} num
 * @returns {string}
 * @example
 * formatInt(14) // '14'
 * formatInt(19999.1) // '19,999'
 */
export function formatInt(num) {
  return numeral(num).format('0,0');
}

/**
 * 格式化金额, 四舍五入
 * @param {number} num
 * @returns {string}
 * @example
 * formatMoney(1.9999) // '¥2.00'
 * formatMoney(16) // '¥16.00'
 * formatMoney(1.1) // '¥1.10'
 * formatMoney(19999.1) // '¥19,999.10'
 */
export function formatMoney(num) {
  return numeral(num).format('$0,0.00');
  // return numeral((Math.floor(num * 100)) / 100).format('$0,0.00');
}
