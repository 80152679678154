// import { template } from 'lodash';
import axios from '@/plugins/axios';
import { deepClone } from '../assets/js/utils';
// import { deepClone, getRunEnvironment } from '../assets/js/utils';
// import viewsData from './viewsData.js';

/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/**
 * 视图控制器
 */

// const evt = getRunEnvironment();

function shimPageNode(data) {
  const detail = JSON.parse(data.detail);
  Object.assign(data, detail);
  delete data.detail;
}

function shimFolderNode(data) {
  const detail = JSON.parse(data.detail);
  Object.assign(data, detail);
  delete data.detail;
  data.icon = data.icon || 'el-icon-menu';
}

function shimLinkNode(data) {
  const detail = JSON.parse(data.detail);
  Object.assign(data, detail);
  delete data.detail;
}

/**
 * 遍历树
 * @param {tree} tree 需要遍历的树
 * @param {function} travelFuc 处理函数
 */
function treeTravel(tree, travelFuc, parent = '') {
  if (typeof tree !== 'object') return;

  if (Array.isArray(tree)) {
    tree.forEach((subConfig) => {
      treeTravel(subConfig, travelFuc, parent || 'root');
    });
    return;
  }

  travelFuc(tree, parent);

  if (tree.children) {
    tree.children.forEach((subConfig) => {
      treeTravel(subConfig, travelFuc, tree);
    });
  }
}

/**
 * 剪枝函数
 * @param {tree} tree 需要处理的树
 * @param {function} shakeFuc 处理函数, 返回 false 则进行剪枝
 * @returns {tree} 返回剪枝后的树
 */
function treeShaking(tree, shakeFuc) {
  if (Array.isArray(tree)) {
    const target = tree.map(item => treeShaking(item, shakeFuc));
    return target.filter(item => !!item);
  }

  if (typeof tree !== 'object') {
    return tree;
  }

  // 检查函数
  if (!shakeFuc(tree)) {
    return false;
  }

  if (tree.children) {
    tree.children = treeShaking(tree.children, shakeFuc);
  }
  return tree;
}

/**
 * 根据原始树构建新树
 * @param {tree} tree 原树
 * @param {function} buildFuc 构建函数
 * @returns {tree} 返回新树
 */
function treeBuild(tree, buildFuc) {
  if (typeof tree !== 'object') return tree;

  if (Array.isArray(tree)) {
    return tree.map(subConfig => treeBuild(subConfig, buildFuc));
  }

  if (tree.children) {
    tree.children = tree.children.map(subConfig => treeBuild(subConfig, buildFuc));
  }

  return buildFuc(tree);
}

class ViewsController {
  constructor(biz = 1) {
    this.biz = biz; // 1 代理商, 2 广告主, 3 kker, 4 dmp
    this.auth = new Map();
    this._viewsConfig = [];
    this._routeConfig = [];
    this._sideNavConfig = [];
    this._hashMap = new Map();
    console.log(this);
  }

  async init() {
    const res = await axios({
      url: '/kyle/menu/list',
      method: 'get',
      params: {
        biz_type: this.biz,
      },
    });

    this._hashMap = new Map();
    if (!res.data || res.data.length === 0) {
      // eslint-disable-next-line no-throw-literal
      throw { code: 403, message: '缺少权限' };
    }

    const authSet = new Set();

    // 处理原始数据
    try {
      treeTravel(res.data[0].children, (node, parent) => {
        if (parent === 'root') {
          node.parent = null;
        } else {
          node.parent = parent;
        }

        if ((node || {}).type === 'page') {
          shimPageNode(node);
        } else if ((node || {}).type === 'folder') {
          shimFolderNode(node);
        } else if ((node || {}).type === 'link') {
          shimLinkNode(node);
        } else {
          shimFolderNode(node);
        }
        this._hashMap.set(node.id, node);
        (node.auth || []).forEach(item => authSet.add(item));
      });
    } catch (err) {
      console.error(err);
    }

    this._viewsConfig = res.data[0].children;
    this._routeConfig = this._getRouteConfig();
    this._sideNavConfig = this._getSideNavConfig();

    if (authSet.size) {
      const authCheckRes = await axios({
        url: '/kyle/common/permissions/check',
        method: 'get',
        params: {
          permissions: [...authSet].join(','),
        },
      });

      (authCheckRes.data || []).forEach((item) => {
        this.auth.set(item.permission, item.check_result);
      });
    }
  }

  _getRouteConfig() {
    const { viewsConfig } = this;
    const target = [];
    const viewsMap = new Map();
    // 处理原始数据
    try {
      treeTravel(viewsConfig, (node, parentNode) => {
        if (node.type !== 'page') {
          return;
        }
        if (!node.route) {
          console.error('节点未找到路由配置, 无法生成路由', node);
          return;
        }
        // 构造舞台view路由
        const viewRouter = {};
        viewRouter.path = node.route;
        viewRouter.name = node.name;
        viewRouter.component = node.page;
        viewRouter.meta = {
          id: node.id,
          title: node.label,
          filepath: node.page,
        };

        viewsMap.set(node.id, viewRouter);
        // 父元素是页面, 当做子页面处理
        if ((parentNode || {}).type === 'page') {
          const parentView = viewsMap.get(parentNode.id);
          parentView.children = parentView.children || [];
          parentView.children.push(viewRouter);
        } else {
          target.push(viewRouter);
        }
      });
    } catch (err) {
      console.error(err);
    }

    return target;
  }

  _getSideNavConfig() {
    const { viewsConfig } = this;

    let target = treeShaking(viewsConfig, item => (item.navVisible || item.type !== 'page'));

    target = treeShaking(target, (item) => {
      if (item.type === 'folder' && Array.isArray(item.children) && item.children.length === 0) {
        return false;
      }
      return true;
    });
    target = treeBuild(target, (node) => {
      const result = {};
      result.id = node.id;
      const { type } = node;
      if (type === 'page') {
        result.type = 'page';
        result.title = node.label;
        result.icon = node.icon;
        result.name = node.name;
        result.route = { name: node.name };
        return result;
      }
      if (type === 'folder') {
        result.type = 'folder';
        result.title = node.label;
        result.icon = node.icon;
        result.children = node.children;
        return result;
      }
      if (type === 'link') {
        result.type = 'link';
        result.title = node.label;
        result.link = node.link;
        return result;
      }
    });

    return target;
  }

  get routeConfig() {
    const routerConfig = deepClone(this._routeConfig);
    const bizMap = {
      1: 'subpage-agent',
      2: 'subpage-adver',
      3: 'subpage-kker',
      4: 'subpage-dmp',
    };
    const AsyncComponent = path => () => import(`@/views/${bizMap[this.biz] || 'common'}/views${path}`);

    const deepReduce = (routeConfig) => {
      // eslint-disable-next-line no-param-reassign
      routeConfig.component = AsyncComponent(routeConfig.component);

      (routeConfig.children || []).forEach((subRouteConfig) => {
        deepReduce(subRouteConfig);
      });
    };
    routerConfig.forEach((item) => {
      deepReduce(item);
    });
    return routerConfig;
  }

  get viewsConfig() {
    return deepClone(this._viewsConfig);
  }

  get sideNavConfig() {
    return deepClone(this._sideNavConfig);
  }

  get keepaliveList() {
    const list = [];

    this._hashMap.forEach((item) => {
      if (item.type !== 'page') return;

      if (item.keepAlive) {
        list.push(item.name);
      }
    });

    return list;
  }

  getPath(id) {
    if (!this._hashMap.get(id)) {
      return [];
    }

    const path = [];
    let current = this._hashMap.get(id);

    do {
      path.unshift(current);
      current = current.parent;
    } while (current);

    return path;
  }

  getNodeByName(name) {
    let target = null;
    this._hashMap.forEach((item) => {
      if (item.name === name) {
        target = item;
      }
    });
    return target;
  }

  getNodeByLabel(name) {
    let target = null;
    this._hashMap.forEach((item) => {
      if (item.label === name) {
        target = item;
      }
    });
    return target;
  }

  getNode(id) {
    return this._hashMap.get(id) || null;
  }
}

export default ViewsController;

// function printNavList(data) {
//   function delKey(obj) {
//     if ('id' in obj) {
//       delete obj.id;
//     }
//     if ('parent_id' in obj) {
//       delete obj.parent_id;
//     }
//     if ('order' in obj) {
//       delete obj.order;
//     }
//   }

//   (data || []).forEach(item1 => {
//     delKey(item1);

//     (item1.children || []).forEach(item2 => {
//       delKey(item2);

//       (item2.children || []).forEach(item3 => {
//         delKey(item3);

//         (item3.children || []).forEach(item4 => {
//           delKey(item4);
//         });
//       });
//     });
//   });

//   console.log(JSON.stringify(data));
// }
