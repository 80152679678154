<template>
  <div>
    <!-- 单选按钮组 -->
    <el-radio-group
      :size="inputSize"
      v-if="type === 'radio'"
      :disabled="disabled"
      v-model="innerVal"
      class="radio-button"
      @change="changeRadioVal">
      <template v-for="item in options">
        <el-radio-button :key="item.value" :label="item.value" :disabled="item.disabled">
          <span style="margin-right: 2px;"> {{ item.label }}</span>
          <el-popover
            v-if="item.explain"
            :key="item.value"
            placement="top"
            width="200"
            trigger="hover">
            <div v-html="item.explain" />
            <kk-icon slot="reference" type="question-circle" />
          </el-popover>
        </el-radio-button>
      </template>
    </el-radio-group>

    <!-- 多选按钮组 -->
    <div v-if="type === 'checkbox'" style="display:flex;" class="checkbox-button">
      <el-checkbox-group
        v-if="clearable && options.length > 0"
        v-model="clearVal"
        :size="inputSize"
        class="clear-button"
        @change="changeClearVal"
        :disabled="disabled">
        <el-checkbox-button :label="-1">{{clearConfig.label || '不限'}}</el-checkbox-button>
      </el-checkbox-group>
      <el-checkbox-group
        :class="{'first-button': clearable, 'checkbox-sign': true}"
        :size="inputSize"
        :disabled="disabled"
        v-model="innerVal"
        @change="changeCheckboxVal"
        :max="max || 100">
        <template v-for="item in options">
          <el-checkbox-button :key="item.value" :label="item.value" :disabled="item.disabled">
            <span style="margin-right: 2px;">{{ item.label }}</span>
            <el-popover
              v-if="item.explain"
              :key="item.value"
              placement="top"
              width="200"
              trigger="hover">
              <div v-html="item.explain" />
              <kk-icon slot="reference" type="question-circle" />
            </el-popover>
          </el-checkbox-button>
        </template>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KkButtonSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  data() {
    return {
      innerVal: '',
      clearVal: [-1],
    };
  },
  props: {
    value: {},
    options: {
      type: Array,
      default: () => ([]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'radio',
    },
    size: {
      type: String,
      default: 'small',
    },
    max: {
      type: Number,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    clearConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    inputSize() {
      return this.size || this.elFormItemSize || (this.$ELEMENT || {}).size;
    },
  },
  watch: {
    value(val) {
      if (this.innerVal !== val) {
        this.innerVal = val;
      }
    },
  },
  created() {
    this.innerVal = this.value;
    if (this.innerVal.length > 0) {
      this.clearVal = [];
    }
  },
  methods: {
    changeRadioVal(evt) {
      this.$emit('change', evt);
    },
    changeClearVal(evt) {
      if (evt.length > 0) {
        this.innerVal = [];
        this.$emit('change', this.innerVal);
      }
    },
    changeCheckboxVal(evt) {
      if (evt.length === 0) {
        this.clearVal.push(-1);
      } else if (this.clearVal.length > 0) {
        this.clearVal = [];
      }
      this.$emit('change', evt);
    },
  },
};
</script>

<style lang="scss">
.radio-button {
  .el-radio-button__inner {
    min-width: 100px;
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #4987EC;
    background-color: #ebf3ff;
    // border: 1px solid #4987EC;
  }

  .el-popover__reference {
    height: 12px;
    display: inline-block;
    vertical-align: top !important;
  }
}

.checkbox-button {
  .el-popover__reference {
    height: 12px;
    display: inline-block;
    vertical-align: top !important;
  }

  .el-checkbox-button__inner {
    min-width: 100px;
  }

  .el-checkbox-button {
    &.is-checked .el-checkbox-button__inner {
      color: #4987EC;
      background-color: #ebf3ff;
    }

    &:first-child .el-checkbox-button__inner {
      border-radius: 2px 0 0 2px;
    }

    &:last-child .el-checkbox-button__inner {
      border-radius: 0 2px 2px 0;
    }
  }

  .clear-button {
    margin-right: -1px;

    .el-checkbox-button {
      &.is-checked .el-checkbox-button__inner {
        z-index: 1;
      }

      &:last-child .el-checkbox-button__inner {
        border-radius: 2px 0 0 2px;
      }
    }
  }

  .first-button {
    .el-checkbox-button:first-child .el-checkbox-button__inner {
      border-radius: 0;
    }
  }

  .checkbox-sign {
    .el-checkbox-button {
      &.is-checked ::after {
        border-left: 6px solid #4987EC;
      }

      &.is-checked.is-disabled :hover::after {
          border-left: 6px solid #4987EC;
        }

      &.is-disabled :hover::after {
        border-left: 6px solid #e0e0e0;
      }

      & ::after {
        content: "";
        position: absolute;
        right: 1px;
        top: -2px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid;
        border-left-color: #e0e0e0;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      & :hover::after {
        border-left: 6px solid #4987EC;
      }
    }
  }
}
</style>
