import { DateTime } from 'luxon';

export function getDisableBeforeDay() {
  const dayStart = DateTime.now().startOf('day').toMillis();
  // console.log(dayStart);
  return function disableBeforeDay(time) {
    return time.getTime() < dayStart;
  };
}

export function getDisableAfterDay() {
  const dayEnd = DateTime.now().endOf('day').toMillis();
  return function disableAfterDay(time) {
    return time.getTime() > dayEnd;
  };
}
