/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/**
 * ajax 封装
 * axios 官方文档: https://github.com/axios/axios#request-config
 */
import Vue from 'vue';
import { Message } from 'element-ui';
// eslint-disable-next-line import/no-named-default
import { default as Axios } from 'axios';
// import store from '@/store';
import user from '@/models/User.js';
import { getRunEnvironment } from '../assets/js/utils';

/** 当前运行环境 */
const env = getRunEnvironment();
// eslint-disable-next-line no-use-before-define
const requestEnv = getRequestEnv();

const axios = Axios.create({
  timeout: 60 * 60 * 1000,
  baseURL: '',
});
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/** 获取请求模式, stag环境还是dev环境 */
function getRequestEnv() {
  if (env !== 'local') return '';

  // 本地环境配置请求模式
  let mode = localStorage.getItem('requestMode') || '';
  if (!mode) {
    mode = 'stag';
    localStorage.setItem('requestMode', mode);
  }
  return mode;
}

/** 判断是否是文件对象 */
function isFile(target) {
  return (target instanceof FileList || target instanceof Blob || target instanceof File);
}

/** 检查 header content-type 中有没有 application/json */
function checkApplicationJson(contentType) {
  if (typeof contentType !== 'string') {
    return false;
  }
  const str = contentType.toLowerCase();
  return (str.indexOf('application/json') >= 0);
}

/** 检查对象内是否有文件 */
function checkHasFile(obj) {
  let hasFile = false;
  if (typeof obj !== 'object') {
    return hasFile;
  }
  Object.keys(obj).forEach((key) => {
    if (isFile(obj[key])) {
      hasFile = true;
    }
  });
  return hasFile;
}

/** 对象序列化 */
function serialization(obj) {
  if (typeof obj !== 'object') return obj;

  return Object.keys(obj).reduce((acc, key) => {
    let val = obj[key];
    if (Array.isArray(val)) {
      val = val.map(item => JSON.stringify(item));
    } else if (typeof val === 'object') {
      val = JSON.stringify(val);
    }
    acc += `${encodeURIComponent(key)}=${encodeURIComponent(val)}&`;
    return acc;
  }, '');
}

// let waitLoginPromise = null;
// function waitForLogin() {
//   if (waitLoginPromise) {
//     return waitLoginPromise;
//   }
//   Message.error('登录信息过期，请重新登录');
//   waitLoginPromise = new Promise((resolve) => {
//     // 轮询检测是否登录成功
//     const checkLogin = () => setTimeout(() => {
//       // if (!store.state.app.isLogin) { // 登录成功
//       //   checkLogin();
//       //   return;
//       // }
//       resolve();
//     }, 1000);
//     checkLogin();
//   }).then(() => {
//     waitLoginPromise = null;
//   }).catch((err) => {
//     waitLoginPromise = null;
//     throw new Error(err);
//   });
//   return waitLoginPromise;
// }

const proxyRegex = [/^\/kyle/, /^\/v2/, /^\/v3/, /^\/media_service/, /^\/search/];
/** 处理请求 url, 主要为本地开发环境使用不同模式开发接口 */
function reduceUrl(url) {
  if (env !== 'local') return url;

  // 验证是否已符合规范的开头
  const isHit = proxyRegex.some(item => item.test(url));

  if (!isHit) return url;

  /** 内部 url 前缀 */
  const urlPrex = `/${requestEnv}`;
  return urlPrex + url;
}

axios.interceptors.request.use((originConfig) => {
  const config = originConfig;
  if (!config.url) {
    throw new Error('request need url');
  }

  if (!config.method) {
    config.method = 'get';
  }
  config.url = reduceUrl(config.url);

  config.headers['Callback-Url'] = window.location.href;

  // 处理参数
  const method = config.method.toLowerCase();
  if (method !== 'post') {
    return config;
  }
  // post 请求处理
  if (config.data instanceof FormData) {
    return config;
  }

  if (checkHasFile(config.data)) {
    console.warn('检测到有文件内容, 自动构造 formData 进行提交, 建议开发者自行构造');
    const formData = new FormData();
    Object.keys(config.data).forEach((key) => {
      let val = config.data[key];
      if (!isFile(val) && typeof val === 'object') {
        val = JSON.stringify(val);
      }
      formData.append(key, val);
    });
    config.data = formData;
    return config;
  }

  if (!checkApplicationJson(config.headers['Content-Type'])) {
    config.transformRequest = [serialization];
    return config;
  }

  return config;
}, (error) => {
  Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  if (!response.request || !response.data || response.data.code === undefined) {
    // 说明非标准回应, 不处理
    return response;
  }

  const { data } = response;
  const { code } = data;

  if (code === 200) {
    return response.data;
  }
  // 未登录
  if (code === 401) {
    user.logout().then(() => {
      if (window.location.hash === '#/login') {
        window.sessionStorage.removeItem('redirect');
      } else {
        window.sessionStorage.setItem('redirect', window.location.href);
      }
      window.location.href = '/effect/#/login';
    }).catch((err) => {
      console.log(err);
    });
    return Promise.reject(data);
  }

  // 302跳转
  if (code === 302) {
    if (data.data.redirect_url) {
      window.location.href = data.data.redirect_url;
    }
    return Promise.reject(data);
  }

  // 无权限
  if (code === 403) {
    if (response.config.method === 'get') {
      Message.error(data.message || '您没有进行此操作的权限');
    }
    return Promise.reject(data);
  }

  // 其它错误码
  if (response.config.method === 'get') {
    Message.error(data.message || '获取数据错误');
  }
  return Promise.reject(data);
}, (error) => {
  const { response } = error || {};
  if (response) {
    Message.error(`服务器错误: status ${response.status}, ${response.statusText}`);
  }
  return Promise.reject(error);
});

/** 扩展方法 */
axios.postJson = (config) => {
  config.headers = config.headers || {};
  config.headers['Content-Type'] = 'application/json; charset=UTF-8';
  config.data = JSON.stringify(config.data);
  config.method = 'post';
  config.transformRequest = [];
  return axios(config);
};

axios.useStagMode = () => {
  if (env === 'local') {
    localStorage.setItem('requestMode', 'stag');
    window.location.reload();
  }
};

axios.useDevMode = () => {
  if (env === 'local') {
    localStorage.setItem('requestMode', 'development');
    window.location.reload();
  }
};

axios.usePreviewMode = () => {
  if (env === 'local') {
    localStorage.setItem('requestMode', 'preview');
    window.location.reload();
  }
};

// ------------------------------------------------------------------------------------------------------
const vuePlugin = {};
// eslint-disable-next-line
vuePlugin.install = (Vue) => {
  // eslint-disable-next-line
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      },
    },
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(vuePlugin);

export default axios;
