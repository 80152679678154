// 按需导出 lodash 的方法, 此写法可触发 tree shaking 模式
import {
  isEmpty,
  isPlainObject,
  isFunction,
} from 'lodash';

export { isFunction, isPlainObject };

/**
 * 检测是否是 promise
 * @param {any} object 待检测的对象
 */
export function isPromise(object) {
  if (Promise && Promise.resolve) {
    return Promise.resolve(object) === object;
  }
  console.warn('Promise not supported in your environment');
  return false;
}

/**
 * 判断是否是空对象
 * @param {any} obj
 */
export function isEmptyObject(obj) {
  if (!isEmpty(obj)) {
    return false;
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
