const ONE_KB = 1024;
const ONE_MB = ONE_KB * 1024;

/**
 * 获取范围类型限制的提示文本
 * @param {String} prx 提示前缀
 * @param {Number} min 范围下限
 * @param {Number} max 范围上限
 * @param {String} unit 单位
 */
function getRangeTip(prx, min, max, unit = '') {
  let str = prx;
  if (min && max) { // 有范围限制
    str += ` ${min}${unit}~${max}${unit}`;
  } else if (min) { // 只有最小范围
    str += ` ≥ ${min}${unit}`;
  } else if (max) { // 只有最大范围
    str += ` ≤ ${max}${unit}`;
  } else { // 无限制
    str += '无限制';
  }
  return str;
}

/** 获取限制条件文本 */
export function rulesTip(rules) {
  if (typeof rules === 'function') {
    return '动态限制';
  }

  const tips = [];

  /** 图像验证规则 */
  let basicRule;
  // 针对动态规则模式, 获取输入为空时的规则
  // 动态规则 rule 为函数, 当选择图片后根据选择的图片生成校验规则
  if (typeof rules === 'function') {
    try {
      basicRule = rules();
    } catch (err) {
      basicRule = {};
    }
  } else {
    basicRule = rules || {};
  }

  // 宽高限制提示语
  if (basicRule.width && basicRule.height) { // 固定宽高限制
    tips.push(`宽高 ${basicRule.width}x${basicRule.height}`);
  } else if (basicRule.width) { // 固定宽限制
    tips.push(`宽度 ${basicRule.width}`);
    tips.push(`${getRangeTip('高度', basicRule.minHeight, basicRule.maxHeight)}`);
  } else if (basicRule.height) { // 固定高限制
    tips.push(`高度 ${basicRule.height}`);
    tips.push(`${getRangeTip('宽度', basicRule.minWidth, basicRule.maxWidth)}`);
  } else { // 宽高都不固定
    tips.push(`${getRangeTip('宽度', basicRule.minWidth, basicRule.maxWidth)}`);
    tips.push(`${getRangeTip('高度', basicRule.minHeight, basicRule.maxHeight)}`);
  }

  // 宽高比限制提示语
  if (basicRule.ratio) {
    if (Array.isArray(basicRule.ratio)) {
      tips.push(`宽高比 ${basicRule.ratio.join(':')}`);
    } else {
      tips.push(`宽高比 ${basicRule.ratio}`);
    }
  }

  // 文件大小
  if (basicRule.minSize || basicRule.maxSize) {
    tips.push(getRangeTip('文件大小', basicRule.minSize, basicRule.maxSize, 'Mb'));
  }

  // 是否动态图 0 不检测, 1 不允许动图, 2 只允许动图
  if (basicRule.allowAnimated && basicRule.allowAnimated > 0) {
    if (basicRule.allowAnimated === 1) {
      tips.push('不允许上传动图');
    } else if (basicRule.allowAnimated === 2) {
      tips.push('只允许上传动图');
    }
  }

  return tips;
}

/** 图像校验 */
export function validateImg(info, rules) {
  // 宽高校验
  if (rules.width) {
    if (info.width !== rules.width) {
      throw new Error('宽度不满足要求');
    }
  } else {
    if (rules.maxWidth && info.width > rules.maxWidth) {
      throw new Error('宽度不满足要求');
    }
    if (rules.minWidth && info.width < rules.minWidth) {
      throw new Error('宽度不满足要求');
    }
  }
  if (rules.height) {
    if (info.height !== rules.height) {
      throw new Error('高度不满足要求');
    }
  } else {
    if (rules.maxHeight && info.height > rules.maxHeight) {
      throw new Error('高度不满足要求');
    }
    if (rules.minHeight && info.height < rules.minHeight) {
      throw new Error('高度不满足要求');
    }
  }

  // 宽高比校验
  if (rules.ratio) {
    let ratioVal;
    if (Array.isArray(rules.ratio) && rules.ratio.length === 2 && rules.ratio[1]) {
      ratioVal = rules.ratio[0] / rules.ratio[1];
    } else {
      ratioVal = rules.ratio;
    }
    if (Math.abs((info.width / info.height) - ratioVal) > Number.EPSILON) {
      throw new Error('宽高比不满足要求');
    }
  }

  // 文件大小校验
  if (rules.minSize && (info.size < rules.minSize * ONE_MB)) {
    throw new Error('文件大小不满足要求');
  }
  if (rules.maxSize && (info.size > rules.maxSize * ONE_MB)) {
    throw new Error('文件大小不满足要求');
  }

  // 动图校验 0 不检测, 1 不允许动图, 2 只允许动图
  if (rules.allowAnimated && rules.allowAnimated > 0) {
    if (rules.allowAnimated === 1 && info.isAnimation) {
      throw new Error('不允许上传动图');
    }
    if (rules.allowAnimated === 2 && !info.isAnimation) {
      throw new Error('只允许上传动图');
    }
  }
  return true;
}
