import TreeNode from './treeNode';

const rootId = Symbol('root');

class Tree {
  constructor(initData, handleNode) {
    this.store = new Map();
    this.root = new TreeNode({ id: rootId });

    const traverse = (data, parent) => {
      if (handleNode) {
        // eslint-disable-next-line no-param-reassign
        data = handleNode(data);
      }
      const node = new TreeNode(data);
      node.appendTo(parent);

      const { children } = data;

      children.forEach((child) => {
        traverse(child, node);
      });
    };

    initData.forEach((item) => {
      traverse(item, this.root);
    });
  }

  shaking() {
    return this;
  }

  filter(checkMethod) {
    const traverse = (node) => {
      const childNodes = node.children;

      childNodes.forEach((child) => {
        // eslint-disable-next-line no-param-reassign
        child.visible = checkMethod.call(child, child.data, child);
        traverse(child);
      });

      if (!node.visible && childNodes.length) {
        let allHidden = true;
        allHidden = !childNodes.some(child => child.visible);

        // eslint-disable-next-line no-param-reassign
        node.visible = allHidden === false;
      }
    };

    traverse(this);
  }

  getNode(data) {
    if (data instanceof TreeNode) return data;
    const key = typeof data !== 'object' ? data : data.id;
    return this.store.get(key) || null;
  }

  insertBefore(data, refData) {
    const refNode = this.getNode(refData);
    refNode.parent.insertBefore({ data }, refNode);
  }

  insertAfter(data, refData) {
    const refNode = this.getNode(refData);
    refNode.parent.insertAfter({ data }, refNode);
  }

  remove(data) {
    const node = this.getNode(data);

    if (node && node.parent) {
      node.parent.removeChild(node);
      this.deregisterNode(node);
    }
  }

  append(data, parentData) {
    const node = data instanceof TreeNode ? data : new TreeNode(data);
    const parentNode = parentData ? this.getNode(parentData) : this.root;

    if (parentNode) {
      parentData.append(node);
    }
  }

  registerNode(node) {
    this.store.set(node.id, node);

    node.children.forEach((child) => {
      this.registerNode(child);
    });
  }

  deregisterNode(node) {
    node.children.forEach((child) => {
      this.deregisterNode(child);
    });

    this.store.delete(node.id);
  }
}

export default Tree;
