// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios';
import md5 from 'js-md5';
import EventObj from './EventObj';
/**
 * 用户相关操作
 * 此处进行数据存储
 */

const modeConfig = {
  common: {
    index: '/effect',
  },
  agent: {
    biz: 1,
    userType: 1,
    index: '/effect/agent',
  },
  adver: {
    biz: 2,
    userType: 2,
    index: '/effect/advertise',
  },
  kkuser: {
    biz: 3,
    userType: 3,
    index: '/effect/kker',
  },
  dmpuser: {
    biz: 4,
    userType: 4,
    index: '/effect/dmp',
  },
};

class User extends EventObj {
  constructor(mode = 'common') {
    super();
    const userCatch = JSON.parse(localStorage.getItem('kyle_account') || '{}');
    this.mode = mode;
    this.id = userCatch.id || '';
    this.name = userCatch.name || '';
    this.type = userCatch.type || '';
    this.typeName = userCatch.typeName || '';
    this.company = userCatch.company || '';
    this.username = userCatch.username || '';
    this.agency_type = userCatch.agency_type || ''; // 1-快看代理商，2-三方代理商
    this.client_type = userCatch.client_type || ''; // // 1-直客广告主，2-三方广告主
    this.uid = userCatch.uid || '';
    this.detail = {};
    this.possessInfo = {};
    this.possessLogin = false;
  }

  /** 用户是否登录 */
  get isLogin() {
    return !!this.uid;
  }

  // todo: toAdmin to toUserIndex
  toUserIndex() {
    if (!this.type) {
      return;
    }
    const userType = this.type;
    const targetMode = Object.keys(modeConfig).find(mode => (modeConfig[mode].userType === userType));
    if (!targetMode) {
      return;
    }

    if (!modeConfig[targetMode].index) {
      return;
    }

    window.location.href = modeConfig[targetMode].index;
    // const target = mode || this.mode;
    // const modeInfo = modeConfig[target];

    // if (target ==)

    // if (this.type === 1) {
    //   window.location.href = '/effect/agent';
    // } else if (this.type === 2) {
    //   window.location.href = '/effect/advertise';
    // }
  }

  /** 登录 */
  async login(username, password, challenge) {
    const { data } = await axios.postJson({
      method: 'post',
      url: '/kyle/login',
      data: {
        login_name: username,
        password: md5(password),
        challenge,
      },
    });

    this.id = data.account_id;
    this.name = data.account_name;
    this.type = data.account_type;
    this.typeName = data.account_type_desc;
    this.company = data.account_company_name;
    this.username = data.login_name;
    this.uid = data.user_id;
    this.agency_type = data.agency_type || ''; // 1-快看代理商，2-三方代理商
    this.client_type = data.client_type || ''; // // 1-直客广告主，2-三方广告主

    localStorage.setItem('kyle_account', JSON.stringify(this));
    // 登录成功后, 检查是否有跳转信息
    const redirect = window.sessionStorage.getItem('redirect');
    window.sessionStorage.removeItem('redirect');
    if (redirect) {
      window.location.href = redirect;
    } else {
      this.toUserIndex();
    }
    this.trigger('login');
  }

  /** 登出 */
  async logout() {
    // 无 token 说明 token 过期或首次打开页面, 无需调用登录接口
    if (!this.id) {
      return;
    }

    // 当前正在登出
    if (this.logoutPromise) {
      return;
    }

    // 调用登出接口, 记录登出接口promise对象, 防止登出过程中重复调用
    /** 登出异步任务对象 */
    this.logoutPromise = await axios({
      method: 'post',
      url: '/kyle/logout',
    });

    this.logoutPromise = null;

    // 清空数据
    this.id = '';
    this.name = '';
    this.type = '';
    this.typeName = '';
    this.company = '';
    this.username = '';
    this.uid = '';
    this.agency_type = '';
    this.client_type = '';
    this.detail = {};

    localStorage.removeItem('kyle_account');
    this.trigger('logout');
  }

  /** 获取用户信息 */
  async getInfo() {
    if (!this.isLogin) {
      return;
    }
    try {
      const modeInfo = modeConfig[this.mode] || {};

      const { data } = await axios({
        url: '/kyle/user/current/info',
        method: 'get',
        params: {
          biz: modeInfo.biz || this.type,
        },
      });

      this.detail = {};
      this.detail.id = data.account_id;
      this.detail.name = data.account_name;
      this.detail.type = data.account_type;
      this.detail.typeName = data.account_type_desc;
      this.detail.company = data.account_company_name;
      this.detail.username = data.login_name;
      this.detail.uid = data.user_id;
      this.detail.agency_type = data.agency_type; // 1-快看代理商，2-三方代理商
      this.detail.client_type = data.client_type; // // 1-直客广告主，2-三方广告主
      this.detail.client_type = data.client_type; // // 1-直客广告主，2-三方广告主

      this.possessLogin = data.possess_login;
      this.possessInfo = {};

      if (data.possess_login && data.possess_user_info) {
        this.possessInfo.id = data.possess_user_info.account_id;
        this.possessInfo.name = data.possess_user_info.account_name;
        this.possessInfo.type = data.possess_user_info.account_type;
        this.possessInfo.typeName = data.possess_user_info.account_type_desc;
        this.possessInfo.company = data.possess_user_info.account_company_name;
        this.possessInfo.username = data.possess_user_info.login_name;
        this.possessInfo.uid = data.possess_user_info.user_id;
        this.possessInfo.agency_type = data.possess_user_info.agency_type; // 1-快看代理商，2-三方代理商
        this.possessInfo.client_type = data.possess_user_info.client_type; // 1-直客广告主，2-三方广告主
      }

      // 王治国建议去除此处掉用（运营账户登录时会报错，且广告组获取密钥也不用此处先获取）
      // if (data.possess_login) {
      //   // 获取广告主密钥
      //   const { data: secdata = {} } = await axios({
      //     url: '/kyle/client/get',
      //     method: 'get',
      //     params: {
      //       id: this.possessInfo.id || this.detail.id,
      //     },
      //   });
      //   this.detail.conversion_secret = secdata.conversion_secret || '';
      // }
      this.trigger('userInfo');
    } catch (err) {
      if (err.code === 306) {
        this.toUserIndex();
      } else {
        console.error(err);
        this.detail = {};
      }
    }
  }

  /** 修改密码 */
  async changePassword(newPwd, oldPwd) {
    if (!this.isLogin) {
      throw new Error('当前未登录, 无法修改密码');
    }
    await axios.postJson({
      url: '/kyle/user/pwd/change',
      method: 'post',
      data: {
        new_pwd: md5(newPwd),
        old_pwd: md5(oldPwd),
      },
    });
    this.logout();
  }

  /** 忘记密码 */
  async forgetPassword(username, code, pwd) {
    if (this.isLogin) {
      throw new Error('当前状态已登录, 不可执行忘记密码操作');
    }
    await axios.postJson({
      url: '/kyle/user/pwd/forget',
      method: 'post',
      data: {
        username,
        code,
        pwd: md5(pwd),
      },
    });
  }
}
export default new User();

export {
  User,
};
