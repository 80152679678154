<!-- 分页类型组件 -->
<template>
  <div v-if="url || fetchFuc">
    <div v-loading="loading">
      <slot :list="list" :loading="loading" :total="total" :currentPage="currentPage"></slot>
    </div>
    <div style="text-align: right; padding: 15px 0;">
      <el-pagination
        background
        :layout="layout"
        :current-page.sync="currentPage"
        :page-sizes="pageSizeList"
        :page-size="innerPageSize"
        :total="total"
        @size-change="changePageSize"
        @current-change="fetchData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import defaultConfig from './defaultConfig';

export default {
  name: 'KkPageView',
  components: {},
  data() {
    return {
      loading: false,
      currentPage: 1,
      total: 0,
      list: [],
      innerPageSize: 20,
      pageSizeList: [10, 20, 30, 50, 100],
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.innerPageSize;
    },
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    fetch: {
      type: Boolean,
      default: false,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    beforeFetch: {
      type: Function,
      default: null,
    },
    afterFetch: {
      type: Function,
      default: null,
    },
    fetchFuc: {
      type: Function,
      default: null,
    },
    catchKey: {
      type: Array,
      default: () => [],
    },
    pagemode: {
      type: String,
      default: defaultConfig.pagemode,
    },
    pageSize: {
      type: Number,
      default: defaultConfig.pageSize,
    },
    layout: {
      type: String,
      default: defaultConfig.layout,
    },
    keyData: {
      type: String,
      default: defaultConfig.datakey,
    },
    keyTotal: {
      type: String,
      default: defaultConfig.totalkey,
    },
    keyList: {
      type: String,
      default: defaultConfig.listkey,
    },
    keyOffset: {
      type: String,
      default: defaultConfig.offsetkey,
    },
    keyPagesize: {
      type: String,
      default: defaultConfig.pagesizekey,
    },
    keyCurrentpage: {
      type: String,
      default: defaultConfig.currentpagekey,
    },
  },
  watch: {
    fetch(newVal) {
      if (newVal === false) {
        return;
      }
      // 外部触发获取数据需重置页码
      this.currentPage = 1;
      // 重置页码后获取数据
      this.$nextTick(() => {
        this.fetchData();
      });
    },
  },
  created() {
    // 辅助变量
    this.xm_lock = false;
    this.xm_catchReq = false;
    if (this.pageSize) {
      this.innerPageSize = this.pageSize;
    }
    // 插入分页配置中不存在的配置
    if (!this.pageSizeList.includes(this.innerPageSize)) {
      const pos = this.pageSizeList.findIndex(size => (size > this.innerPageSize));
      this.pageSizeList.splice(pos, 0, this.innerPageSize);
    }
  },
  mounted() {
    if (this.fetch) {
      this.fetchData();
    }
  },
  methods: {
    /** 页码修改 */
    changePageSize(newPageSize) {
      this.innerPageSize = newPageSize;
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    /** 获取数据 */
    async fetchData(arg) {
      if (!this.url && !this.fetchFuc) return;

      if (this.xm_lock) { // 表示当前是锁住状态
        this.xm_catchReq = true; // 表示有缓存
        return;
      }
      this.xm_lock = true; // 加锁
      this.loading = true; // 显示加载中状态

      if (!arg || !arg.fromCatch) {
        this.$emit('begin-fetch');
      }

      // 获取请求参数
      const params = await this.structureParams();

      let resData = await this.$fetch(params).catch(err => ({
        type: 'error',
        err,
      }));

      // 检查数据返回之前是不是有触发新请求, 有新请求则触发新的请求, 本次结果作废
      if (this.xm_catchReq) {
        this.xm_catchReq = false;
        this.xm_lock = false;
        this.fetchData({ fromCatch: true });
        return;
      }

      // 解锁
      this.xm_lock = false;
      this.xm_catchReq = false;
      this.loading = false;

      if (resData && resData.type === 'error') {
        this.$emit('error-fetch', resData.err);
        this.$emit('update:fetch', false);
        return;
      }

      this.$emit('update:fetch', false);
      this.$emit('end-fetch', resData);

      if (this.afterFetch) {
        const resDataCatch = { ...resData };
        resData = await this.$utils.tryRun(this.afterFetch, resDataCatch).catch((err) => {
          this.$message.error(err.message || 'afterFetch 执行出错');
          return resData;
        });
      }

      this.setListData(resData);
    },
    /** 构造请求参数 */
    async structureParams() {
      // 调整执行序, 处理数据未及时变更的问题
      await this.$utils.sleep(1);
      let params = { ...this.params };

      // 添加分页请求需要的参数
      if (this.pagemode === 'offset') { // 偏移量模式
        params[this.keyOffset] = this.offset;
      } else { // 当前页模式
        params[this.keyCurrentpage] = this.currentPage;
      }
      params[this.keyPagesize] = this.innerPageSize;

      if (this.beforeFetch) {
        const paramsCatch = { ...params };
        params = await this.$utils.tryRun(this.beforeFetch, paramsCatch).catch((err) => {
          this.$message.error(err.message || 'beforeFetch 执行出错');
          return params;
        });
      }

      return params;
    },
    /** 将远程获取到的数据设置变量 */
    setListData(resData) {
      const data = this.keyData ? ((resData || {}).data || {})[this.keyData] : (resData || {}).data;

      if (!data) {
        this.$message.error('BasePageView: 找不到返回数据');
        console.error('BasePageView: 找不到返回数据', resData);
        return;
      }

      if (typeof data[this.keyTotal] === 'undefined' || typeof data[this.keyList] === 'undefined') {
        this.$message.error('BasePageView: 找不到对应的总数/数据列表字段');
        console.error('BasePageView: 找不到对应的总数/数据列表字段', data);
        return;
      }

      this.list = data[this.keyList];
      this.total = data[this.keyTotal];
      if (this.catchKey.length > 0) {
        this.list = this.list.map((item) => {
          const newItem = item;
          newItem.catch = Object.create(null);
          this.catchKey.forEach((key) => {
            if (typeof newItem[key] !== 'undefined') {
              // todo: 缓存时是否处理引用类型
              newItem.catch[key] = newItem[key];
            }
          });
          return newItem;
        });
      }
    },
    /**
     * ajax方法
     * @param {string} url 请求地址
     * @param {object} params 请求参数
     */
    async $fetch(params) {
      const { url, fetchFuc } = this;
      if (url) {
        return this.$axios({
          method: 'get',
          url,
          params,
        });
      }
      return fetchFuc(params);
    },
  },
};
</script>
