<!-- 长文本展示 -->
<template>
  <div class="kk-long-text" v-if="!longText">{{ text }}</div>
  <el-tooltip
    v-else
    class="kk-long-text"
    effect="dark"
    :content="longText"
    :placement="placement"
    popper-class="text-limit-tooltip"
    :open-delay="500">
    <div>{{ shortText }}</div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'KkLongText',
  data() {
    return {};
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 30,
    },
    placement: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    shortText() {
      return this.$utils.limitText(this.text, this.limit);
    },
    longText() {
      if (this.shortText === this.text) {
        return '';
      }
      return this.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.kk-long-text {
  display: inline-block;
}
</style>
<style lang="scss">
.text-limit-tooltip {
  max-width: 300px;
}
</style>
