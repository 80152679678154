import EventObj from '@/models/EventObj.js';

// 设置隐藏属性和改变可见属性的事件的名称
let hidden;
let visibilityChange;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

/**
 * 监听网页是否可见
 * @example
 * pageVisible.on('hidden', () => {
 *   console.log('监听到 hidden 事件');
 * });
 *
 * pageVisible.on('show', () => {
 *   console.log('监听到 show 事件');
 * });
 */
const pageVisible = new EventObj();

pageVisible.getStatus = () => (document[hidden] ? 'hidden' : 'show');

function handleVisibilityChange() {
  if (document[hidden]) {
    pageVisible.trigger('hidden');
    console.log(`%c 页面隐藏 %c ${new Date(Date.now())}  %c`,
      'background: #35495e; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
      'background: #ffd801; padding: 1px; border-radius: 0 3px 3px 0;  color: #000',
      'background:transparent');
  } else {
    pageVisible.trigger('show');
    console.log(`%c 页面可见 %c ${new Date(Date.now())}  %c`,
      'background: #35495e; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
      'background: #ffd801; padding: 1px; border-radius: 0 3px 3px 0;  color: #000',
      'background:transparent');
  }
}

// 如果浏览器不支持addEventListener 或 Page Visibility API 给出警告
if (typeof document.addEventListener === 'undefined' || typeof document[hidden] === 'undefined') {
  console.log('浏览器不支持页面可见性 API');
} else {
  // 处理页面可见属性的改变
  document.addEventListener(visibilityChange, handleVisibilityChange, false);
}

export default pageVisible;
