import axios from '@/plugins/axios.js';

class Notice {
  constructor(biz) {
    if (!biz) {
      throw new Error('Notice Error: biz is required');
    }
    this.biz = biz;
    this.updateTime = 0;
    this.globalNotices = [];
  }

  /** 从服务端拉取消息数据 */
  async fetch() {
    const { data } = await axios({
      method: 'get',
      url: '/kyle/user/notice/list',
      params: {
        biz: this.biz,
      },
    });
    // todo: 返回html字符串, 需做预防 xss 攻击处理
    this.globalNotices = data.global_notices;
  }

  /** 根据id关闭消息 */
  async closeNotice(nId) {
    await axios({
      method: 'post',
      url: '/kyle/user/notice/close',
      data: {
        id: nId,
      },
    });
    await this.fetch();
  }
}

export default Notice;
