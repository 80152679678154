const { hasOwnProperty } = Object.prototype;

function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
}

function isVNode(node) {
  return node !== null && typeof node === 'object' && hasOwn(node, 'componentOptions');
}

export default isVNode;
