<template>
  <div class="header">
    <div class="logo">
      <div style="display: inline-block; cursor: pointer;" title="后羿官网" @click="goIntroduction">
        <img src="https://festatic.v3mh.com/static-resource/img/20210706/tiny/logo_64x64.png" style="width: 26px; vertical-align: middle;" />
      </div>
      <el-divider direction="vertical" />
      <span @click="goHome" style="cursor: pointer;">{{title}}</span>
    </div>

    <!-- 导航 -->
    <div style="flex: 1; padding: 0 30px;">
      <navbar />
    </div>

    <div class="header-action">
      <div v-if="!name" class="action-item">登录</div>
      <el-popover
        v-if="name"
        placement="bottom"
        trigger="click"
        transition="el-zoom-in-top"
        :visible-arrow="false">
        <div class="drop-menu">
          <div class="drop-menu-item" @click="goUserCenter">
            <i class="el-icon-user" /> 个人中心
          </div>
          <div class="drop-menu-item" @click="goOperaLog" v-if="typeName === '广告主'">
            <i class="el-icon-user" /> 操作日志
          </div>
          <div class="drop-menu-item" @click="logout">
            <i class="el-icon-switch-button" /> 退出账户
          </div>
          <!-- <div class="drop-menu-item" @click="openAppDrawer">
            <i class="el-icon-setting" /> 开发设置
          </div> -->
        </div>
        <div class="action-item" slot="reference">
          <el-avatar :size="30" icon="el-icon-user-solid" />
          <div style="display: inline-block; margin: 0 4px 0 8px; max-width: 120px; font-size: 12px;">
            <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" :title="name">{{name}}</div>
            <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" :title="name">ID: {{uid}}</div>
          </div>
          <i class="icon el-icon-arrow-down" />
        </div>
      </el-popover>

      <div v-if="possessLogin" class="action-item" @click="showPossessInfo">
        <i class="el-icon-user-solid" /> 附身登录中
      </div>

      <div v-if="environment !== 'production'" class="action-item">
        <i class="icon el-icon-coordinate" /> {{envTitle}}
      </div>
    </div>

    <el-dialog v-if="possessInfo" title="您正在附身登录中" width="500px" :append-to-body="true" :visible.sync="possesInfoVisible">
      <kk-form>
        <kk-form-item label="真实用户">
          {{possessInfo.name}} (id: {{possessInfo.uid}})
        </kk-form-item>
        <kk-form-item label="身份">
          {{possessInfo.typeName}}
        </kk-form-item>
        <kk-form-item label="公司">
          {{possessInfo.company}}
        </kk-form-item>
      </kk-form>
      <span slot="footer">
        <el-button type="primary" @click="goUserIndex">返回系统</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import user from '@/models/User.js';
import Navbar from './navbar';

export default {
  name: 'AppHeader',
  components: {
    Navbar,
  },
  data() {
    return {
      activeIndex: '',
      baseUrl: process.env.BASE_URL,
      canFullscreen: false,
      showFeedback: false,
      possesInfoVisible: false,
    };
  },
  props: {
    title: String,
  },
  computed: {
    environment() {
      return this.$store.state.app.environment;
    },
    mode() {
      return this.$store.state.app.mode;
    },
    name() {
      return this.$store.state.user.name;
    },
    typeName() {
      return this.$store.state.user.typeName;
    },
    possessLogin() {
      return this.$store.state.user.possessLogin;
    },
    possessInfo() {
      return this.$store.state.user.possessInfo;
    },
    uid() {
      return this.$store.state.user.id;
    },
    breadcrumb() {
      return this.$store.state.app.breadcrumb;
    },
    envTitle() {
      if (this.environment === 'production') {
        return '生产环境';
      }
      if (this.environment === 'stag') {
        return 'stag环境';
      }
      if (this.environment === 'development') {
        return 'dev环境';
      }
      if (this.environment === 'local') {
        return `本地-${this.mode}`;
      }
      return `未知环境-${this.environment}`;
    },
  },
  methods: {
    goIntroduction() {
      window.location.href = process.env.BASE_URL;
    },
    goHome() {
      this.$router.push('/');
    },
    goUserCenter() {
      if (this.$route.name !== 'accountCenter') {
        this.$router.push({
          name: 'accountCenter',
        });
      }
    },
    goOperaLog() {
      if (this.$route.name !== 'operationLog') {
        this.$router.push({
          name: 'operationLog',
        });
      }
    },
    async logout() {
      this.$store.dispatch('user/logout');
    },
    openAppDrawer() {
      this.$store.commit('app/openDrawer');
    },
    showPossessInfo() {
      if (!this.possessLogin) {
        return;
      }
      this.possesInfoVisible = true;
    },
    goUserIndex() {
      if (!this.possessLogin) {
        return;
      }
      user.toUserIndex();
    },
  },
};
</script>

<style lang="scss" scoped>
$height: 60px;

.header {
  z-index: 10;
  height: $height;
  min-height: $height;
  background-color: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  display: flex;
  position: sticky;
  top: 0;
}

.logo {
  height: $height;
  padding-left: 24px;
  overflow: hidden;
  line-height: $height;
  text-decoration: none;
}

.header-action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: stretch;
  color: #909399;

  .action-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all .3s ease;
    font-size: 14px;

    &:hover {
      background-color: rgba(0, 0, 0, .025);
      color: #636363;
    }
  }
}

.drop-menu {
  .drop-menu-item {
    cursor: pointer;
    margin: 0 -12px;
    padding: 6px 12px;
    color: #909399;

    &:hover {
      background-color: rgba(0, 0, 0, .025);
      color: #636363;
    }
  }
}

.icon {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1em;
}
</style>

<style lang="scss">
.app-breadcrumb {
  .el-breadcrumb__inner {
    color: #d4dade;
  }
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: rgb(0 0 0 / 0.025);
}
</style>
