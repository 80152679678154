export default function auth(store, defaultMode = 'hide') {
  return {
    bind(el, binding) {
      console.log(binding.value);
      const authList = binding.value;
      const isAllow = authList.some(item => store.state.app.auth.get(item));
      const mode = binding.argument || defaultMode;
      const element = el;
      if (isAllow) {
        return;
      }
      if (mode === 'hide') {
        element.style.display = 'none';
      } else {
        element.disabled = true;
        element.style.opacity = 0.4;
        element.style.cursor = 'not-allowed';
      }
    },
  };
}
