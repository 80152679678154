<template>
  <div>
    <div style="margin-bottom: 5px;">
      <el-tag
        :key="tag"
        v-for="(tag, index) in tags"
        :closable="!inputDisabled"
        :disable-transitions="false"
        :size="inputSize"
        @close="handleDel(index)"
        style="margin: 0 5px 5px 0;">
        {{tag}}
      </el-tag>
    </div>
    <el-input
      v-if="!inputDisabled"
      v-model="inputVal"
      @change="handleInput"
      :placeholder="placeholder"
      :disabled="inputDisabled || overMutiLimit"
      :size="inputSize"
      prefix-icon="el-icon-plus" />
    <template v-if="!inputDisabled">
      <template v-if="$slots.desc">
        <slot name="desc" />
      </template>
      <div v-else style="font-size: 12px; color: gray; line-height: 20px;">回车或取消焦点添加, 也可同时添加多个, 输入多个时使用半角(,)逗号分隔</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'KkTagsInput',
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  data() {
    return {
      tags: [],
      separator: ',',
      inputVal: '',
    };
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: String,
    validate: {
      type: Function,
      default: null,
    },
    unique: {
      type: Boolean,
      default: true,
    },
    multipleLimit: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize() {
      return this.size || this.elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    overMutiLimit() {
      if (this.multipleLimit === 0) {
        return false;
      }
      return this.tags.length >= this.multipleLimit;
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  mounted() {
    this.tags = this.converTags(this.value);
  },
  watch: {
    value(val) {
      this.tags = this.converTags(val);
    },
  },
  methods: {
    /**
     * 将字符串转为标签数组
     * @param {string} str 原字符串
     * @returns {array<string>} 标签数组
     */
    converTags(str) {
      const { separator } = this;
      if (!str) {
        return [];
      }
      let source = str;
      if (typeof str !== 'string') {
        source = str.toString();
      }
      const result = source.split(separator);
      return result.map(item => item.trim()).filter(item => !!item);
    },
    /**
     * 转化数组为字符串
     */
    converStr() {
      const { separator, tags } = this;
      if (!Array.isArray(tags)) {
        return '';
      }
      return tags.map(item => item.trim()).filter(item => !!item).join(separator);
    },
    /**
     * 处理用户输入, 往外 emit 新值
     * @param {string} value 数据文本
     */
    handleInput(value) {
      const newTags = this.converTags(value);

      const dupicateItems = [];
      const errorItems = [];
      newTags.forEach((newTag) => {
        // 判断是否超出限制
        if (this.multipleLimit > 0 && this.tags.length >= this.multipleLimit) {
          return;
        }

        if (this.unique && this.tags.findIndex(tag => (tag === newTag)) >= 0) {
          dupicateItems.push(newTag);
          return;
        }
        if (this.$utils.isFunction(this.validate)) {
          if (!this.validate(newTag)) {
            errorItems.push(newTag);
            return;
          }
        }
        this.tags.push(newTag);
      });

      if (dupicateItems.length > 0) {
        this.$message(`已存在 ${dupicateItems.join(', ')}`);
      }

      if (errorItems.length > 0) {
        this.$message.error(`${errorItems.join(', ')} 格式验证不通过`);
      }

      this.inputVal = errorItems.join(',');
      this.$emit('change', this.converStr());
    },
    /**
     * 处理用户删除标签
     */
    handleDel(index) {
      this.tags.splice(index, 1);
      this.$emit('change', this.converStr());
    },
  },
};
</script>
