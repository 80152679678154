<template>
  <span v-html="content" style="vertical-align: middle;"></span>
</template>
<script>
import * as antdIcons from '@ant-design/icons-svg';
import { renderIconDefinitionToSVGElement } from '@ant-design/icons-svg/es/helpers';

const modeMap = {
  outlined: 'Outlined',
  filled: 'Filled',
  twoTone: 'TwoTone',
};

function nameFormat(str) {
  if (!str) return '';

  const list = str.split('-');
  return list.map(item => (item.charAt(0).toUpperCase() + item.slice(1))).join('');
}

export default {
  name: 'KkIcon',
  data() {
    return {
      content: '',
    };
  },
  props: {
    /**
     *  https://www.antdv.com/components/icon/
     */
    type: {
      type: String,
      required: true,
    },
    /**
     * 展示模式, outlined, filled, twoTone
     */
    theme: {
      type: String,
      default: 'outlined',
    },
  },
  watch: {
    type() {
      this.renderIcon();
    },
    theme() {
      this.renderIcon();
    },
  },
  created() {
    this.renderIcon();
  },
  methods: {
    renderIcon() {
      const iconName = `${nameFormat(this.type)}${modeMap[this.theme]}`;
      const icon = antdIcons[iconName];
      if (!icon) {
        this.content = '';
        return;
      }
      this.content = renderIconDefinitionToSVGElement(icon, {
        extraSVGAttrs: { width: '1em', height: '1em', fill: 'currentColor' },
      });
    },
  },
};

</script>
