// 常用正则 https://www.cnblogs.com/zxin/archive/2013/01/26/2877765.HTML
const validateRegex = {
  /** 名称是否合法(中文、英文、数字包括下划线) */
  name: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/,
  /** app版本号验证, 6位数字 */
  versionApp: /^\d{6}$/,
  /** 人工输入ID, 字母、数字包括下划线 */
  manualId: /^\w+$/,
  /** 数字 */
  numberStr: /^[0-9]*$/,
  /** 手机号 */
  phoneNumber: /^([1][3,4,5,6,7,8,9])\d{9}$/,
  /** 固定电话 */
  telephoneNumber: /^((\d{3,4}-)|\d{3.4}-)?\d{7,8}$/,
  email: /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  /** 密码: 8位以上大写字母、小写字母、数字及下划线号组成 */
  password: /^[A-Za-z0-9_]{8,}$/,
  /** 链接 */
  InternetURL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  InternetHttpsURL: /^https:\/\/\S*/,
};

/** 常用校验函数 */
export default {
  name(val) {
    return validateRegex.name.test(val);
  },
  versionApp(val) {
    return validateRegex.versionApp.test(val);
  },
  manualId(val) {
    return validateRegex.manualId.test(val);
  },
  phoneNumber(val) {
    return validateRegex.phoneNumber.test(val);
  },
  telephoneNumber(val) {
    return validateRegex.telephoneNumber.test(val);
  },
  email(val) {
    return validateRegex.email.test(val);
  },
  password(val) {
    return validateRegex.password.test(val);
  },
  InternetURL(val) {
    return validateRegex.InternetURL.test(val);
  },
  InternetHttpsURL(val) {
    return validateRegex.InternetHttpsURL.test(val);
  },
  numberStr(val) {
    return validateRegex.numberStr.test(val);
  },
};
