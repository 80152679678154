<template>
  <el-form ref="elForm" v-bind="$props" class="kk-form" @validate="spreadEvent('validate', $event)">
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </el-form>
</template>

<script>
export default {
  name: 'KkForm',
  props: {
    model: Object,
    rules: Object,
    labelPosition: String,
    labelWidth: {
      type: String,
      default: '140px',
    },
    labelSuffix: {
      type: String,
      default: ':',
    },
    inline: Boolean,
    inlineMessage: Boolean,
    statusIcon: Boolean,
    showMessage: {
      type: Boolean,
      default: true,
    },
    size: String,
    disabled: Boolean,
    validateOnRuleChange: {
      type: Boolean,
      default: true,
    },
    hideRequiredAsterisk: {
      type: Boolean,
      default: false,
    },

    /** 支持出入 formInfo 对象 */
    formInfo: {
      type: Object,
      default: null,
    },
  },
  provide() {
    return {
      kkFormInfo: this.formInfo,
    };
  },
  methods: {
    /** 同 el-form validate */
    validate() {
      // eslint-disable-next-line
      return this.$refs.elForm.validate.apply(this.$refs.elForm, arguments);
    },
    /** 同 el-form validateField */
    validateField() {
      // eslint-disable-next-line
      return this.$refs.elForm.validateField.apply(this.$refs.elForm, arguments);
    },
    /** 同 el-form resetFields */
    resetFields() {
      // eslint-disable-next-line
      return this.$refs.elForm.resetFields.apply(this.$refs.elForm, arguments);
    },
    /** 同 el-form clearValidate */
    clearValidate() {
      // eslint-disable-next-line
      return this.$refs.elForm.clearValidate.apply(this.$refs.elForm, arguments);
    },
    /** 传递事件触发 */
    spreadEvent(name, evt) {
      this.$emit(name, evt);
    },
  },
};
</script>
