import Vue from 'vue';
import * as utils from '@/assets/js/utils';

const myPlugin = {};

// eslint-disable-next-line
myPlugin.install = (Vue) => {
  // eslint-disable-next-line
  Object.defineProperties(Vue.prototype, {
    $utils: {
      get() {
        return utils;
      },
    },
  });
};

Vue.use(myPlugin);

export default utils;
