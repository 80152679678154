<template>
  <div id="app-body" style="flex: 1; position: relative; width: 100%; padding: 12px 24px;">
    <div v-if="breadcrumb.length > 1" style="margin-bottom: 16px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in breadcrumb" :key="item.id">{{ item.label }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppBody',
  data() {
    return {};
  },
  computed: {
    // todo: 支持面包屑点击
    breadcrumb() {
      return this.$store.state.app.breadcrumb;
    },
  },
};
</script>

<style lang="scss">
.el-breadcrumb__separator {
  font-weight: normal;
}
</style>
