/**
 * 由于不同项目中约定的分页结构不一样, 因此抽出本配置文件, 使用者可根据本项目需求进行配置
 */

export default {
  /** 分页请求模式, offset/currentPage (传偏移量还是当前页) */
  pagemode: 'currentPage',
  /** 服务端返回值模式, 总数模式, 是返回查询到记录的条数还是返回总页数 */
  totalmode: 'total',
  /** 返回数据结构, 默认返回主体, 但是如果被包装在子项中, 则使用本配置指定数据的位置, 通常不使用 */
  datakey: '',
  /** 返回数据中表述总数的值的 key */
  totalkey: 'total_count',
  /** 返回值数据中表示列表数据的字段名称, 取值示例 "result.data.list" */
  listkey: 'list',
  /** 请求参数偏移参数 key 值 */
  offsetkey: 'offset',
  /** 请求参数每页数据量 key 值 */
  pagesizekey: 'page_size',
  /** 请求参数当前页 key 值 */
  currentpagekey: 'page_num',
  /** 请求每页数据量 */
  pageSize: 20,
  /** 分页显示内容 */
  layout: 'total,sizes,prev,pager,next,jumper',
};
