<template>
  <div @click="handleInit" :style="{
    position: 'relative',
    cursor: disabled ? 'not-allowed' : 'pointer'
  }">
    <slot></slot>
    <img-gallery
      v-if="init"
      ref="gallery"
      :disabled="disabled"
      :speed="speed"
      :loop="loop"
      :preload="preload"
      :thumbail="thumbail"
      :zoom="zoom"
      :dynamic="dynamic"
      :selector="selector"
      :keypress="keypress"
      :index="index"
      :enable-download="enableDownload"
      :content="imgList"
      :visible.sync="visible" />
  </div>
</template>

<script>
import utils from './utils';
import ImgGallery from './ImgGallery';

export default {
  name: 'KkGallery',

  data() {
    return {
      init: false,
      visible: false,
      index: 0,
      imgList: [],
      backdropDuration: 150,
    };
  },
  components: {
    ImgGallery,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    speed: {
      type: Number,
      default: 300,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    keypress: {
      type: Boolean,
      default: true,
    },
    preload: {
      type: Number,
      default: 0,
    },
    thumbail: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    dynamic: {
      type: Boolean,
      default: true,
    },
    selector: {
      type: String,
      default: '',
    },
    enableDownload: {
      type: Boolean,
      default: true,
    },
  },

  computed: {},

  mounted() {},

  watch: {
    visible(val) {
      this.$emit('visible-change', val);
    },
  },

  methods: {
    // 初始化
    handleInit(evt) {
      if (this.disabled) {
        return;
      }

      // 获取需要初始化的元素
      this.getContentItem();
      if (!this.itemEles.length || this.itemEles.length === 0) {
        // eslint-disable-next-line
        console.error('Gallery init error:', '找不到预览元素');
        return;
      }

      // 获取预览数据
      this.getContentData();
      if (!this.imgList.length || this.imgList.length === 0) {
        // eslint-disable-next-line
        console.error('Gallery init error:', '找不到预览数据');
        return;
      }

      this.initEvent();
      this.init = true;

      // console.log('判断发出事件元素, 主动触发显示')
      // 判断发出事件元素, 主动触发显示
      const fromIndex = this.imgList.findIndex((item) => {
        let i = false;
        if (item.$el === evt.target || (item.$el.contains && item.$el.contains(evt.target))) {
          i = true;
        }
        // else if (item.$el.contains && item.$el.contains(evt.target)) {
        //   i = true;
        // }
        return i;
        // return item.$el === evt.target || (item.$el.contains && item.$el.contains(evt.target));
      });
      // console.log('fromIndex:', fromIndex);
      if (fromIndex >= 0) {
        if (this.disabled) {
          return;
        }
        this.$nextTick(() => {
          this.index = fromIndex;
          this.visible = true;
        });
      }
    },

    // 获取需要初始化的元素
    getContentItem() {
      const select = this.selector;
      if (select) {
        this.itemEles = this.$el.querySelectorAll(select);
      } else {
        this.itemEles = this.$el.children;
      }

      if (this.itemEles.length > 0) {
        this.itemEles = Array.prototype.slice.call(this.itemEles);
      }
    },

    // 获取预览数据
    getContentData() {
      this.imgList = this.itemEles.map((ele) => {
        const data = {};
        data.$el = ele;
        // 0: 未初始化
        // 1: 初始化了, 未加载
        // 2: 已加载
        // 3: 出错
        data.status = 0;

        if (this.thumbail) {
          data.thumbail = ele.getAttribute('data-thumbail');

          if (!data.thumbail) {
            if (ele.tagName.toLowerCase() === 'img') {
              (data.thumbail = ele.getAttribute('src'));
            }

            const imgEle = ele.queryselector('img');
            if (imgEle) {
              (data.thumbail = imgEle.getAttribute('src'));
            }
          }
        }

        return data;
      });
    },

    // 数据绑定
    initEvent() {
      this.imgList.forEach((item, index) => {
        utils.on(item.$el, 'click.ig', () => {
          if (this.disabled) {
            return;
          }
          this.index = index;
          this.visible = true;
        });
      });
    },

    destroy() {
      this.imgList.forEach(item => utils.off(item.$el, '.ig'));
      utils.off(window, '.lg');
      utils.removeClass(document.body, 'lg-on');
      this.imgList = [];
      this.itemEles = [];
      this.init = false;
    },
  },
};
</script>
