<template>
  <div class="empty-container">
    <div class="empty-icon" style="background-image: url('/effect/static/assets/empty.svg');"></div>
    <div v-if="title" class="empty-title">{{title}}</div>
    <div class="empty-other">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'KkEmpty',
  props: {
    title: {
      type: String,
      default: '暂无数据',
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.empty-title {
  color: rgba(0,0,0,.5);
  font-size: 12px;
  margin: 4px 0;
}

.empty-icon {
  display: inline-block;
  width: 120px;
  height: 120px;
  max-width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
