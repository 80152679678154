/**
 * @author 圈圈
 * @description 上传视频, 并对视频处理
 * https://developer.qiniu.com/kodo/sdk/1283/javascript
 */
import * as qiniu from 'qiniu-js';
import axios from '@/plugins/axios.js';
import getVideoFileInfo from '@/assets/js/getVideoFileInfo.js';
import * as utils from './utils';
import Uploader, { Private } from './Uploader';

/** 已上传的视频缓存 7 天 */
const catchEffectTime = 7 * 24 * 60 * 60 * 1000;
/** token 有效期 30min */
const tokenEffectTime = 30 * 60 * 1000;
/** 视频处理超时时间 30min */
const processTimeOut = 30 * 60 * 1000;
/** 7牛上传配置 */
const uploadConfig = {
  checkByMD5: true,
  chunkSize: 4,
};

function refreshCatch(catchMap) {
  const catchList = [...catchMap.values()];
  localStorage.setItem('video_catch', JSON.stringify(catchList));
}

function getVideoCatch() {
  const catchList = JSON.parse(localStorage.getItem('video_catch') || '[]');
  const result = new Map();
  const now = Date.now();
  /** 标志缓存是否要更新 */
  let restore = false;
  catchList.forEach((item) => {
    if (item.expires < now || item.status !== 'complete') {
      restore = true;
      return;
    }
    result.set(item.hash, item);
  });

  if (restore) {
    refreshCatch(result);
  }
  return result;
}

class VideoUploader extends Uploader {
  constructor(file, key = '', bucket = 1, config = { useCatch: true }) {
    super(file, key);
    this.key = key;
    this.hash = '';
    this.sourceUrl = '';
    this.displayUrl = '';
    this.bucket = bucket;
    this.processOutput = {};
    this[Private].config = config;
    this[Private].subscription = null;
    this.info = null;
  }

  async init() {
    try {
      this.hash = await utils.getQiniuHash(this.file);
    } catch (err) {
      // 计算 hash 出错, 使用随机字符串代替
      this.hash = utils.getRandomStr(32);
    }

    try {
      this.info = await getVideoFileInfo(this.file);
    } catch (err) {
      this.info = {
        name: this.file.name,
        type: this.file.type,
        size: this.file.size,
        width: '',
        height: '',
        duration: '',
      };
    }

    if (!this.key) {
      this.setKey();
    }
    this.uploadUrl = this.key;

    this.setStatus('inited');
  }

  async checkToken() {
    // 判断 token 是否有效
    const expires = this.global.tokenExpires || 0;
    const token = this.global.token || '';
    const url = '/kyle/common_storage/token/get';
    const effectTime = tokenEffectTime;

    // 未过期
    if (Date.now() < expires && token) {
      return;
    }

    // 更新 token
    const { data } = await axios({
      method: 'get',
      url,
      params: {
        bucket_code: this.bucket,
      },
    });
    this.global.token = data.token;
    this.global.tokenExpires = Date.now() + effectTime;
    this.global.baseUrl = data.url_base;
    localStorage.setItem('qiniu_base_url', this.global.baseUrl);
    localStorage.setItem('qiniu_token', this.global.token);
    localStorage.setItem('qiniu_expires', this.global.tokenExpires.toString());
  }

  setKey(key = '') {
    // 强制知道 key, 不走缓存
    if (key) {
      this.key = key;
      return;
    }

    // 根据文件标识 hash 检查缓存
    const catchItem = this.findCatchItem();

    if (!catchItem) { // 未命中缓存, 创建 key
      this.key = `video/${utils.dateKey()}/${utils.getRandomStr(9)}.${utils.getFileSuffix(this.file)}`;
      return;
    }

    // 设为缓存 key 值
    this.key = catchItem.key;
  }

  async upload() {
    // 状态检测
    const enableStatus = ['inited', 'complete', 'error', 'processSuccess', 'processError', 'abort'];
    if (!enableStatus.includes(this.status)) {
      throw new Error(`上传出错, 当前状态 ${this.status} 不允许上传`);
    }

    this.setStatus('uploading');

    await this.checkToken();
    this.sourceUrl = this.global.baseUrl + this.key;
    this.displayUrl = this.global.baseUrl + this.key;

    const catchItem = this.findCatchItem();
    if (catchItem) {
      this.setStatus('complete');
      this.trigger('upload:success', {
        hash: this.hash,
        key: this.key,
      });
      return;
    }

    // 第一步: 上传视频文件
    const uploadTask = new Promise((resolve, reject) => {
      const observable = qiniu.upload(this.file, this.key, this.global.token, {}, uploadConfig);
      this[Private].subscription = observable.subscribe({
        next: (res) => {
          this.trigger('upload:progress', res);
        },
        error: (err) => {
          reject(err);
          this.setStatus('error');
          this.trigger('upload:error', err);
          this.trigger('error', err);

          if (err.code === 401) {
            // 清空token
            this.global.tokenExpires = 0;
            this.checkToken();
          }
          this[Private].subscription = null;
        },
        complete: (res) => {
          // 更新状态
          this.setStatus('complete');
          // 发出成功事件
          this.trigger('upload:success', res);
          this[Private].subscription = null;

          resolve(({ hash: res.hash, key: this.key }));

          this.addCatch({
            key: this.key,
            hash: this.hash,
            expires: Date.now() + catchEffectTime,
            status: 'complete',
          });
        },
      });
    });

    return uploadTask;
  }

  /**
   * 视频处理
   */
  async process() {
    // 状态检测
    const enableStatus = ['complete', 'processSuccess', 'processError'];
    if (!enableStatus.includes(this.status)) {
      throw new Error(`处理视频出错, 当前状态 ${this.status} 不允许视频转码`);
    }

    this.setStatus('process');
    const processTask = axios({
      url: '/kyle/common_storage/video/compress',
      method: 'post',
      params: {
        video_url: this.key,
      },
    });

    await processTask;
    this.setStatus('processing');

    if (this.status === 'abort') {
      return;
    }

    // 第三步: 检查视频处理结果
    const checkStatus = async () => {
      const { data } = await axios({
        url: '/kyle/common_storage/video/compress_status',
        method: 'get',
        params: {
          video_url: this.key,
        },
      });
      return data;
    };

    await utils.sleep(1000);

    if (this.status === 'abort') {
      return;
    }
    let processRes = await checkStatus();
    const startTime = Date.now();
    /** 是否超时 */
    let isTimeout = false;

    // status:2 处理中
    while (processRes.status === 2 && !isTimeout) {
      if (this.status === 'abort') {
        return;
      }
      // eslint-disable-next-line no-await-in-loop
      await utils.sleep(5000);
      // eslint-disable-next-line no-await-in-loop
      processRes = await checkStatus();
      isTimeout = (Date.now() - startTime) > processTimeOut;
    }

    if (isTimeout) {
      this.setStatus('processError');
      throw new Error('视频处理超时');
    }

    // status:3 处理失败
    if (processRes.status === 3) {
      this.setStatus('processError');
      throw new Error('视频处理失败');
    }

    // status:1 成功
    if (processRes.status === 1) {
      console.log(processRes);
      this.processOutput = processRes;
      this.setStatus('processSuccess');
      return;
    }

    this.setStatus('processError');
    throw new Error('视频处理状态异常');
  }

  abort() {
    // 状态检测
    const enableStatus = ['uploading', 'process', 'processing'];
    if (!enableStatus.includes(this.status)) {
      console.error(`当前状态 ${this.status} 中止无效`);
      return false;
    }
    if (this[Private].subscription) {
      this[Private].subscription.unsubscribe();
      this[Private].subscription = null;
    }
    this.setStatus('abort');
  }

  /** 查找缓存 */
  findCatchItem() {
    if (!this[Private].config.useCatch) {
      return false;
    }

    const target = this.global.catch.get(this.hash);

    if (!target) {
      return false;
    }

    // 检查缓存状态
    if (target.status === 'complete') {
      return target;
    }

    return false;
  }

  /** 添加缓存 */
  addCatch(catchItem) {
    this.global.catch.set(catchItem.hash, catchItem);
    refreshCatch(this.global.catch);
  }
}

VideoUploader.prototype.global = {
  baseUrl: localStorage.getItem('qiniu_base_url') || '',
  token: localStorage.getItem('qiniu_token') || '',
  tokenExpires: parseInt(localStorage.getItem('qiniu_expires'), 10) || 0,
  catch: getVideoCatch(),
};
export default VideoUploader;
