/**
 * @author 圈圈
 * @description 上传资源到7牛
 * https://developer.qiniu.com/kodo/sdk/1283/javascript
 * todo: 如果有多个存储buckey, 需要分开缓存上传地址和token等信息
 */
import * as qiniu from 'qiniu-js';
import axios from '@/plugins/axios.js';
import * as utils from './utils';
import Uploader, { Private } from './Uploader';

/** 已上传的文件缓存 1 天 */
const catchEffectTime = 24 * 60 * 60 * 1000;
/** token 有效期 30min */
const tokenEffectTime = 30 * 60 * 1000;

const defaultConfig = { useCatch: false };

const uploadConfig = {
  checkByMD5: true,
  chunkSize: 4,
};

function refreshCatch(catchMap) {
  const catchList = [...catchMap.values()];
  localStorage.setItem('qiniu_catch', JSON.stringify(catchList));
}

function getCatch() {
  const catchList = JSON.parse(localStorage.getItem('qiniu_catch') || '[]');
  const result = new Map();
  const now = Date.now();
  let restore = false;
  catchList.forEach((item) => {
    if (item.expires < now || item.status !== 'complete') {
      restore = true;
      return;
    }
    result.set(item.hash, item);
  });

  if (restore) {
    refreshCatch(result);
  }
  return result;
}

class QiniuUploader extends Uploader {
  constructor(file, key = '', bucket = 1, config = {}) {
    super(file, key);
    /** 用于提交给服务器的地址 */
    this.uploadUrl = '';
    /** 原图地址 */
    this.sourceUrl = '';
    this.downloadUrl = '';
    this.hash = '';
    this.bucket = bucket;
    this[Private].config = { ...defaultConfig, ...config };
    this[Private].subscription = null;
    this.info = null;
  }

  async init() {
    const { config } = this[Private];

    this.hash = utils.getRandomStr(32);

    // 启用缓存, 需使用真实的文件 hash
    if (config.useCatch) {
      try {
        this.hash = await utils.getQiniuHash(this.file);
      } catch (err) {
        console.log(err);
      }
    }

    this.info = {
      name: this.file.name,
      size: this.file.size,
      type: this.file.type,
    };

    if (!this.key) {
      this.setKey();
    }
    this.uploadUrl = this.key;

    this.setStatus('inited');
  }

  async checkToken() {
    // 判断 token 是否有效
    const expires = this.global.tokenExpires || 0;
    const token = this.global.token || '';
    const url = '/kyle/common_storage/token/get';
    const effectTime = tokenEffectTime;

    // 未过期
    if (Date.now() < expires && token) {
      return;
    }

    // 更新 token
    const { data } = await axios({
      method: 'get',
      url,
      params: {
        bucket_code: this.bucket,
      },
    });
    this.global.token = data.token;
    this.global.tokenExpires = Date.now() + effectTime;
    this.global.baseUrl = data.url_base;
    localStorage.setItem('qiniu_base_url', this.global.baseUrl);
    localStorage.setItem('qiniu_token', this.global.token);
    localStorage.setItem('qiniu_expires', this.global.tokenExpires.toString());
  }

  setKey(key = '') {
    // 强制知道 key, 不走缓存
    if (key) {
      this.key = key;
      return;
    }

    // 根据文件标识 hash 检查缓存
    const catchItem = this.findCatchItem();

    if (!catchItem) { // 未命中缓存, 创建 key 并增加缓存
      this.key = key || `file/${utils.dateKey()}/${utils.getRandomStr(9)}.${utils.getFileSuffix(this.file)}`;
      return;
    }

    // 设为缓存 key 值
    this.key = catchItem.key;
  }

  async upload() {
    // 状态检测
    const enableStatus = ['inited', 'complete', 'error', 'abort'];
    if (!enableStatus.includes(this.status)) {
      throw new Error(`上传出错, 当前状态 ${this.status} 不允许上传`);
    }

    this.setStatus('uploading');

    await this.checkToken();
    this.sourceUrl = this.global.baseUrl + this.key;
    this.downloadUrl = this.sourceUrl;

    const catchItem = this.findCatchItem();
    if (catchItem) {
      this.setStatus('complete');
      this.trigger('upload:success', {
        hash: this.hash,
        key: this.key,
      });
      return;
    }

    const uploadTask = new Promise((resolve, reject) => {
      const observable = qiniu.upload(this.file, this.key, this.global.token, {}, uploadConfig);
      this[Private].subscription = observable.subscribe({
        next: (res) => {
          this.trigger('upload:progress', res);
        },
        error: (err) => {
          reject(err);
          this.setStatus('error');
          this.trigger('upload:error', err);
          this.trigger('error', err);

          if (err.code === 401) {
            // 清空token
            this.global.tokenExpires = 0;
            this.checkToken();
          }
          this[Private].subscription = null;
        },
        complete: async (res) => {
          // 更新状态
          this.setStatus('complete');
          // 发出成功事件
          this.trigger('upload:success', res);
          this[Private].subscription = null;

          resolve(({ hash: res.hash, key: this.key }));
          this.addCatch({
            key: this.key,
            hash: this.hash,
            expires: Date.now() + catchEffectTime,
            status: 'complete',
          });
        },
      });
    });

    return uploadTask;
  }

  abort() {
    // 状态检测
    const enableStatus = ['uploading'];
    if (!enableStatus.includes(this.status)) {
      console.error(`当前状态 ${this.status} 中止无效`);
      return false;
    }
    if (this[Private].subscription) {
      this[Private].subscription.unsubscribe();
      this[Private].subscription = null;
    }
    this.setStatus('abort');
  }

  /** 查找缓存 */
  findCatchItem() {
    if (!this[Private].config.useCatch) {
      return false;
    }

    const target = this.global.catch.get(this.hash);

    if (!target) {
      return false;
    }

    // 检查缓存状态
    if (target.status === 'complete') {
      return target;
    }

    return false;
  }

  /** 添加缓存 */
  addCatch(catchItem) {
    this.global.catch.set(catchItem.hash, catchItem);
    refreshCatch(this.global.catch);
  }
}

QiniuUploader.prototype.global = {
  baseUrl: localStorage.getItem('qiniu_base_url') || '',
  token: localStorage.getItem('qiniu_token') || '',
  tokenExpires: parseInt(localStorage.getItem('qiniu_expires'), 10) || 0,
  catch: getCatch(),
};

export default QiniuUploader;
