<!--
支持的校验对象
  rules.maxWidth
  rules.minWidth
  rules.maxHeight
  rules.minHeight
  rules.ratio [width, height]
  rules.maxSize
-->
<template>
  <div>
    <input ref="inputEle" type="file" :accept="accept" @change="inputChange" style="display: none;" />
    <div style="display: flex; align-items: center;">
      <el-button :size="elFormItemSize" :disabled="inputDisabled" :loading="loading" @click="chooseFile">{{ tipText }}</el-button>
      <div v-if="url" style="flex: 1; color: #606266; margin-left: 16px; font-size: 14px;">
        <i class="el-icon-document" />
        {{ url }}
        <i v-if="clearable" class="el-icon-delete" style="cursor: pointer; color: #E04545;" @click="clear" />
      </div>
    </div>
    <div v-if="displayUrl" style="margin-top: 6px;" v-loading="loading">
      <video preload="metadata" controls style="width: 100%; max-width: 500px;">
        <source :src="displayUrl" type="video/mp4">
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  </div>
</template>

<script>
import VideoUploader from '@/models/uploader/VideoUploader.js';

function validateData(info, rules) {
  if (rules.maxSize && info.size > rules.maxSize) {
    throw new Error(`视频文件大小 ${(info.size / 1024 / 1024).toFixed(2)}M 超过文件大小限制 ${(rules.maxSize / 1024 / 1024).toFixed(2)}M`);
  }

  if (!info.width) {
    return;
  }
  if (rules.width) {
    if (rules.width !== info.width) {
      throw new Error(`视频宽度需为 ${rules.width}`);
    }
  } else {
    if (rules.maxWidth && info.width > rules.maxWidth) {
      throw new Error(`视频宽度 ${info.width} 超过最大宽度限制 ${rules.maxWidth}`);
    }
    if (rules.minWidth && info.width < rules.minWidth) {
      throw new Error(`视频宽度 ${info.width} 小于最小要求宽度 ${rules.minWidth}`);
    }
  }

  if (rules.height) {
    if (rules.height !== info.height) {
      throw new Error(`视频高度需为 ${rules.height}`);
    }
  } else {
    if (rules.maxHeight && info.height > rules.maxHeight) {
      throw new Error(`视频高度 ${info.height} 超过最大高度度限制 ${rules.maxHeight}`);
    }
    if (rules.minHeight && info.height < rules.minHeight) {
      throw new Error(`视频高度 ${info.height} 小于最小要求高度 ${rules.minHeight}`);
    }
  }

  if ((rules.ratio || []).length === 2 && Math.abs((info.width / info.height) - (rules.ratio[0] / rules.ratio[1])) > Number.EPSILON) {
    throw new Error(`视频宽高比不满足 ${rules.ratio[0]} : ${rules.ratio[1]}`);
  }
  if (rules.maxDuration && info.duration > rules.maxDuration) {
    throw new Error(`视频时长 ${info.duration} 超过时长限制 ${rules.maxDuration}`);
  }
  return true;
}

export default {
  name: 'KkVideoUpload',
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  model: {
    prop: 'value',
    event: 'changeVal',
  },
  data() {
    return {
      loading: false,
      displayUrl: '',
      url: '',
    };
  },
  props: {
    accept: {
      type: String,
      default: '.mp4',
    },
    rules: {
      type: Object,
      default: () => ({ size: 1024 * 1024 * 1024 }),
    },
    value: {
      type: String,
      default: '',
    },
    display: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    size: String,
  },
  computed: {
    elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize() {
      return this.size || this.elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    tipText() {
      if (this.loading) {
        return '上传中';
      }
      if (!this.url) {
        return '选择视频';
      }
      return '更换视频';
    },
  },
  watch: {
    display(val) {
      this.changeDisplayUrl(val);
    },
    value(val) {
      this.url = val;
    },
  },
  created() {
    this.url = this.value;
    this.changeDisplayUrl(this.display);
  },
  methods: {
    chooseFile() {
      this.$refs.inputEle.value = '';
      this.$refs.inputEle.click();
    },
    async inputChange(evt) {
      if (!evt.target.files[0]) {
        return;
      }

      const file = evt.target.files[0];
      const uploader = new VideoUploader(file);
      console.log(uploader);
      await uploader.init();

      const videoInfo = uploader.info;

      // 检测文件 type, 尽量防止用户手动绕过 accept 配置
      if (!videoInfo.type.includes('video')) {
        this.$message.error('请选择视频文件');
        return;
      }

      try {
        validateData(videoInfo, this.rules);
      } catch (err) {
        this.$message.error(err.message || '视频不符合要求');
        return;
      }

      this.uploader = uploader;

      uploader.on('upload:progress', (info) => {
        this.progress = Math.round(info.total.percent);
      });
      uploader.on('change:status', (val) => {
        this.status = val;
      });

      this.loading = true;
      try {
        await this.upload();
      } catch (err) {
        console.log(err);
        this.clear();
        this.$message.error(err.message || '视频上传失败');
      }
      this.loading = false;
    },
    async upload() {
      if (this.inputDisabled) {
        return;
      }
      await this.uploader.upload();
      await this.uploader.process();
      this.changeDisplayUrl(this.uploader.processOutput.display_url || this.uploader.localUrl);
      this.$emit('changeVal', this.uploader.processOutput.url || this.uploader.key);
      this.$emit('change', this.uploader);
    },
    clear() {
      if (this.loading) {
        return;
      }
      this.changeDisplayUrl('');
      this.url = '';

      this.$emit('change', ({
        info: null,
        url: '',
        display: '',
        previewImg: '',
      }));
      this.$emit('changeVal', '');
    },
    changeDisplayUrl(val) {
      this.displayUrl = '';
      this.$nextTick(() => {
        this.displayUrl = val;
      });
    },
  },
};
</script>
